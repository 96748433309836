import { ActionButton, DirectionalHint, IContextualMenuItem, IDialogContentProps, Icon, IconButton, TooltipDelay, TooltipHost } from 'office-ui-fabric-react';
import React, { useState } from 'react'
import { EntityType, ITimeframe, Quantization, entityTypeLabelMap } from '../../entities/common';
import { CreateTimeTrackingEntryAttr, IsLockedPeriod, TimeTrackingEntry, TimeTrackingEntryProject, TimeTrackingLine, TimelineToCreateTimeEntry } from '../../store/TimeTrackingStore'
import { notUndefined, toDate } from '../utils/common';
import { TimeTrackingGlobalSettings } from '../../store/Tenant';
import RemoveDialog from '../common/RemoveDialog';
import { getDaysBetweenDates } from '../common/timeline/utils';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { ShowO365Connector, TimeType } from './common';

type OwnProps = {
    timeTrackingLine: TimeTrackingLine;
    entityType?: EntityType;
    isReadonly: boolean;

    onClearTimeEntries: (toClear: TimeTrackingEntry[]) => void;
    onDeleteTimeEntries: (ids: string[]) => void;

    onAddTime: (type: TimeType) => void;

    onCopyTask: (toCopy: CreateTimeTrackingEntryAttr[]) => void;
}

type StateProps = {
    settings: TimeTrackingGlobalSettings;
    showO365Connector: boolean;
};

type Props = OwnProps & StateProps;

function TimeTrackingItemMenu(props: Props) {

    const { timeTrackingLine, entityType, settings, isReadonly, onClearTimeEntries, onDeleteTimeEntries,
        onAddTime, showO365Connector } = props;

    const [entriesToClear, setEntriesToClear] = useState<TimeTrackingEntry[] | undefined>();
    const [entriesToDelete, setEntriesToDelete] = useState<TimeTrackingEntry[] | undefined>();

    if (isReadonly) {
        return <></>;
    }

    const commands: IContextualMenuItem[] = [];

    const entityTypeLabel = entityType
        ? timeTrackingLine.attributes.IsAdministrative
            ? entityType === EntityType.Project
                ? "Administrative Categories"
                : timeTrackingLine.attributes.Task.name
            : entityTypeLabelMap[entityType].singular
        : "";
    

    if (entityType === EntityType.Task || entityType === EntityType.Project) {

        const isFullyLocked = IsLockedPeriod(timeTrackingLine.attributes.TimeFrame.end, settings.timeReportingLock);

        if (entityType === EntityType.Project &&
            !timeTrackingLine.attributes.IsAdministrative &&
            !isFullyLocked &&
            !timeTrackingLine.attributes.ProjectDetails?.isDeleted && 
            !timeTrackingLine.attributes.ProjectDetails?.isArchived) {

            commands.push({
                key: "import",
                text: `Import Task`,
                iconProps: { iconName: "PPMXImport" },
                onClick: () => onAddTime(TimeType.ProjectTask),
            });

            if (settings.enableReportingOnCustomTimeTrackingEntries) {
                commands.push({
                    key: "addCustomEvent",
                    name: "Add Custom Time Entry",
                    iconProps: { iconName: "Add" },
                    onClick: () => onAddTime(TimeType.CustomEvent),
                });
            }
                
            if (showO365Connector) {
                commands.push({
                    key: "importFromM365",
                    name: "Import from M365",
                    iconProps: { iconName: "M365Logo" },
                    onClick: () => onAddTime(TimeType.O365)
                });
            }
        }
        
        if (entityType === EntityType.Task &&
            !timeTrackingLine.attributes.ProjectDetails?.isDeleted &&
            !timeTrackingLine.attributes.TaskDetails?.isDeleted && 
            !timeTrackingLine.attributes.ProjectDetails?.isArchived &&
            !timeTrackingLine.attributes.TaskDetails?.isArchived) {
            
            const nextInterval: ITimeframe = timeTrackingLine.attributes.Period === Quantization.months
                ? timeTrackingLine.attributes.TimeFrame.end.getNextMonth()
                : timeTrackingLine.attributes.Period === Quantization.weeks
                    ? timeTrackingLine.attributes.TimeFrame.end.getNextWeek()
                    : { start: timeTrackingLine.attributes.TimeFrame.end.clone().addDays(1), end: timeTrackingLine.attributes.TimeFrame.end.clone().addDays(1) };
            
            
            const datesToCopy = getDaysBetweenDates(nextInterval.start, nextInterval.end).filter(_ => !IsLockedPeriod(_, settings.timeReportingLock));
            const periodLabel = timeTrackingLine.attributes.Period === Quantization.months
                ? "Month"
                : timeTrackingLine.attributes.Period === Quantization.weeks
                    ? "Week"
                    : "Day";
            
            if (datesToCopy.length) {
                commands.push({
                    key: "copy",
                    text: `Copy ${entityTypeLabel} to the next ${periodLabel}`,
                    iconProps: {
                        iconName: "Copy"
                    },
                    onClick: () => {

                        const toCopy: CreateTimeTrackingEntryAttr[] = datesToCopy.map(_ => TimelineToCreateTimeEntry(timeTrackingLine, _, 0));
                        props.onCopyTask(toCopy);
                    }
                })
            }
        }

        const entriesWithReportedTime = timeTrackingLine.TimeEntries.filter(_ => _.attributes.Duration);
        const notLockedEntries = entriesWithReportedTime.filter(_ => !IsLockedPeriod(toDate(_.attributes.Date)!, settings.timeReportingLock));

        if (notLockedEntries.length) {

            commands.push({
                key: "clear",
                text: `Clear Visible ${entityTypeLabel} Time`,
                iconProps: {
                    iconName: "Refresh"
                },
                onClick: () => {
                    setEntriesToClear(notLockedEntries);
                }
            });
        }

        if (!timeTrackingLine.attributes.SuggestionType) {

            const hasLockedReportedTime = entriesWithReportedTime.length !== notLockedEntries.length;
            const hasSuggestedTasks = timeTrackingLine.attributes.HasSuggestedTasks;

            if (!hasLockedReportedTime) {

                commands.push({
                    key: "delete",                    
                    disabled: hasSuggestedTasks,
                    onRender: () => (
                        <div className='time-tracking-menu'>
                            <div className={`ms-ContextualMenu-link align-center ${hasSuggestedTasks ? 'is-disabled' : ''}` }>
                            
                                <ActionButton
                                    className='ms-ContextualMenu-linkContent '
                                    onClick={() => setEntriesToDelete(timeTrackingLine.TimeEntries)}
                                    text={`Remove ${entityTypeLabel} from Timesheet`}
                                    style={hasSuggestedTasks
                                        ? undefined
                                        : {
                                            color: "red"
                                        }
                                    }
                                    iconProps={{
                                        iconName: "Delete",
                                        style: hasSuggestedTasks
                                            ? undefined
                                            : {
                                                color: "red"
                                            }
                                    }}
                                    disabled={hasSuggestedTasks}
                                />

                                {hasSuggestedTasks &&
                                    <TooltipHost
                                        content={entityType === EntityType.Project
                                            ? "You cannot delete this Project as its Tasks were suggested by the System."
                                            : `You cannot delete this ${entityTypeLabel} as it was suggested by the System`
                                        }
                                        delay={TooltipDelay.zero}
                                        hostClassName="tooltip"
                                        calloutProps={{ directionalHint: DirectionalHint.topCenter }}>
                                        <Icon iconName="Info" />
                                    </TooltipHost>
                                }
                            </div>
                        </div>)
                            
                });
            }
        }
    }

    return (<>
        {
            !!commands.length &&
                 <IconButton
                    menuIconProps={{ iconName: 'PPMXMore' }}
                    menuProps={{
                        items: commands
                }}/>
        }

        {!!entriesToClear?.length &&
            <RemoveDialog
            dialogContentProps={_getClearTimeText(timeTrackingLine, entityTypeLabel, entityType!, settings, entriesToClear)}
            onClose={() => {
                setEntriesToClear(undefined);
            }}
            confirmButtonProps={{
                text: "Clear"
            }}
            onComplete={() => {
                onClearTimeEntries(entriesToClear);
            }}/>
        }
        
        {!!entriesToDelete?.length &&
            <RemoveDialog
            dialogContentProps={_getDeleteTimeText(timeTrackingLine, entityTypeLabel, entityType!, settings, entriesToDelete)}
            onClose={() => {
                setEntriesToDelete(undefined);
            }}
            onComplete={() => {

                onDeleteTimeEntries(entriesToDelete.map(_ => _.id));
            } }
            />
        }
        </>);
  
}

const _getClearTimeText = (
    timeTrackingLine: TimeTrackingLine,
    entityTypeLabel: string,
    entityType: EntityType,
    setting: TimeTrackingGlobalSettings,
    timeEntriesToClear: TimeTrackingEntry[]): IDialogContentProps => {

    const result: IDialogContentProps = {
        title: `Clear Visible ${entityTypeLabel} Time`,
        isMultiline: true
    };

    if (timeTrackingLine.attributes.IsAdministrative) {
        result.subText = `Are you sure you want to clear the ${entityTypeLabel} visible Reported Time?`;
    }
    else if (entityType === EntityType.Project) {

        const canRecalculateCompletedWork = setting.calculateCompletedWorkBasedOnReportedTime && timeEntriesToClear.some(_ => !_.attributes.CustomTimeEntryName);
            
        result.subText = [
            `Are you sure you want to clear the Tasks visible Reported Time of the Project "${timeTrackingLine.attributes.Project.name}"?`,
            canRecalculateCompletedWork ? `Tasks Completed Work will be recalculated.` : undefined
        ]
            .filter(notUndefined)
            .join("\r\n");
    }
    else {

        const canRecalculateCompletedWork = setting.calculateCompletedWorkBasedOnReportedTime && !timeTrackingLine.attributes.IsCustomEvent;

        result.subText = [
            `Are you sure you want to clear the Task "${timeTrackingLine.attributes.Task.name}" visible Reported Time?`,
            canRecalculateCompletedWork ? `Task Completed Work will be recalculated.` : undefined
        ]
            .filter(notUndefined)
            .join("\r\n");
    }

    return result;
}

const _getDeleteTimeText = (
    timeTrackingLine: TimeTrackingLine,
    entityTypeLabel: string,
    entityType: EntityType,
    setting: TimeTrackingGlobalSettings,
    timeEntriesToDelete: TimeTrackingEntry[]): IDialogContentProps => {

    const result: IDialogContentProps = {
        title: `Remove ${entityTypeLabel} from Timesheet`,
        isMultiline: true
    };

    if (timeTrackingLine.attributes.IsAdministrative) {
        result.subText = `Are you sure you want to remove the ${entityTypeLabel} with its Reported Time from the visible period of your Timesheet?`;
    }
    else if (entityType === EntityType.Project) {
        
        const canRecalculateCompletedWork = setting.calculateCompletedWorkBasedOnReportedTime && timeEntriesToDelete.some(_ => !_.attributes.CustomTimeEntryName);
                
        result.subText = [
            `Are you sure you want to remove the Project "${timeTrackingLine.attributes.Project.name}" along with its Tasks and Reported Time from the visible period of your Timesheet?`,
            `Reported Time will be lost. ${canRecalculateCompletedWork ? `Tasks Completed Work will be recalculated.` : ''}`,
        ]
            .filter(notUndefined)
            .join("\r\n");
    }
    else {
    
        const canRecalculateCompletedWork = setting.calculateCompletedWorkBasedOnReportedTime && !timeTrackingLine.attributes.IsCustomEvent;
    
        result.subText = [
            `Are you sure you want to remove the Task "${timeTrackingLine.attributes.Task.name}" along with its Reported Time from the visible period of your Timesheet?`,
            `Reported Time will be lost. ${canRecalculateCompletedWork ? `Task Completed Work will be recalculated.` : ''}`,
        ]
            .filter(notUndefined)
            .join("\r\n");
            
    }

    return result;
}

function mapStateToProps(state: ApplicationState, ownProps: OwnProps): StateProps {
    return {
        settings: state.tenant.timeTracking.globalSettings,
        showO365Connector: ShowO365Connector(state)
    };
}

export default connect(mapStateToProps)(TimeTrackingItemMenu);