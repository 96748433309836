import * as React from "react";
import { OnComplete, UpdatePanel } from "../common/SubentityPanel";
import { IPredecessorInfo, ITask, ITaskAttrs } from "../../entities/Subentities";
import { EntityType, ppmxTaskConnectionId } from "../../entities/common";
import * as Metadata from "../../entities/Metadata";
import { validatorsBuilder, rendersBuilder, LoadedTask } from "../task/Fields";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import { RouteComponentProps, withRouter } from "react-router-dom";
import * as CalendarStore from '../../store/CalendarStore';
import { bindActionCreators } from "redux";
import { get } from "../../fetch-interceptor";
import { Insights, StatusCalculationTypes } from "../../store/Tenant";
import { nameof, namesof } from "../../store/services/metadataService";
import { calculateTaskVariance, getMandatoryEditFields } from "../common/sectionsControl/uiControls/TasksControl";
import * as ProjectsListStore from '../../store/ProjectsListStore';
import { SourceType } from "../../store/ExternalEpmConnectStore";
import TaskBaseline from "../task/TaskBaseline";
import TaskPredecessors from "../task/TaskPredecessors";
import { Icon } from "office-ui-fabric-react";
import { TaskCalculationModeTypes } from "../views/list/columns/task/Mode";

type FindTaskInfo = { 
    id: string; 
    name: string, 
    startDate?: string; 
    dueDate?: string; 
    duration?: number; 
    predecessor?: IPredecessorInfo[];
    parentIds: string[];
}

type OwnProps = {
    projectId: string;
    readOnly: boolean;
    statusCalculationDisabled: boolean;
    entity: ITask;
    fields: Metadata.Field[];
    onComplete: OnComplete;
    onDismiss: () => void;
    mandatoryEditableFields?: (entity: ITask) => string[] | undefined;
}
type StateProps = {
    project: ProjectsListStore.ProjectInfo
    insights: Insights;
    groups: Metadata.Group[];
    calendar: CalendarStore.CalendarDataSet;
}
type ActionProps = {
    groupsActions: typeof ProjectsListStore.groupsCreators;
    calendarActions: typeof CalendarStore.actionCreators;
};
type Props = OwnProps & StateProps & ActionProps & RouteComponentProps<any>;

class TaskEditPanel extends React.Component<Props, { tasks: LoadedTask[] }> {
    _isMounted = false;

    constructor(props: Props) {
        super(props);
        this.state = { tasks: [] };
    }

    componentWillMount() {
        const { entity, projectId } = this.props;
        const connectionId = entity.connectionId ?? ppmxTaskConnectionId;

        this.props.groupsActions.load(projectId, connectionId);
        if (connectionId === ppmxTaskConnectionId) {
            this.props.calendarActions.load();
            get<FindTaskInfo[]>(`/api/project/${projectId}/task/find`)
                .then(tasks => this._isMounted && this.setState({
                    tasks: tasks.map(_ => ({
                        id: _.id,
                        attributes: {
                            Name: _.name,
                            StartDate: _.startDate,
                            DueDate: _.dueDate,
                            Duration: _.duration,
                            Predecessor: _.predecessor
                        },
                        hierarchy: { parentIds: _.parentIds }
                    }))
                }));
        }
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    public render() {
        const { entity, fields, onComplete, onDismiss, readOnly, insights, statusCalculationDisabled, calendar, mandatoryEditableFields } = this.props;

        return <UpdatePanel<ITask>
            subentityTypeLabel="Task"
            subentityType={EntityType.Task}
            displayFields={getMandatoryEditFields(entity.sourceType)}
            entity={entity}
            fields={fields}
            readOnly={readOnly}
            onComplete={onComplete}
            onDismiss={onDismiss}
            readOnlyFields={(task: ITask) => getTaskReadonlyFields(task, insights, statusCalculationDisabled)}
            customFieldValidatorBuilder={validatorsBuilder(calendar, () => this.state.tasks)}
            uiControlElementsCustomRender={rendersBuilder(this.props.project, this.props.groups, this.props.calendar, this._urlBuilder, () => this.state.tasks)}
            mandatoryEditableFields={mandatoryEditableFields}
            extraTabs={entity.sourceType !== SourceType.Ppmx ? undefined : [{
                key: 'predecessors',
                label: 'Predecessors',
                renderHeaderSecondaryText: () => "Add predecessor(s) for the task, then select the dependency type and optionally set lead or lag time.",
                renderBody: (task, onChange) => <TaskPredecessors
                    readonly={readOnly}
                    task={task}
                    tasks={this.state.tasks as unknown as ITask[]}
                    onChange={onChange}
                    calendar={this.props.calendar}
                />
            }, {
                key: 'baseline',
                label: 'Baseline',
                renderHeaderSecondaryText: () => `View the task baseline and its current state`,
                renderBody: (updatedTask: ITask) => <TaskBaseline task={updatedTask} variance={calculateTaskVariance(updatedTask, entity, calendar)} />
            }]}
            header={{ suffixRender: titleSuffixRender }}
        />;
    }

    private _urlBuilder = () => undefined;
}

function mergeActionCreators(dispatch: any): ActionProps {
    return {
        groupsActions: bindActionCreators(ProjectsListStore.groupsCreators, dispatch),
        calendarActions: bindActionCreators(CalendarStore.actionCreators, dispatch)
    }
}

export default withRouter<OwnProps>(connect(
    (state: ApplicationState, ownProp: OwnProps & RouteComponentProps<any>): StateProps => {
        return {
            calendar: state.calendar,
            groups: state.projectsList.groups.byId[ownProp.projectId]?.items || [],
            insights: state.tenant.insights,
            project: state.projectsList.byId[ownProp.projectId],
        };
    },
    mergeActionCreators
)(TaskEditPanel));

const readonlyAutoSummaryTaskFields = namesof<ITaskAttrs>(["StartDate", "DueDate", "Duration", "StoryPoints", "CompletedStoryPoints",
    "Progress", "Effort", "CompletedWork", "RemainingWork", "OriginalEstimate"]);

export const getTaskReadonlyFields = (entity: ITask, insights: Insights, statusCalculationDisabled?: boolean): string[] => {
    let fields = insights.task.statusCalculation === StatusCalculationTypes.Auto && !statusCalculationDisabled
        ? [nameof<ITaskAttrs>("Status")]
        : [];

    if (entity.isAutoMode && entity.hierarchy.isParent) {
        fields = fields.concat(readonlyAutoSummaryTaskFields);
    }

    return fields;
}

export const titleSuffixRender = (subentity: ITask): (JSX.Element | undefined) =>
    <a className='calculation-mode' target='_blank'
        href="https://help.ppm.express/94162-portfolio-and-project-management/2082039-project-settings#section-6"
        title='Click for more information about the calculation mode for PPM Express Tasks.' >
        {subentity.isAutoMode ? TaskCalculationModeTypes.Auto : TaskCalculationModeTypes.Manual} Mode
        <Icon iconName="PPMXQuestionMarkInCircle" />
    </a>;
