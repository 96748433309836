import "./QuickStart.css";
import * as React from 'react';
import LetsStart from "../LetsStart";
import MoreResources from "../MoreResources";
import RequestDemo from "./RequestDemo";
import ContactUs from "./ContactUs";
import HelpGuide from "./HelpGuide";
import WhatsNew from "../WhatsNew";
import Connections from "../Connections";
import DemoData from "../DemoData";
import VideoBox from "../VideoBox";
import { ProductType } from "../../../store/Tenant";
import AiInsights from "../AiInsights";
import ViewEntities from "./ViewEntities";
import { canCollaborate, canRead } from "../../../store/permissions";
import { connect } from "react-redux";
import { ApplicationState } from "../../../store";
import { UserState } from "../../../store/User";

type StateProps = {
    user: UserState;
}

type OwnProps = {
    isTrial: boolean;
    hasPartnersSupport: boolean;
    hasAiInsights: boolean;
    hasProjectManagement: boolean;
    hasIdeation: boolean;
    isConnectionAvailable: boolean;
    productType: ProductType;
}

type Props = StateProps & OwnProps;

const QuickStartRegular = (props: Props) => {

    const _renderVideo = () => {
        switch (props.productType) {
            case ProductType.Enterprise:
                return <>
                    <VideoBox text="PPM Express Overview" href="https://vimeo.com/685714660" />
                    <VideoBox text="PPM Express Task Management" href="https://vimeo.com/991507302" />
                    <VideoBox text="PPM Express Time Tracking" href="https://vimeo.com/991511091" />
                </>
            case ProductType.Product:
            case ProductType.Portfolio:
                return <>
                    <VideoBox text="PPM Express Overview" href="https://vimeo.com/685714660" />
                    <VideoBox text="Overview for Team Members" href="https://vimeo.com/1027302351" />
                    <VideoBox text="PPM Express Task Management" href="https://vimeo.com/991507302" />
                </>
            default:
                return <>
                    <VideoBox text="PPM Express Overview" href="https://vimeo.com/991507263" />
                    <VideoBox text="PPM Express Task Management" href="https://vimeo.com/991511062" />
                    <VideoBox text="PPM Express Time Tracking" href="https://vimeo.com/991511091" />
                </>
        }
    }

    return <>
        {props.hasAiInsights && <div className="row"><AiInsights /></div>}
        {
            props.isConnectionAvailable && <div className="row">
                <Connections />
            </div>
        }
        <div className="row">
            <HelpGuide />
            <div className="column double">
                <DemoData />
                {props.hasPartnersSupport
                    ? undefined
                    : props.isTrial
                        ? <RequestDemo />
                        : <ContactUs />}
            </div>
        </div>
        <div className="row withVideo">
            {_renderVideo() }
        </div>
        <div className="row">
            <div className="column double">
                <div className="column">
                    <LetsStart />
                    <MoreResources />
                </div>
            </div>
            <div className="column">
                {
                    props.hasProjectManagement
                        ? <ViewEntities text="View Projects" path='/projects' fixed disabled={!canRead(props.user.permissions.project)} />
                        : props.hasIdeation
                            ? <ViewEntities text="View Business Challenges" path='/challenges' fixed disabled={!canCollaborate(props.user.permissions.challenge)} />
                            : undefined
                }
                <WhatsNew big />
            </div>
        </div>
    </>;
}

function mapStateToProps(state: ApplicationState): StateProps {
    return { user: state.user };
}

export default connect(mapStateToProps)(QuickStartRegular);