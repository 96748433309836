import React from 'react'
import { ITimeframe, ServerEntityType } from '../../../entities/common';
import { EntityDescriptor } from '../../../store/RoadmapsListStore';
import SearchItemsImportPanel from '../../common/SearchItemsImportPanel';

type Props = {
    timeFrame: ITimeframe | undefined;
    onDismiss: () => void,
    onImport: (descriptor: EntityDescriptor, callback: () => void) => void;
}

export default function TimeTrackingImportProjectsPanel(props: Props) {

    const { timeFrame } = props;

    return <SearchItemsImportPanel
        {...props}
        title='Select Project'
        subTitle='Select the Project you want to report time on the Custom Activity. This activity will be added to the Timesheet as a line entry but not to the Project Tasks'
        isEntityImported={_ => false}
        onUndoImport={() => { }}
        supportedEntityTypes={[ServerEntityType.Project]}
        selectedEntityType={ServerEntityType.Project}
        showParentColumn={(type) => false}
        addButtonText='Report Time'

        showMyWorkItems
        initialFilter={{
            Date: timeFrame && {
                from: timeFrame.start,
                to: timeFrame.end
            }
        }}
    />
}