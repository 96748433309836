import React from "react";
import LabellableComponent from "../../common/LabellableComponent";
import FlagInput from "../../common/inputs/FlagInput";
import { TimeTrackingGlobalSettings } from "../../../store/Tenant";

type Props = {
    saveSettings: (settings: Partial<TimeTrackingGlobalSettings>, callback?: () => void) => void;
    settings: TimeTrackingGlobalSettings;
    canManage: boolean;
};

export default function TimeReportingControls(props: Props) {
    const { canManage, settings, saveSettings } = props;

    return (
        <div className="settings-fields-holder time-tracking-settings">
            <div className="settings-row">
                <LabellableComponent
                    className="field-container"
                    label="Report time on Unassigned Tasks"
                    description={
                        <>
                            Allows users to report time on any task (even where they are not assigned).
                            <br />
                            Enabling this setting provides flexibility for users to track their work on tasks outside their
                            assigned responsibilities, which can be helpful in collaborative or shared project environments.
                        </>
                    }>
                    <FlagInput
                        value={settings.enableReportingOnNotAssignedTasks}
                        onChanged={_ => saveSettings({ enableReportingOnNotAssignedTasks: _ })}
                        readOnly={!canManage}
                    />
                </LabellableComponent>

                <LabellableComponent
                    className="field-container"
                    label="Enable Custom Time Entries"
                    description={
                        "Users can report time on the non-scheduled activities in projects (there is no task created). This time entry will be added to the Timesheet as a new line entry."
                    }>
                    <FlagInput
                        value={settings.enableReportingOnCustomTimeTrackingEntries}
                        onChanged={_ => saveSettings({ enableReportingOnCustomTimeTrackingEntries: _ })}
                        readOnly={!canManage}
                    />
                </LabellableComponent>
            </div>
        </div>
    );
}
