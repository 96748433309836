import React, { useMemo, useState } from "react";
import { CommonOperations, contains } from "../../../store/permissions";
import SettingsPage, { Section } from "../SettingsPage";
import TimeReportingSettings from "./TimeReportingSettings";
import TimeTrackingAdministrative from "./Administrative";
import Link from "../../common/Link";
import { connect } from "react-redux";
import { IsEnterpriseTimeTracker } from "../../../store/TimeTrackingStore";
import { ApplicationState } from "../../../store";
import { notUndefined } from "../../utils/common";
import TimeTrackingCostTypeAndBillableSettings from "./TimeTrackingCostTypeAndBillableSettings";
import { TimeTrackingGlobalSettings, actionCreators as tenantActionCreators } from "../../../store/Tenant";
import { bindActionCreators, Dispatch } from "redux";
import './timeTrackingSettings.css';
import ReportedTimeSynchronizationSettings from "./ReportedTimeSynchronizationSettings";
import TimeReportingControls from "./TimeReportingControls";

type StateProps = {
    isEnterpriseTimeTracker: boolean;
    settings: TimeTrackingGlobalSettings;
    canManage: boolean;
}

type ActionProps = {
    tenantActions: typeof tenantActionCreators;
};

type Props = StateProps & ActionProps;

function TimeTrackingSettings(props: Props) {
    const { isEnterpriseTimeTracker, canManage, tenantActions } = props;

    const [settings, setSettings] = useState(props.settings);

    const saveSettings = React.useCallback(
        (newSettings: Partial<TimeTrackingGlobalSettings>, callback?: () => void) => {
            const newState = {
                ...settings,
                ...newSettings,
            };

            setSettings(newState);

            tenantActions.updateTimeTrackingSettings(newSettings, callback);
        },
        [settings]
    );

    const sections: Section[] = useMemo(
        () =>
            [
                {
                    key: "globalSettings",
                    name: "Time Reporting Settings",
                    icon: "",
                    onRenderBody: () => (
                        <TimeReportingSettings saveSettings={saveSettings} settings={settings} canManage={canManage} />
                    ),
                    tooltip: (
                        <span>
                            Define time reporting settings for your PPM Express tenant. Please refer to the
                            {' '}
                            <Link
                                target="_blank"
                                href="https://help.ppm.express/89495-ppm-express-settings/2610325-time-tracking-settings#section-0">
                                 article 
                            </Link>
                            {' '}
                            for more details.
                        </span>
                    ),
                },
                {
                    key: "reportedTimeSync",
                    name: "Reported Time synchronization",
                    icon: "",
                    onRenderBody: () => (
                        <ReportedTimeSynchronizationSettings
                            saveSettings={saveSettings}
                            settings={settings}
                            canManage={canManage}
                        />
                    ),
                    tooltip: (
                        <span>
                            Manage the synchronization of the time reported by users within your PPM Express Tenant. Please
                            refer to the
                            {' '}
                            <Link
                                target="_blank"
                                href="https://help.ppm.express/89495-ppm-express-settings/2610325-time-tracking-settings#section-1">
                                 article 
                            </Link>
                            for more details.
                        </span>
                    ),
                },
                isEnterpriseTimeTracker
                    ? {
                          key: "billableCostTypeSettings",
                          name: "Billable and Cost Type synchronization",
                          icon: "",
                          onRenderBody: () => (
                              <TimeTrackingCostTypeAndBillableSettings
                                  saveSettings={saveSettings}
                                  settings={settings}
                                  canManage={canManage}
                              />
                          ),
                          tooltip: (
                              <span>
                                  Manage the synchronization rules of the Billable and Cost Type fields values to the time
                                  entries recorded by users. Please refer to the
                                  {' '}
                                  <Link
                                      target="_blank"
                                      href="https://help.ppm.express/89495-ppm-express-settings/2610325-time-tracking-settings#section-2">
                                       article 
                                  </Link>
                                  {' '}
                                  for more details.
                              </span>
                          ),
                      }
                    : undefined,
                {
                    key: "reportingControls",
                    name: "Time Reporting controls",
                    icon: "",
                    onRenderBody: () => (
                        <TimeReportingControls
                            saveSettings={saveSettings}
                            settings={settings}
                            canManage={canManage}
                        />
                    )
                },
                {
                    key: "administrative",
                    name: "Administrative Categories",
                    icon: "",
                    tooltip: (
                        <span>
                            Create and manage administrative categories that users in your tenant will report time on. Please
                            refer to the
                            {' '}
                            <Link
                                target="_blank"
                                href="https://help.ppm.express/89495-ppm-express-settings/2610325-time-tracking-settings#section-3">
                                article
                            </Link>
                            {' '}
                            for more details.
                        </span>
                    ),
                    onRenderBody: () => <TimeTrackingAdministrative />,
                },
            ].filter(notUndefined),
        [isEnterpriseTimeTracker, setSettings, saveSettings]
    );

    return (
        <SettingsPage
            title="Time Tracking Settings"
            requiredPermission={CommonOperations.TimeTrackingView}
            sections={sections}></SettingsPage>
    );
};

const mapStateToProps = (_: ApplicationState): StateProps => ({
    isEnterpriseTimeTracker: IsEnterpriseTimeTracker(_.tenant.subscription),
    settings: _.tenant.timeTracking.globalSettings,
    canManage: contains(_.user.permissions.common, CommonOperations.TimeTrackingManage)
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => {
    return {
        tenantActions: bindActionCreators(tenantActionCreators, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TimeTrackingSettings);