import { CommandBar, ICommandBarItemProps, IContextualMenuItem } from "office-ui-fabric-react";
import React, { useMemo } from "react";
import { connect } from "react-redux";
import { Field } from "../../../../entities/Metadata";
import { CostType, EntityType, MaybeDate } from "../../../../entities/common";
import { ApplicationState } from "../../../../store";
import { CreateTimeTrackingEntry, IsEnterpriseTimeTracker, IsLockedPeriod, TimeTrackingEntry, TimeTrackingEntryAttr, TimeTrackingEntrySourceType, TimeTrackingLine } from "../../../../store/TimeTrackingStore";
import { nameof } from "../../../../store/services/metadataService";
import { DisplayField } from "../../../common/DisplayField";
import EntityName from "../../../views/list/columns/EntityName";
import TaskIcon from "../../../task/TaskIcon";
import TimeTrackingAdministrativeCategoryEntityName from "../../TimeTrackingAdministrativeCategoryEntityName";
import { IsEmptyObject, notUndefined, toDateTime } from "../../../../components/utils/common";
import { CalendarDataSet } from "../../../../store/CalendarStore";
import { getDaysBetweenDates } from "../../../common/timeline/utils";
import { TimeTrackingGlobalSettings } from "../../../../store/Tenant";
import { ITimelineSegment } from "../../../common/timeline/TimelineSegment";

type OwnProps = {
    
    timeTrackingLine: TimeTrackingLine;
    segment: ITimelineSegment;
    isReadonly: boolean;

    onCreate: () => void;
    onStartEditing: (entry: TimeTrackingEntry) => void;
    onClone: (entries: CreateTimeTrackingEntry[]) => void;
    onEditCompleted: (id: string, attributes: Partial<TimeTrackingEntryAttr>) => void;

    resourceCalendarDataSet: CalendarDataSet;
};

type StoreProps = {
    fields: Field[];
    timeTrackingSettings: TimeTrackingGlobalSettings;
    isEnterpriseTimeTracker: boolean;
};

type Props = OwnProps & StoreProps;

const _hiddenFields: string[] = [
    nameof<TimeTrackingEntryAttr>("Project"),
    nameof<TimeTrackingEntryAttr>("Task"),
    nameof<TimeTrackingEntryAttr>("Resource"),
    nameof<TimeTrackingEntryAttr>("CustomTimeEntryName"),
    nameof<TimeTrackingEntryAttr>("AdministrativeCategory"),
    nameof<TimeTrackingEntryAttr>("Date"),
    nameof<TimeTrackingEntryAttr>("Duration"),
];

function TimeTrackingCellTooltip(props: Props) {
    const { timeTrackingLine, segment, fields, onCreate, onStartEditing, onClone, onEditCompleted,
        resourceCalendarDataSet, timeTrackingSettings, isReadonly, isEnterpriseTimeTracker } = props;

    const title = timeTrackingLine.attributes.Task.name;
    const entry = timeTrackingLine.TimeEntries.filter(_ => _.attributes.Date >= segment.startDate && _.attributes.Date <= segment.finishDate)[0];
    const isLocked = IsLockedPeriod(segment.finishDate, timeTrackingSettings.timeReportingLock);

    const visibleFields = entry?.attributes.Duration ? fields.filter(field => {

        if (_hiddenFields.includes(field.name)) {
            return false;
        }

        return field.name === nameof<TimeTrackingEntryAttr>("Description") || isFieldHasValue(entry.attributes[field.name]);
    }) : [];

    const isDeleted = timeTrackingLine.attributes.TaskDetails?.isDeleted || timeTrackingLine.attributes.ProjectDetails?.isDeleted;
    const isArchived = timeTrackingLine.attributes.TaskDetails?.isArchived || timeTrackingLine.attributes.ProjectDetails?.isArchived;

    const canClone = entry && !isDeleted && !isArchived && !isReadonly;
    
    const commands: ICommandBarItemProps[] = useMemo(() => {
        
        if (!entry?.attributes.Duration) {
            return [
                {
                    key: "add",
                    text: "Report Time",
                    iconProps: { iconName: "Add" },
                    disabled: isLocked || isDeleted || isArchived || isReadonly,
                    onClick: onCreate
                }
            ];
        }

        const canChangeBillable = !isEnterpriseTimeTracker || !!entry?.attributes.Project && timeTrackingSettings.enableEditingBillable;
        const canChangeCostType = !!entry?.attributes.Project && timeTrackingSettings.enableEditingCostType && isEnterpriseTimeTracker;
         
        let cloneItems: IContextualMenuItem[] = [];
        if (canClone) {

            const onEntryClone = (dates: Date[]) => {
                onClone( _getEntriesToClone(entry, dates));
            };

            cloneItems = _buildCloneMenuItems(entry, resourceCalendarDataSet, timeTrackingSettings.timeReportingLock, onEntryClone);
        }
        
        return [
            {
                key: "edit",
                text: "Edit",
                iconProps: { iconName: "Edit" },
                disabled: isLocked || isReadonly,
                onClick: () => onStartEditing(entry)
            },
            canChangeCostType
                ? {
                    key: "changeCostType",
                    iconProps: { iconName: "Money" },
                    text: "Cost Type",
                    subMenuProps: {
                        items: [
                            _buildMarkAsMenuItem("costType", CostType.Capex, CostType.Capex, entry, nameof<TimeTrackingEntryAttr>("CostType"), onEditCompleted),
                            _buildMarkAsMenuItem("costType", CostType.Opex, CostType.Opex, entry, nameof<TimeTrackingEntryAttr>("CostType"), onEditCompleted)
                        ]
                    }
                }
                : undefined,
            canChangeBillable
                ? {
                    key: "changeBillable",
                    iconProps: { iconName: "PPMXMoneyHand" },
                    text: "Billable",
                    subMenuProps: {
                        items: [
                            _buildMarkAsMenuItem("billable", "Yes", true, entry, nameof<TimeTrackingEntryAttr>("IsBillable"), onEditCompleted),
                            _buildMarkAsMenuItem("billable", "No", false, entry, nameof<TimeTrackingEntryAttr>("IsBillable"), onEditCompleted)
                        ]
                    }
                }
                : undefined,
            {
                key: 'clone',
                text: 'Clone',
                iconProps: { iconName: "Copy" },
                disabled: !cloneItems.length,
                subMenuProps: {
                    items: cloneItems
                }
            }
        ].filter(notUndefined);
    }, [entry, onCreate, onStartEditing, onClone, onEditCompleted, canClone]);


    return (
        <div className="timeline-tooltip time-tracking-tooltip">
            <div className="header">
                
                {
                    !!entry?.attributes.AdministrativeCategory
                        ? <TimeTrackingAdministrativeCategoryEntityName categoryId={entry?.attributes.AdministrativeCategory} />
                        : <EntityName
                            name={title}
                            isTimelineView
                            replaceCurrentUrl
                            renderLogo={() => <TaskIcon entity={{ externalData: {} }} />} />
                }
                
                {isLocked
                    ? <>(Locked)</>
                    : isArchived
                        ? <>({timeTrackingLine.attributes.IsAdministrative ? "Inactive" : "Archived"})</>
                        : isDeleted
                            ? <>(Deleted)</>
                            : <></>
                }
                
            </div>
            <div className="content">
                {
                    !!entry && visibleFields.map(field => {
                        return renderTooltipField(field.label || field.name, <DisplayField entity={entry} field={field} viewType="Card" />, field.name.toLowerCase());
                    })
                }
                
                <div className="item align-center menu">
                    <CommandBar className="header-command-bar"
                        items={commands}
                        styles={{ root: { padding: "unset", minWidth: 400 } }}
                    />
                </div>
            </div>
        </div>
    );
}

const _buildMarkAsMenuItem = (
    key: string,
    text: string,
    value: any,
    entry: TimeTrackingEntry,
    attribute: string,
    onEditCompleted: (id: string, attributes: Partial<TimeTrackingEntryAttr>) => void): IContextualMenuItem => {
    return {
        key: key+text,
        text: text,
        iconProps: entry.attributes[attribute] === value
            ? { iconName: "Accept" }
            : undefined,
        onClick: entry.attributes.IsBillable === value
            ? undefined
            : () => onEditCompleted(entry.id, { [attribute]: value })
    };
}

const _buildCloneMenuItems = (
    entry: TimeTrackingEntry,
    resourceCalendarDataSet: CalendarDataSet,
    timeReportingLock: MaybeDate,
    onClone: (dates: Date[]) => void): IContextualMenuItem[] => {

    const entryDate = toDateTime(entry.attributes.Date)!;

    const result: IContextualMenuItem[] = [];

    const prevDay = _getPrevWorkingDay(entryDate.clone(), resourceCalendarDataSet, timeReportingLock);
    
    if (prevDay) {

        result.push({
            key: "prevDay",
            text: "Previous working day",
            iconProps: {
                iconName: "PPMXArrowLeft"
            },
            onClick: (e, item) => {
                onClone([prevDay]);
            }
        });                                                            
    }

    const nextDay = _getNextWorkingDayToClone(entryDate.clone(), resourceCalendarDataSet, timeReportingLock);

    if (nextDay) {
        result.push({
            key: "nextDay",
            text: "Next working day",
            iconProps: {
                iconName: "PPMXArrowRight"
            },
            onClick: () => {
                onClone([nextDay]);
            }
        },)
    }

    const thisWeek = _getThisWeekWorkingDaysToClone(toDateTime(entry.attributes.Date)!, resourceCalendarDataSet, timeReportingLock);

    if (thisWeek.length) {

        result.push({
            key: "allWeek",
            text: "Fill all this week",
            iconProps: {
                iconName: "CalendarWorkWeek"
            },
            onClick: () => {
                onClone(thisWeek);
            }
        });
    }

    const thisMonth = _getThisMonthWorkingDaysToClone(toDateTime(entry.attributes.Date)!, resourceCalendarDataSet, timeReportingLock);

    if (thisMonth.length) {
        result.push({
            key: "allMonth",
            text: "Fill all this month",
            iconProps: {
                iconName: "Calendar"
            },
            onClick: () => {
                onClone(thisMonth);
            }
        });
    }

    return result;
}

const _getPrevWorkingDay = (date: Date, resourceCalendarDataSet: CalendarDataSet, timeReportingLock: MaybeDate): Date | undefined => {
    
    const prevWorkingDay = date.addWorkingDays(-1, resourceCalendarDataSet);

    return IsLockedPeriod(prevWorkingDay, timeReportingLock)
        ? undefined
        : prevWorkingDay;
}

const _getNextWorkingDayToClone = (date: Date, resourceCalendarDataSet: CalendarDataSet, timeReportingLock: MaybeDate): Date | undefined => {
    
    const preWorkingDay = date.addWorkingDays(1, resourceCalendarDataSet);

    return IsLockedPeriod(preWorkingDay, timeReportingLock)
        ? undefined
        : preWorkingDay;
}

const _getThisWeekWorkingDaysToClone = (date: Date, resourceCalendarDataSet: CalendarDataSet, timeReportingLock: MaybeDate): Date[] => {
    
    const { start, end } = date.getThisWeek();

    const result = getDaysBetweenDates(start, end).filter(_ => _.toDateOnlyString() !== date.toDateOnlyString() && _.isWorkingDay(resourceCalendarDataSet) && !IsLockedPeriod(_, timeReportingLock));

    return result;
}

const _getThisMonthWorkingDaysToClone = (date: Date, resourceCalendarDataSet: CalendarDataSet, timeReportingLock: MaybeDate): Date[] => {
    
    const { start, end } = date.getThisMonth();

    const result = getDaysBetweenDates(start, end).filter(_ => _.toDateOnlyString() !== date.toDateOnlyString() && _.isWorkingDay(resourceCalendarDataSet) && !IsLockedPeriod(_, timeReportingLock));

    return result;
}

const _getEntriesToClone = (entry: TimeTrackingEntry, dates: Date[]): CreateTimeTrackingEntry[] => {
    return dates.map(_ => {
        return {
            attributes: {
                ...entry.attributes,
                Date: _,
            },
            sourceInfo: entry.sourceInfos?.find(si => si.type === TimeTrackingEntrySourceType.ResourcePlaSuggestion)
        }
    });
}

function mapStateToProps(state: ApplicationState, ownProps: OwnProps): StoreProps {
    const timeTrackingEntryFields = state.fields[EntityType.TimeTrackingEntry];

    return {
        fields: timeTrackingEntryFields.allIds.map(_ => timeTrackingEntryFields.byId[_]),
        timeTrackingSettings: state.tenant.timeTracking.globalSettings,
        isEnterpriseTimeTracker: IsEnterpriseTimeTracker(state.tenant.subscription)
    };
}

export default connect(mapStateToProps)(TimeTrackingCellTooltip);

export const renderTooltipField = (name: string, valueElement: JSX.Element | string, className?: string): JSX.Element => {
    return (
        <div className={`item align-center ${className}`} key={name}>
            <div className="label">{name}</div>
            <div className="value">
                {valueElement}
            </div>
        </div>
    );
};

export const isFieldHasValue = (value: any): boolean => {
    if (value === undefined || value === null || value === "" || IsEmptyObject(value) ) {
        return false;
    }

    return true;
}