import { Label } from 'office-ui-fabric-react';
import React from 'react';
import './AIDigestDataSelector.css';
import Spinner from '../../../common/Spinner';
import { DigestDataItem } from '../common';
import SelectableCardItem from '../../../common/SelectableCardItem';

type Props = {
    items: DigestDataItem[];
    onItemChecked: (item: DigestDataItem) => void;
    isLoading: boolean;
}

const AIDigestDataSelector = (props: Props) => {
    const { items, isLoading, onItemChecked } = props;

    return <>
        {isLoading
            ? <Spinner />
            : <div className='ai-digest-data-selector'>
                <Label className='ai-digest-items-header'>Data included in Project Digest</Label>
                <div className='selecteable-cards-items'>
                    {items.map(item =>
                        <SelectableCardItem key={item.key} isSelected={item.isSelected} onItemChecked={() => onItemChecked(item)} >
                            <div className='item-header'>
                                <div className='item-name'>{item.name}</div>
                            </div>
                            <div className='item-description'>{item.description}</div>
                        </SelectableCardItem>
                    )}
                </div>
            </div>
        }
    </>
}

export default AIDigestDataSelector;