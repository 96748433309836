import * as React from "react";
import { ITaskAttrs, ITask, buildPredecessorsMap, IPredecessorInfo, PredecessorType, PredecessorTypeMetadata } from "../../entities/Subentities";
import { Validator, IValidator, ValidationBuilder } from "../../validation";
import { nameof } from "../../store/services/metadataService";
import * as Metadata from '../../entities/Metadata';
import DatePickerInput from '../common/inputs/DatePickerInput';
import SliderInput from '../common/inputs/SliderInput';
import GroupDropdown from "../common/inputs/GroupDropdown";
import OptionsPicker, { Option } from "../common/inputs/OptionsPicker";
import * as utils from '../common/timeline/utils';
import { Dictionary, IBaseEntity, MaybeDate, ProgressCalculationType } from "../../entities/common";
import { CalendarDataSet } from "../../store/CalendarStore";
import NumberInput from "../common/inputs/NumberInput";
import { applyPredecessors, handleDuration } from "../utils/duration";
import { FormatDate, HUNDRED_PCT, MaxDateConst, MaxDuration, MinDateConst, adjustProgress, formatValue, notUndefined, toDate, toDictionaryById } from "../utils/common";
import { css, DirectionalHint } from "office-ui-fabric-react";
import { ProjectInfo } from "../../store/ProjectsListStore";
import { IInputProps } from "../common/interfaces/IInputProps";

const baseValidatorsBuilder = () => ({
    [nameof<ITaskAttrs>("Progress")]: (state: ITask, field: Metadata.Field): IValidator => {
        const validatorBuilder = Validator.new();
        if (field.type === Metadata.FieldType.Decimal) {
            validatorBuilder.decimal();
        }
        else if (field.type === Metadata.FieldType.Integer) {
            validatorBuilder.int32();
        }
        if (field.settings?.required) {
            validatorBuilder.required();
        }
        validatorBuilder.min(field.settings?.minValue).max(field.settings?.maxValue).step(field.settings?.minValue, field.settings?.step)
        return validatorBuilder.build();
    }
});

export const validatorsBuilder = (calendar: CalendarDataSet, tasksLoader: () => LoadedTask[] | undefined) => {
    return {
        ...baseValidatorsBuilder(),
        [nameof<ITaskAttrs>("StartDate")]: (state: ITask, field: Metadata.Field): IValidator => {
            const validatorBuilder = Validator.new().date();
            const dictatedDates = TaskScheduleCalculator.GetTaskDictatedDates(state, toDictionaryById(tasksLoader() ?? []), calendar);
            if (!!dictatedDates?.startDate) {
                validatorBuilder.required("Required when ToStart predecessor set");
            } else if (field.settings?.required) {
                validatorBuilder.required();
            }
            validatorBuilder.dateIsLessThenOrEqual(state.attributes.DueDate);
            return validatorBuilder.dateIsGreaterThenOrEqual(dictatedDates?.startDate, messages.predecessorDate).build();
        },
        [nameof<ITaskAttrs>("DueDate")]: (state: ITask, field: Metadata.Field): IValidator => {
            const validatorBuilder = Validator.new().date();
            const dictatedDates = TaskScheduleCalculator.GetTaskDictatedDates(state, toDictionaryById(tasksLoader() ?? []), calendar);

            setDueDateRequiredValidator(validatorBuilder, state, field, dictatedDates);

            validatorBuilder.customDate((date) => {
                if (date && state.attributes.StartDate && state.attributes.Duration !== undefined
                    && state.attributes.Duration !== utils.getWorkingDaysBetweenDates(toDate(state.attributes.StartDate)!, date, calendar)
                    && !(state.attributes.Duration === 0 && toDate(state.attributes.StartDate)?.getDate() === date?.getDate())) {
                    return false;
                }
                return true;
            }, "Dates mismatch due to calendar changes");

            return setDueDateIsGreaterValidator(validatorBuilder, state, dictatedDates).build();
        },
        [nameof<ITaskAttrs>("Duration")]: (state: ITask, field: Metadata.Field): IValidator => {
            const validatorBuilder = Validator.new().int32();
            if (field.settings?.required) {
                validatorBuilder.required();
            }
            return validatorBuilder.min(0).max(MaxDuration).build();
        },
        [nameof<ITaskAttrs>("Predecessor")]: (state: ITask, field: Metadata.Field): IValidator => {
            const tasksMap = toDictionaryById(tasksLoader() ?? []);
            const validatorBuilder = Validator
                .new()
                .custom((_: ITaskAttrs["Predecessor"]) => {
                    const predecessors = (_ || []).map(p => tasksMap[p.id]).filter(notUndefined);
                    return predecessors.every(p => isAllowedAsPredecessorByHierarchy(state, p));
                }, messages.notAllowedByHierarchyRemoveIt);

            return validatorBuilder.build();
        }
    };
};

export const inlineValidatorsBuilder = (calendar: CalendarDataSet, tasksLoader: () => LoadedTask[] | undefined, taskFields: Metadata.Field[]) => {
    return {
        ...baseValidatorsBuilder(),
        [nameof<ITaskAttrs>("StartDate")]: (state: ITask, field: Metadata.Field): IValidator => {
            const tasksMap = toDictionaryById(tasksLoader() ?? []);
            const validatorBuilder = Validator.new().date();
            const dictatedDates = TaskScheduleCalculator.GetTaskDictatedDates(state, tasksMap, calendar);
            if (!!dictatedDates?.startDate) {
                validatorBuilder.required("Required when ToStart predecessor set");
            } else if (field.settings?.required) {
                validatorBuilder.required();
            }

            const durationName = Metadata.getLabel(taskFields.filter(_ => _.name === nameof<ITaskAttrs>("Duration"))[0]);
            validatorBuilder.customDate((date) => {
                const extra = handleDuration(state.attributes, { StartDate: date }, calendar, true, dictatedDates);
                return extra?.Duration === undefined || extra?.Duration === null || extra.Duration <= MaxDuration;
            }, `${durationName} must be less than or equal to 10000 d`);

            return validatorBuilder.dateIsGreaterThenOrEqual(dictatedDates?.startDate, messages.predecessorDate).build();
        },
        [nameof<ITaskAttrs>("DueDate")]: (state: ITask, field: Metadata.Field): IValidator => {
            const tasksMap = toDictionaryById(tasksLoader() ?? []);
            const validatorBuilder = Validator.new().date();
            const dictatedDates = TaskScheduleCalculator.GetTaskDictatedDates(state, tasksMap, calendar);

            setDueDateRequiredValidator(validatorBuilder, state, field, dictatedDates);

            const durationName = Metadata.getLabel(taskFields.filter(_ => _.name === nameof<ITaskAttrs>("Duration"))[0]);
            validatorBuilder.customDate((date) => {
                const extra = handleDuration(state.attributes, { DueDate: date }, calendar, false, dictatedDates);
                return extra?.Duration === undefined || extra?.Duration === null || extra.Duration <= MaxDuration;
            }, `${durationName} must be less than or equal to 10000 d`);

            return setDueDateIsGreaterValidator(validatorBuilder, state, dictatedDates).build();
        },
        [nameof<ITaskAttrs>("Duration")]: (state: ITask, field: Metadata.Field): IValidator => {
            const validatorBuilder = Validator.new().int32().min(0).max(MaxDuration);
            const dictatedDates = TaskScheduleCalculator.GetDictatedDates(state.attributes.Predecessor, toDictionaryById(tasksLoader() ?? []), calendar);

            if (field.settings?.required) {
                validatorBuilder.required();
            }

            const startDateName = Metadata.getLabel(taskFields.filter(_ => _.name === nameof<ITaskAttrs>("StartDate"))[0]);
            validatorBuilder.custom((duration) => {
                const extra = handleDuration(state.attributes, { Duration: duration }, calendar, true, dictatedDates);
                return extra?.StartDate === undefined || extra?.StartDate === null || toDate(extra.StartDate)! > MinDateConst;
            }, `${startDateName} should be greater or equal ${FormatDate(MinDateConst)}`);

            const dueDateName = Metadata.getLabel(taskFields.filter(_ => _.name === nameof<ITaskAttrs>("DueDate"))[0]);
            validatorBuilder.custom((duration) => {
                const extra = handleDuration(state.attributes, { Duration: duration }, calendar, false, dictatedDates);
                return extra?.DueDate === undefined || extra?.DueDate === null || toDate(extra.DueDate)! < MaxDateConst;
            }, `${dueDateName} should be less or equal ${FormatDate(MaxDateConst)}`);

            return validatorBuilder.build();
        },
        [nameof<ITaskAttrs>("IsMilestone")]: (state: ITask, field: Metadata.Field): IValidator => {
            const dueDateName = Metadata.getLabel(taskFields.filter(_ => _.name === nameof<ITaskAttrs>("DueDate"))[0]);
            return Validator.new()
                .custom((flag) => !flag || !!state.attributes.DueDate, `${dueDateName} is required`)
                .required()
                .build();
        },
    };
}

const setDueDateRequiredValidator = (validatorBuilder: ValidationBuilder, state: ITask, field: Metadata.Field, dictatedDates: DictatedDates) => {
    if (state.attributes.IsMilestone) {
        validatorBuilder.required("Required for Milestone");
    } else if (dictatedDates?.dueDate) {
        validatorBuilder.required("Required when ToFinish predecessor set");
    } else if (field.settings?.required) {
        validatorBuilder.required();
    }

    return validatorBuilder;
}

const setDueDateIsGreaterValidator = (validatorBuilder: ValidationBuilder, state: ITask, dictatedDates: DictatedDates) => {
    const minDueDate = utils.maxBy([
        { date: dictatedDates?.dueDate, message: messages.predecessorDate },
        { date: state.attributes.StartDate }
    ], _ => _.date);

    return validatorBuilder.dateIsGreaterThenOrEqual(minDueDate?.date, minDueDate?.message);
}

export const messages = {
    predecessorDate: 'Date should be set to {0}, per predecessor',
    notAllowedByHierarchy: 'Dependency between subtasks and their summary tasks cannot be created.',
    notAllowedByHierarchyRemoveIt: "Dependency between subtasks and their summary tasks is not allowed. Remove it to save the task."
}

export type LoadedTask = { id: string, attributes: { Name: string, StartDate?: MaybeDate, DueDate?: MaybeDate, Duration?: number | null, Predecessor?: { id: string }[] } };
export const rendersBuilder = (
    project: ProjectInfo,
    groups: Metadata.Group[],
    calendar: CalendarDataSet,
    urlBuilder: (task: { id: string }) => string | undefined,
    tasksLoader: () => LoadedTask[] | undefined
) => ({
    [nameof<ITaskAttrs>("StartDate")]: (props: IInputProps, state: ITask, field: Metadata.Field, validator?: Validator): JSX.Element | null => {
        const taskMap = toDictionaryById(tasksLoader() ?? []);
        const dictatedDates = TaskScheduleCalculator.GetDictatedDates(state.attributes.Predecessor, taskMap, calendar);
        return <DatePickerInput
            {...props}
            inputProps={{ readOnly: field.isReadonly }}
            validator={validator}
            minDate={isTaskPredecessorHierarchyValid(state, taskMap) ? dictatedDates?.startDate : undefined}
            onChanged={props.onChanged ? (value) => {
                const extra = handleDuration(state.attributes, { StartDate: value }, calendar, true, dictatedDates);
                props.onChanged?.(value, extra);
            } : undefined}
        />;
    },
    [nameof<ITaskAttrs>("DueDate")]: (props: IInputProps, state: ITask, field: Metadata.Field, validator?: IValidator): JSX.Element | null => {
        const taskMap = toDictionaryById(tasksLoader() ?? []);
        const dictatedDates = TaskScheduleCalculator.GetDictatedDates(state.attributes.Predecessor, taskMap, calendar);
        const minDueDate = utils.max([isTaskPredecessorHierarchyValid(state, taskMap) ? dictatedDates?.dueDate : undefined, state.attributes.StartDate]);
        return <DatePickerInput {...props}
            inputProps={{ readOnly: field.isReadonly }}
            validator={validator}
            minDate={minDueDate}
            onChanged={props.onChanged ? (value) => {
                const extra = handleDuration(state.attributes, { DueDate: value }, calendar, false, dictatedDates);
                props.onChanged?.(value, extra);
            } : undefined}
        />;
    },
    [nameof<ITaskAttrs>("Duration")]: (props: IInputProps, state: ITask, field: Metadata.Field, validator?: IValidator): JSX.Element | null => {
        return <NumberInput {...props} inputProps={toNumberInputProps(field)} validator={validator} format={field.settings?.format}
            onChanged={undefined} onEditComplete={(value) => {
                const dictatedDates = TaskScheduleCalculator.GetDictatedDates(state.attributes.Predecessor, toDictionaryById(tasksLoader() ?? []), calendar);
                const extra = handleDuration(state.attributes, { Duration: value ?? undefined }, calendar, true, dictatedDates);
                props.onChanged?.(value, extra);
                props.onEditComplete(value, extra);
            }}
        />;
    },
    [nameof<ITaskAttrs>("Progress")]: (props: IInputProps, state: ITask, field: Metadata.Field, validator?: IValidator): JSX.Element | null => {
        const sliderProps = {
            min: field.settings?.minValue,
            max: field.settings?.maxValue,
            step: field.settings?.step,
            defaultValue: field.settings?.defaultValue,
            className: field.settings?.className,
            readOnly: field.isReadonly
        };
        return <SliderInput {...props} inputProps={sliderProps} validator={validator}
            onEditComplete={(value: number | null) => {
                const extra = TaskProgressCalculator.RecalculateByProgress(project, state, value);
                props.onChanged?.(value, extra);
                props.onEditComplete(value, extra);
            }}
        />;
    },
    [nameof<ITaskAttrs>("Effort")]: (props: IInputProps, state: ITask, field: Metadata.Field, validator?: IValidator): JSX.Element | null => {
        return <NumberInput {...props} inputProps={toNumberInputProps(field)} validator={validator} format={field.settings?.format}
            onChanged={undefined} onEditComplete={(value) => {
                const extra = TaskProgressCalculator.RecalculateByEffort(project, state, value);
                props.onChanged?.(value, extra);
                props.onEditComplete(value, extra);
            }}
        />;
    },
    [nameof<ITaskAttrs>("CompletedWork")]: (props: IInputProps, state: ITask, field: Metadata.Field, validator?: IValidator): JSX.Element | null => {
        return <NumberInput {...props} inputProps={toNumberInputProps(field)} validator={validator} format={field.settings?.format}
            onChanged={undefined} onEditComplete={(value) => {
                const extra = TaskProgressCalculator.RecalculateByCompletedWork(project, state, value);
                props.onChanged?.(value, extra);
                props.onEditComplete(value, extra);
            }}
        />;
    },
    [nameof<ITaskAttrs>("RemainingWork")]: (props: IInputProps, state: ITask, field: Metadata.Field, validator?: IValidator): JSX.Element | null => {
        return <NumberInput {...props} inputProps={toNumberInputProps(field)} validator={validator} format={field.settings?.format}
            onChanged={undefined} onEditComplete={(value) => {
                const extra = TaskProgressCalculator.RecalculateByRemainingWork(project, state, value);
                props.onChanged?.(value, extra);
                props.onEditComplete(value, extra);
            }}
        />;
    },
    [nameof<ITaskAttrs>("Group")]: (props: IInputProps, state: any, field: Metadata.Field): JSX.Element | null =>
        <GroupDropdown dropdownInputProps={props} value={props.value} groups={groups} />,
    [nameof<ITaskAttrs>("Predecessor")]: (props: IInputProps, state: ITask, field: Metadata.Field, validator?: IValidator): JSX.Element | null => {
        const tasks = tasksLoader();
        const tasksMap = toDictionaryById(tasks ?? []);
        const selectedItems = props.value ? props.value.map((_: any) => ({
            key: _.id,
            text: `${_.name} (${PredecessorTypeMetadata[_.type].abbreviation}${_.lag < 0 ? "" : "+"}${formatValue(_.lag, Metadata.FormatType.Days)})`,
            url: urlBuilder(_),
            data: {
                task: tasksMap[_.id],
                lag: _.lag,
                type: _.type
            }
        })) : [];
        const onChange = props.onChanged
            ? (v?: Option[]) => {
                const predecessorsData = v?.length ? (v as any as { data: { task: ITask, lag: number, type: PredecessorType } }[]).map(_ => _.data) : [];
                const predecessors = predecessorsData?.map(_ => ({ id: _.task.id, name: _.task.attributes.Name, lag: _.lag, type: _.type }));
                const extra = applyPredecessors(state.attributes, predecessors, tasksMap, calendar);
                props.onChanged!(predecessors, extra);
            } : undefined
        return <>
            <OptionsPicker
                className={css(validator?.isValid(props.value) ? undefined : "invalid")}
                disabled={props.readOnly || field.isReadonly}
                pickerCalloutProps={{ directionalHint: DirectionalHint.bottomRightEdge }}
                onResolveSuggestions={(filter, selectedItems) => {
                    const predecessorsMap = buildPredecessorsMap(tasks);
                    return tasks
                        ?.filter(_ => (!state.id || _.id != state.id && !predecessorsMap[_.id]?.[state.id])
                            && isAllowedAsPredecessorByHierarchy(state, _)
                            && _.attributes.Name.toLowerCase().includes(filter.toLowerCase())
                            && !selectedItems?.find(i => _.id == i.key))
                        .map<Option>(_ => ({
                            key: _.id,
                            text: _.attributes.Name,
                            url: urlBuilder(_),
                            data: {
                                task: _,
                                lag: 0,
                                type: PredecessorType.FinishToStart
                            }
                        })) || [];
                }}
                onChange={onChange}
                selectedItems={selectedItems}
            />
            <div className="error-message">{validator?.getErrorMessage(props.value)}</div>
        </>
    }
})

export const inlineRendersBuilder = (
    project: ProjectInfo,
    groups: Metadata.Group[],
    calendar: CalendarDataSet,
    urlBuilder: (task: { id: string }) => string | undefined,
    tasksLoader: () => LoadedTask[] | undefined
) => ({
    [nameof<ITaskAttrs>("StartDate")]: (props: IInputProps, state: ITask, field: Metadata.Field, validator?: Validator): JSX.Element | null => {
        const taskMap = toDictionaryById(tasksLoader() ?? []);
        const dictatedDates = TaskScheduleCalculator.GetDictatedDates(state.attributes.Predecessor, taskMap, calendar);
        return <DatePickerInput
            {...props}
            inputProps={{ readOnly: field.isReadonly }}
            validator={validator}
            minDate={isTaskPredecessorHierarchyValid(state, taskMap) ? dictatedDates?.startDate : undefined}
            onChanged={(value) => props.onChanged?.(value, handleDuration(state.attributes, { StartDate: value }, calendar, true, dictatedDates))}
            onEditComplete={() => props.onEditComplete(null)}
        />;
    },
    [nameof<ITaskAttrs>("DueDate")]: (props: IInputProps, state: ITask, field: Metadata.Field, validator?: IValidator): JSX.Element | null => {
        const taskMap = toDictionaryById(tasksLoader() ?? []);
        const dictatedDates = TaskScheduleCalculator.GetDictatedDates(state.attributes.Predecessor, taskMap, calendar);
        const minDueDate = utils.max([isTaskPredecessorHierarchyValid(state, taskMap) ? dictatedDates?.dueDate : undefined, state.attributes.StartDate]);
        return <DatePickerInput {...props}
            inputProps={{ readOnly: field.isReadonly }}
            validator={validator}
            minDate={minDueDate}
            onChanged={(value) => props.onChanged?.(value, handleDuration(state.attributes, { DueDate: value }, calendar, false, dictatedDates))}
            onEditComplete={() => props.onEditComplete(null)}
        />;
    },
    [nameof<ITaskAttrs>("Duration")]: (props: IInputProps, state: ITask, field: Metadata.Field, validator?: IValidator): JSX.Element | null => {
        return <NumberInput {...props} inputProps={toNumberInputProps(field)} validator={validator}
            onChanged={(value) => {
                const dictatedDates = TaskScheduleCalculator.GetDictatedDates(state.attributes.Predecessor, toDictionaryById(tasksLoader() ?? []), calendar);
                props.onChanged?.(value, handleDuration(state.attributes, { Duration: value ?? undefined }, calendar, true, dictatedDates))
            }}
            onEditComplete={() => props.onEditComplete(null)}
        />;
    },

    [nameof<ITaskAttrs>("Progress")]: (props: IInputProps, state: ITask, field: Metadata.Field, validator?: IValidator): JSX.Element | null => {
        return <NumberInput {...props} inputProps={toNumberInputProps(field)} validator={validator} format={field.settings?.format}
            onChanged={(value) => props.onChanged?.(value, validator?.isValid(value) ? TaskProgressCalculator.RecalculateByProgress(project, state, value) : undefined)}
            onEditComplete={() => props.onEditComplete(null)}
        />;
    },
    [nameof<ITaskAttrs>("Effort")]: (props: IInputProps, state: ITask, field: Metadata.Field, validator?: IValidator): JSX.Element | null => {
        return <NumberInput {...props} inputProps={toNumberInputProps(field)} validator={validator} format={field.settings?.format}
            onChanged={(value) => props.onChanged?.(value, validator?.isValid(value) ? TaskProgressCalculator.RecalculateByEffort(project, state, value) : undefined)}
            onEditComplete={() => props.onEditComplete(null)}
        />;
    },
    [nameof<ITaskAttrs>("CompletedWork")]: (props: IInputProps, state: ITask, field: Metadata.Field, validator?: IValidator): JSX.Element | null => {
        return <NumberInput {...props} inputProps={toNumberInputProps(field)} validator={validator} format={field.settings?.format}
            onChanged={(value) => props.onChanged?.(value, validator?.isValid(value) ? TaskProgressCalculator.RecalculateByCompletedWork(project, state, value) : undefined)}
            onEditComplete={() => props.onEditComplete(null)}
        />;
    },
    [nameof<ITaskAttrs>("RemainingWork")]: (props: IInputProps, state: ITask, field: Metadata.Field, validator?: IValidator): JSX.Element | null => {
        return <NumberInput {...props} inputProps={toNumberInputProps(field)} validator={validator} format={field.settings?.format}
            onChanged={(value) => props.onChanged?.(value, validator?.isValid(value) ? TaskProgressCalculator.RecalculateByRemainingWork(project, state, value) : undefined)}
            onEditComplete={() => props.onEditComplete(null)}
        />;
    },

    [nameof<ITaskAttrs>("Group")]: (props: IInputProps, state: any, field: Metadata.Field): JSX.Element | null =>
        <GroupDropdown value={props.value} groups={groups} hideCaretDown
            dropdownInputProps={{ ...props, onEditComplete: () => props.onEditComplete(null) }} />,
    [nameof<ITaskAttrs>("Predecessor")]: (props: IInputProps, state: ITask, field: Metadata.Field): JSX.Element | null => null
})

export const toNumberInputProps = (field: Metadata.Field): Dictionary<any> => {
    return {
        readOnly: field.isReadonly,
        disabled: field.isReadonly,
        placeholder: field.settings?.placeholder
    };
}

export class DictatedDates {
    startDate?: Date;
    dueDate?: Date;
}

// this functionality realized on server side too (in TaskScheduleCalculator)
export class TaskScheduleCalculator {
    public static GetDatesDictatedByPredecessor(predecessor: LoadedTask, info: IPredecessorInfo, calendarSettings: CalendarDataSet): DictatedDates {
        if (info.type === PredecessorType.FinishToStart) {
            const date = toDate(predecessor.attributes.DueDate ?? predecessor.attributes.StartDate);
            return {
                startDate: date?.clone()
                    .addWorkingDays(predecessor.attributes.Duration === 0 && date.isWorkingDay(calendarSettings) ? 0 : 1, calendarSettings)
                    .addWorkingDays(info.lag, calendarSettings)
            };
        }
        if (info.type === PredecessorType.StartToStart) {
            const date = toDate(predecessor.attributes.StartDate ?? predecessor.attributes.DueDate);
            return {
                startDate: date?.clone()
                    .addWorkingDays(date.isWorkingDay(calendarSettings) ? 0 : 1, calendarSettings)
                    .addWorkingDays(info.lag, calendarSettings)
            };
        }
        if (info.type === PredecessorType.FinishToFinish) {
            const date = toDate(predecessor.attributes.DueDate ?? predecessor.attributes.StartDate);
            return {
                dueDate: date?.clone()
                    .addWorkingDays(date.isWorkingDay(calendarSettings) ? 0 : 1, calendarSettings)
                    .addWorkingDays(info.lag, calendarSettings)
            };
        }
        if (info.type === PredecessorType.StartToFinish) {
            const date = toDate(predecessor.attributes.StartDate ?? predecessor.attributes.DueDate);
            return {
                dueDate: date?.clone()
                    .addWorkingDays(date.isWorkingDay(calendarSettings) ? 0 : 1, calendarSettings)
                    .addWorkingDays(info.lag, calendarSettings)
            };
        }
        return {};
    }

    public static GetTaskDictatedDates(task: ITask, tasksMap: Dictionary<LoadedTask>, calendarSettings: CalendarDataSet): DictatedDates {
        if (!task.attributes.Predecessor?.length) {
            return {};
        }

        if (!isTaskPredecessorHierarchyValid(task, tasksMap)) {
            return {};
        }

        return TaskScheduleCalculator.GetDictatedDates(task.attributes.Predecessor, tasksMap, calendarSettings);
    }

    public static GetDictatedDates(predecessors: IPredecessorInfo[] | undefined, tasksMap: Dictionary<LoadedTask>, calendarSettings: CalendarDataSet): DictatedDates {
        if (!predecessors) {
            return {};
        }

        const dates = predecessors
            .filter(_ => tasksMap[_.id])
            .map(_ => TaskScheduleCalculator.GetDatesDictatedByPredecessor(tasksMap[_.id], _, calendarSettings));
        const dictatedDates = { startDate: utils.max(dates.map(_ => _.startDate)), dueDate: utils.max(dates.map(_ => _.dueDate)) };

        if (dictatedDates.startDate && dictatedDates.dueDate && dictatedDates.startDate > dictatedDates.dueDate) {
            dictatedDates.dueDate = dictatedDates.startDate;
        }

        return dictatedDates;
    }

    public static GetLag(expectedDate: Date, currentDate: Date, calendarSettings: CalendarDataSet) {
        if (expectedDate > currentDate) {
            return utils.getWorkingDaysBetweenDates(currentDate, expectedDate, calendarSettings) - 1;
        } else if (expectedDate < currentDate) {
            return -1 * (utils.getWorkingDaysBetweenDates(expectedDate, currentDate, calendarSettings) - 1);
        }
        return 0;
    }
}

const tenMultiplier: number = 10;

export class TaskProgressCalculator {

    public static RecalculateByProgress(project: ProjectInfo, task: ITask, value: number | null): Partial<ITaskAttrs> | undefined {
        if (!task.isAutoMode
            || project.settings.progressCalculationType !== ProgressCalculationType.Effort
            || !task.attributes.Effort
            || value === null) {
            return undefined;
        }
        let multiplayer = this.pointMultiplayer(task.attributes.Effort);
        const completedWork = this.Round(value * task.attributes.Effort * multiplayer) / (multiplayer * HUNDRED_PCT);
        multiplayer = this.pointMultiplayer(task.attributes.Effort, completedWork);
        return {
            CompletedWork: completedWork,
            RemainingWork: this.Round(task.attributes.Effort * multiplayer - completedWork * multiplayer) / multiplayer,
        }
    }

    public static RecalculateByCompletedWork(project: ProjectInfo, task: ITask, value: number | null): Partial<ITaskAttrs> | undefined {
        if (!task.isAutoMode
            || project.settings.progressCalculationType !== ProgressCalculationType.Effort
            || !task.attributes.Effort
            || value === null) {
            return undefined;
        }

        if (value > task.attributes.Effort) {
            return {
                Effort: value,
                Progress: HUNDRED_PCT,
                RemainingWork: 0,
            };
        }

        const progress = this.Round(value * HUNDRED_PCT / task.attributes.Effort);
        const multiplayer = this.pointMultiplayer(task.attributes.Effort, value);
        const remainingWork = multiplayer ? this.Round(task.attributes.Effort * multiplayer - value * multiplayer) / multiplayer : 0;
        return {
            Progress: adjustProgress(progress),
            RemainingWork: remainingWork
        };
    }

    public static RecalculateByEffort(project: ProjectInfo, task: ITask, value: number | null): Partial<ITaskAttrs> | undefined {
        if (!task.isAutoMode
            || project.settings.progressCalculationType !== ProgressCalculationType.Effort
            || task.attributes.CompletedWork === undefined
            || value === null) {
            return undefined;
        }

        if (value < task.attributes.CompletedWork) {
            return {
                CompletedWork: value,
                Progress: HUNDRED_PCT,
                RemainingWork: 0,
            };
        }

        const multiplayer = this.pointMultiplayer(task.attributes.CompletedWork, value);
        const remainingWork = this.Round(value * multiplayer - task.attributes.CompletedWork * multiplayer) / multiplayer;
        const progress = value ? this.Round(task.attributes.CompletedWork * HUNDRED_PCT / value) : 0;
        return {
            RemainingWork: remainingWork,
            Progress: adjustProgress(progress)
        }
    }

    public static RecalculateByRemainingWork(project: ProjectInfo, task: ITask, value: number | null): Partial<ITaskAttrs> | undefined {
        if (!task.isAutoMode
            || project.settings.progressCalculationType !== ProgressCalculationType.Effort
            || task.attributes.CompletedWork === undefined
            || value === null) {
            return undefined;
        }

        const multiplayer = this.pointMultiplayer(task.attributes.CompletedWork, value);
        const effort = this.Round(task.attributes.CompletedWork * multiplayer + value * multiplayer) / multiplayer;
        const progress = effort ? this.Round(task.attributes.CompletedWork * HUNDRED_PCT / effort) : 0;
        return {
            Effort: effort,
            Progress: adjustProgress(progress)
        }
    }

    private static Round(value: number): number {
        return Math.floor(value);
    }

    private static countDecimals = (values: number[]): number =>
        Math.max.apply(null, values.map(value => Math.floor(value) === value ? 0 : value?.toString().split(".")[1].length || 0));

    private static pointMultiplayer = (...values: number[]): number =>
        Math.pow(tenMultiplier, TaskProgressCalculator.countDecimals(values));
}

export function isAllowedAsPredecessorByHierarchy<T extends IBaseEntity & { hierarchy?: { parentIds?: string[]; } }>(task: T, maybePredecessor: T) {
    return !(task?.hierarchy?.parentIds?.includes(maybePredecessor.id) || maybePredecessor.hierarchy?.parentIds?.includes(task.id));
}

export function isTaskPredecessorHierarchyValid(task: ITask, tasksMap: Dictionary<LoadedTask>) {
    return (task.attributes.Predecessor ?? [])
        .map(_ => tasksMap[_.id])
        .filter(notUndefined)
        .every(_ => isAllowedAsPredecessorByHierarchy(task, _));
};