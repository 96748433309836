import { Icon, css } from "office-ui-fabric-react";
import React from "react";
import './SelectableCardItem.css';

type Props = {
    onItemChecked: () => void;
    isSelected: boolean,
    disabled?: boolean
}

const SelectableCardItem = (props: React.PropsWithChildren<Props>) => {
    const { onItemChecked } = props;
    const [isIconHovered, setIsIconHovered] = React.useState(false);

    return <div className={css('selecteable-card-item', props.isSelected ? 'selected-item' : undefined, props.disabled ? 'is-disabled': undefined) }>
        <div className='item-selector-container'>
            {props.children}
            <label className='item-selector'>
                <Icon className='item-checkbox' iconName={isIconHovered || props.isSelected ? 'CompletedSolid' : 'CircleRing'}
                    onMouseEnter={() => setIsIconHovered(true)}
                    onMouseLeave={() => setIsIconHovered(false)} />
                <input type="checkbox" checked={props.isSelected} onChange={() => onItemChecked()} />
            </label>
        </div>
    </div>
}

export default SelectableCardItem;