import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { loadTheme, registerIcons, initializeIcons, registerIconAlias } from 'office-ui-fabric-react';
import { initializeFileTypeIcons } from '@uifabric/file-type-icons';
import { createBrowserHistory } from 'history';

import ErrorPage from './components/ErrorPage';
import { App } from './components/App';
import { ConnectedRouter } from 'react-router-redux';
import configureStore from './configureStore';
import { get, setupFetchDefaults, appendFetchHeaderDefaults } from './fetch-interceptor';
import { routes } from './routes';
import { LoginApp } from './LoginApp';
import ErrorApp from './components/ErrorApp';
import { ApplicationState } from './store';
import * as analytics from './analytics';

import './site.css'
import { currency, cultureInfo, baseUrl } from './components/utils/common';
import { RegionInfo } from './LoginPage';
import { AuthProvider } from './entities/common';
import { ThemeType } from './store/Tenant';

loadTheme({
    palette: {
        themePrimary: '#128b10',

        themeDark: '#107c0e',
        themeDarkAlt: '#107c0e',
        themeDarker: '#0e6d0c',

        themeLight: '#B1DDB1',
        themeLighterAlt: '#16a914',
        themeLighter: '#16a914'
    }
});

initializeIcons();
initializeFileTypeIcons();
const iconsStyle = {
    MozOsxFontSmoothing: 'grayscale',
    WebkitFontSmoothing: 'antialiased',
    fontStyle: 'normal',
    fontWeight: 'normal',
    speak: 'none'
};
registerIcons({
    style: iconsStyle,
    fontFace: {
        fontFamily: `"FabricExternalMDL2Assets"`
    },
    icons: {
        'JiraLogo': <span className="icon icon-jira"></span>,
        'PlannerNewLogo': <span className="icon icon-planner"></span>,
        'SpoLogo': <span className="icon icon-spo"></span>,
        'MondayComLogo': <span className="icon icon-mondaycom"></span>,
        'FileLinkIcon': <span className="icon icon-file-link"></span>,
        'MPPLogo': <span className="icon icon-mpp"></span>,
        'ConfluenceLogo': <span className="icon icon-confluence"></span>,
        'SlackLogo': <span className="icon icon-slack"></span>,
        'SmartsheetLogo': <span className="icon icon-smartsheet"></span>,
        'P4WLogo': <span className="icon icon-p4w"></span>,
        'PPMXTeamsLogo': <span className="icon icon-teams"></span>,
        'PPMXLinkProject': <span className="icon icon-link-project"></span>,
        'PPMXWorkTogether': <span className="icon icon-link-work-together"></span>,
        'PPMXLink': <span className="icon icon-link"></span>,
        'PPMXLinkWhite': <span className="icon icon-link-white"></span>,
        'PPMXFeedback': <span className="icon icon-feedback"></span>,
        'PPMXRisk': <span className="icon icon-risk"></span>,
        'PPMXIssue': <span className="icon icon-issue"></span>,
        'PPMXActionItem': <span className="icon icon-actionitem"></span>,
        'PPMXPurchaseOrderIcon': <span className="icon icon-purchaseorder"></span>,
        'PPMXInvoiceIcon': <span className="icon icon-invoice"></span>,
        'PPMXKeyDecision': <span className="icon icon-keydecision"></span>,
        'PPMXChangeRequest': <span className="icon icon-changerequest"></span>,
        'PPMXKeyDateCompleted': <span className="icon icon-keydates-keydate-completed"></span>,
        'PPMXMilestoneCompleted': <span className="icon icon-keydates-milestone-completed"></span>,
        'PPMXReleaseCompleted': <span className="icon icon-keydates-release-completed"></span>,
        'PPMXKeyDateCritical': <span className="icon icon-keydates-keydate-critical"></span>,
        'PPMXMilestoneCritical': <span className="icon icon-keydates-milestone-critical"></span>,
        'PPMXReleaseCritical': <span className="icon icon-keydates-release-critical"></span>,
        'PPMXKeyDateNA': <span className="icon icon-keydates-keydate-na"></span>,
        'PPMXMilestoneNA': <span className="icon icon-keydates-milestone-na"></span>,
        'PPMXReleaseNA': <span className="icon icon-keydates-release-na"></span>,
        'PPMXKeyDateAtrisk': <span className="icon icon-keydates-keydate-atrisk"></span>,
        'PPMXMilestoneAtrisk': <span className="icon icon-keydates-milestone-atrisk"></span>,
        'PPMXReleaseAtrisk': <span className="icon icon-keydates-release-atrisk"></span>,
        'PPMXKeyDateDisabled': <span className="icon icon-keydates-keydate-disabled"></span>,
        'PPMXMilestoneDisabled': <span className="icon icon-keydates-milestone-disabled"></span>,
        'PPMXReleaseDisabled': <span className="icon icon-keydates-release-disabled"></span>,
        'PPMXKeyDateLate': <span className="icon icon-keydates-keydate-late"></span>,
        'PPMXMilestoneLate': <span className="icon icon-keydates-milestone-late"></span>,
        'PPMXReleaseLate': <span className="icon icon-keydates-release-late"></span>,
        'PPMXKeyDateOntrack': <span className="icon icon-keydates-keydate-ontrack"></span>,
        'PPMXMilestoneOntrack': <span className="icon icon-keydates-milestone-ontrack"></span>,
        'PPMXReleaseOntrack': <span className="icon icon-keydates-release-ontrack"></span>,
        'PPMXDeliverableOntrack': <span className="icon icon-deliverable-ontrack"></span>,
        'PPMXDeliverableCompleted': <span className="icon icon-deliverable-completed"></span>,
        'PPMXDeliverableNA': <span className="icon icon-deliverable-na"></span>,
        'PPMXDeliverableAtRisk': <span className="icon icon-deliverable-atrisk"></span>,
        'PPMXDeliverableCritical': <span className="icon icon-deliverable-critical"></span>,
        'PPMXIterationCompleted': <span className="icon icon-iterations-completed"></span>,
        'PPMXIterationLate': <span className="icon icon-iterations-late"></span>,
        'PPMXIterationCurrent': <span className="icon icon-iterations-current"></span>,
        'PPMXIterationFuture': <span className="icon icon-iterations-future"></span>,
        'PPMXIterationProgressItems': <span className="icon icon-iterations-progress-items"></span>,
        'PPMXIterationProgressStoryPoints': <span className="icon icon-iterations-progress-story-points"></span>,
        'PPMXIterationProgressEffort': <span className="icon icon-iterations-progress-effort"></span>,
        'LoadingError': <span className="icon icon-error-loading"></span>,
        'DetailsSectionIcon': <span className="icon icon-details-section"></span>,
        'RiskSectionIcon': <span className="icon icon-risk-section"></span>,
        'IssueSectionIcon': <span className="icon icon-issue-section"></span>,
        'KeyDateSectionIcon': <span className="icon icon-keydate-section"></span>,
        'IterationSectionIcon': <span className="icon icon-iteration-section"></span>,
        'ResourcePlanSectionIcon': <span className="icon icon-resourceplan-section"></span>,
        'LessonLearnedSectionIcon': <span className="icon icon-lessonlearned-section"></span>,
        'ActionItemSectionIcon': <span className="icon icon-actionitem-section"></span>,
        'KeyDecisionSectionIcon': <span className="icon icon-keydecision-section"></span>,
        'SteeringCommitteeSectionIcon': <span className="icon icon-steeringcommittee-section"></span>,
        'ChangeRequestSectionIcon': <span className="icon icon-changerequest-section"></span>,
        'DependencySectionIcon': <span className="icon icon-dependency-section"></span>,
        'TeamSectionIcon': <span className="icon icon-team-section"></span>,
        'ScheduleSectionIcon': <span className="icon icon-schedule-section"></span>,
        'TaskManagementSectionIcon': <span className="icon icon-taskmanagement-section"></span>,
        'BudgetSectionIcon': <span className="icon icon-budget-section"></span>,
        'BudgetSectionLockedIcon': <span className="icon icon-budget-section-locked"></span>,
        'BenefitsSectionLockedIcon': <span className="icon icon-benefit-section-locked"></span>,
        'PPMXStartIcon': '',
        'PPMXFinishIcon': '',
        'PPMXTimeLogo': <span className="icon icon-time"></span>,
        'PPMXBaseline': <span className="icon icon-baseline"></span>,
        'PPMXImport': '',
        'PPMXUpdate': '',
        'PPMXCheckMark': '',
        'PPMXCircle': '',
        'PPMXBack': '',
        'PPMXLogo': '',
        'PPMXProject': '',
        'PPMXPortfolio': '',
        'PPMXProgram': '',
        'PPMXResource': '',
        'PPMXGear': '',
        'PPMXQuestion': '',
        'PPMXSearch': '',
        'PPMXExpand': '',
        'PPMXClose': '',
        'PPMXBreadcrumbsSeparator': '',
        'PPMXHome': '',
        'PPMXSort': '',
        'PPMXSortAZ': '',
        'PPMXSortZA': '',
        'PPMXSortNewest': '',
        'PPMXSortOldest': '',
        'PPMXArrowHorizontalStatus': '',
        'PPMXStatusCircleQuestionMark': '',
        'PPMXArrowUpStatus': '',
        'PPMXArrowDownStatus': '',
        'PPMXGuide': '',
        'PPMXCardStartDate': '',
        'PPMXCardFinishDate': '',
        'PPMXCardStage': '',
        'PPMXCardManager': '',
        'PPMXMore': '',
        'PPMXList': '',
        'PPMXTable': '',
        'PPMXCardView': '',
        'PPMXListView': '',
        'PPMXTimelineView': '',
        'PPMXActions': '',
        'PPMXAdd': '',
        'PPMXSectionSummary': '',
        'PPMXSectionDetails': '',
        'PPMXSectionStatuses': '',
        'PPMXSectionKeyDates': '',
        'PPMXSectionTeam': '',
        'PPMXSectionResourcePlan': '',
        'PPMXSectionRisks': '',
        'PPMXSectionIssues': '',
        'PPMXSectionLessonsLearned': '',
        'PPMXSectionActionItems': '',
        'PPMXSectionKeyDecisions': '',
        'PPMXSectionKeyResults': '',
        'PPMXSectionSteeringCommittee': '',
        'PPMXSelected': '',
        'PPMXSectionChangeRequests': <span className="icon icon-changerequests"></span>,
        'PPMXSectionDependencies': '',
        'PPMXCreateIdea': <span className="icon icon-create-idea"></span>,
        'PPMXSectionSchedule': '',
        'PPMXSectionBudget': '',
        'PPMXSectionProjects': '',
        'PPMXSectionPrograms': '',
        'PPMXSectionIterations': '',
        'PPMXStrategicAlignment': '',
        'PPMXCompanyObjectives': '',
        'PPMXDecisionMatrix': '',
        'PPMXBusinessIdeas': '\uF3B3', // unicode code of default office-ui fabric Rocket icon
        'PPMXPurchaseOrder': '\uEFE0',
        'PPMXInvoice': '\uE9DC',
        'PPMXRocket': '\uF3B3',
        'PPMXBusinessChallenge': '',
        'PPMXMapping': '',
        'PPMXArrowRight': '',
        'PPMXArrowLeft': '',
        'PPMXStateNotStarted': '',
        'PPMXStateStarted': '',
        'PPMXSaveAsLayout': '',
        'PPMXApplyLayout': '',
        'PPMXPeopleInvite': '',
        'PPMXStrategicPriority': '',
        'PPMXInsightsProjectsSummary': <span className="icon icon-insights-projects-summary"></span>,
        'PPMXInsightsStatuses': <span className="icon icon-insights-statuses"></span>,
        'PPMXInsightsDueThisWeek': <span className="icon icon-insights-due-this-week"></span>,

        'PPMXTaskPriorityNA': <span className="icon icon-priority-na"></span>,
        'PPMXTaskPriorityLow': <span className="icon icon-priority-low"></span>,
        'PPMXTaskPriorityMedium': <span className="icon icon-priority-medium"></span>,
        'PPMXTaskPriorityHigh': <span className="icon icon-priority-high"></span>,
        'PPMXTaskPriorityUrgent': <span className="icon icon-priority-urgent"></span>,
        
        'PPMXTaskProgress': '',
        'PPMInsights': <span className="icon icon-ppm-insights"></span>,
        "PPMXArrowDown": <span className="icon icon-arrow-down"></span>,
        "PPMXHelp": <span className="icon icon-help"></span>,

        "PPMXAlertCompleted": <span className="icon icon-alert-completed"></span>,
        "PPMXAlertCritical": <span className="icon icon-alert-critical"></span>,
        "PPMXAlertImportant": <span className="icon icon-alert-important"></span>,
        "PPMXAlertLowPriority": <span className="icon icon-alert-low-priority"></span>,
        "PPMXAlertMedium": <span className="icon icon-alert-medium"></span>,
        "PPMXLateKeyDate": <span className="icon icon-late-key-date"></span>,
        "PPMXLateTask": <span className="icon icon-late-task"></span>,
        'PPMXKeyDateCurrent': <span className="icon icon-keydates-key-date-current"></span>,
        'PPMXMilestoneCurrent': <span className="icon icon-keydates-milestone-current"></span>,
        'PPMXReleaseCurrent': <span className="icon icon-keydates-release-current"></span>,
        'PPMXProjectNormal': <span className="icon icon-project"></span>,
        'PPMXProjectLate': <span className="icon icon-project-late"></span>,
        'PPMXProjectUpcoming': <span className="icon icon-project-upcoming"></span>,
        'PPMXProjectSchedule': <span className="icon icon-project-schedule"></span>,
        'PPMXRequestUpdate': <span className="icon icon-request-update"></span>,
        'PPMXCalendarException': <span className="icon icon-calendar-exception"></span>,
        'PPMXLightningIcon': '',
        'PPMXQuickStart': '',
        'PPMXQuickStartContactUs': <span className="icon icon-quickstart-contact-us"></span>,
        'PPMXQuickStartCalendar': <span className="icon icon-quickstart-calendar"></span>,
        'PPMXQuickStartPortfolio': <span className="icon icon-quickstart-portfolio"></span>,
        'PPMXQuickStartProgram': <span className="icon icon-quickstart-program"></span>,
        'PPMXQuickStartChallenge': <span className="icon icon-quickstart-challenge"></span>,
        'PPMXQuickStartProject': <span className="icon icon-quickstart-project"></span>,
        'PPMXQuickStartRoadmap': <span className="icon icon-quickstart-roadmap"></span>,
        'PPMXQuickStartIdea': <span className="icon icon-quickstart-idea"></span>,
        'PPMXQuickStartMySpace': <span className="icon icon-quickstart-my-space"></span>,
        'PPMXQuickStartInvite': <span className="icon icon-quickstart-invite"></span>,
        'PPMXQuickStartHelpGuide': <span className="icon icon-quickstart-help-guide"></span>,
        'PPMXQuickStartViewProjects': <span className="icon icon-quickstart-view-projects"></span>,
        'PPMXQuickStartDemoData': <span className="icon icon-quickstart-demo-data"></span>,
        'PPMXQuickStartDocumentation': <span className="icon icon-quickstart-documentation"></span>,
        'PPMXQuickStartBlogs': <span className="icon icon-quickstart-blogs"></span>,
        'PPMXQuickStartVideos': <span className="icon icon-quickstart-videos"></span>,
        'PPMXQuickStartWebinars': <span className="icon icon-quickstart-webinars"></span>,
        'PPMXQuickStartWhatsNew': <span className="icon icon-quickstart-whats-new"></span>,
        'PPMXRoadmap': '',
        'PPMXSwitchUser': '',
        'PPMXReport': <span className="icon icon-report"></span>,
        'PPMXSigma': <span className="icon icon-sigma">Σ</span>,
        'PPMXRoadmapBarPlannedSmall': <span className="icon icon-roadmap-bar-planned-small"></span>,
        'PPMXRoadmapKeyDatePlannedSmall': <span className="icon icon-roadmap-keydate-planned-small"></span>,
        'PPMXRoadmapBarBacklogSmall': <span className="icon icon-roadmap-bar-backlog-small"></span>,
        'PPMXRoadmapKeyDateBacklogSmall': <span className="icon icon-roadmap-keydate-backlog-small"></span>,
        'PPMXRoadmapBarPlanned': <span className="icon icon-roadmap-bar-planned"></span>,
        'PPMXRoadmapKeyDatePlanned': <span className="icon icon-roadmap-keydate-planned"></span>,
        'PPMXRoadmapBarBacklog': <span className="icon icon-roadmap-bar-backlog"></span>,
        'PPMXRoadmapKeyDateBacklog': <span className="icon icon-roadmap-keydate-backlog"></span>,
        'PPMXDependencyDeliversTo': <span className="icon icon-dependency-delivers-to"></span>,
        'PPMXDependencyDependsOn': <span className="icon icon-dependency-depends-on"></span>,
        'PPMXDependencyDeliversToPlanned': <span className="icon icon-dependency-delivers-to-planned"></span>,
        'PPMXDependencyDeliversToBacklog': <span className="icon icon-dependency-delivers-to-backlog"></span>,
        'PPMXDependencyDependsOnPlanned': <span className="icon icon-dependency-depends-on-planned"></span>,
        'PPMXDependencyDependsOnBacklog': <span className="icon icon-dependency-depends-on-backlog"></span>,
        'PPMXVideoGuide': <span className="icon icon-video-guide"></span>,
        'PPMXUserManual': <span className="icon icon-user-manual"></span>,
        'PPMXEnterprisePlan': <span className="icon icon-enterprise-plan"></span>,
        'PPMXEnterpriseUltraPlan': <span className="icon icon-enterprise-ultra-plan"></span>,
        'PPMXWorkUnlimitedPlan': <span className="icon icon-work-unlimited-plan"></span>,
        'PPMXPayment': <span className="icon icon-payment"></span>,
        'PPMXPortfolioPlan': <span className="icon icon-portfolio-plan"></span>,
        'PPMXResourcePlan': <span className="icon icon-resource-plan"></span>,
        'PPMXProductManagementPlan': <span className="icon icon-product-management-plan"></span>,
        'PPMXIdeationPlan': <span className="icon icon-ideation-plan"></span>,
        'PPMXRenewalDate': <span className="icon icon-renewal-date"></span>,
        'PPMXTeamMembersSeats': <span className="icon icon-team-members-seats"></span>,
        'PPMXTrialRemaining': <span className="icon icon-trial-remaining"></span>,
        'PPMXUsersSeats': <span className="icon icon-users-seats"></span>,
        'PPMXFormCall': <span className="icon icon-form-call"></span>,
        'PPMXCorePlan': <span className='icon icon-core-plan'></span>,
        'PPMXKPI': <span className='icon icon-kpi'></span>,
        'PPMXKPISection': <span className='icon icon-kpi-section'></span>,
        'PPMXResultsNotFound': <span className='icon icon-results-not-found'></span>,
        'PPMXKPICost': <span className='icon icon-kpi-cost'></span>,
        'PPMXKPIDate': <span className='icon icon-kpi-date'></span>,
        'PPMXKPIDays': <span className='icon icon-kpi-days'></span>,
        'PPMXKPIDuration': <span className='icon icon-kpi-duration'></span>,
        'PPMXKPIFlag': <span className='icon icon-kpi-flag'></span>,
        'PPMXKPIHyperlink': <span className='icon icon-kpi-hyperlink'></span>,
        'PPMXKPINumber': <span className='icon icon-kpi-number'></span>,
        'PPMXKPIPercent': <span className='icon icon-kpi-percent'></span>,
        'PPMXKPIResource': <span className='icon icon-kpi-resource'></span>,
        'PPMXKPIResourceCompleted': <span className='icon icon-kpi-resource-completed'></span>,
        'PPMXKPISelect': <span className='icon icon-kpi-select'></span>,
        'PPMXKPISlider': <span className='icon icon-kpi-slider'></span>,
        'PPMXKPIEnumSlider': <span className='icon icon-kpi-enum-slider'></span>,
        'PPMXKPIText': <span className='icon icon-kpi-text'></span>,
        'PPMXKPIUser': <span className='icon icon-kpi-user'></span>,
        'PPMXKPIProgram': <span className='icon icon-kpi-program'></span>,
        'PPMXKPIPortfolio': <span className='icon icon-kpi-portfolio'></span>,
        'PPMXKPIIdea': <span className='icon icon-kpi-idea'></span>,
        'PPMXKPIStartDate': <span className='icon icon-kpi-startdate'></span>,
        'PPMXKPIFinishDate': <span className='icon icon-kpi-finishdate'></span>,
        'PPMXKPILastModified': <span className='icon icon-kpi-lastmodified'></span>,
        'PPMXKPIPriority': <span className='icon icon-kpi-priority'></span>,
        'PPMXKPIProgress': <span className='icon icon-kpi-progress'></span>,
        'PPMXKPIStage': <span className='icon icon-kpi-stage'></span>,
        'PPMXKPIRisk': <span className='icon icon-kpi-risk'></span>,
        'PPMXKPIBudget': <span className='icon icon-kpi-budget'></span>,
        'PPMXKPIBenefits': <span className='icon icon-kpi-benefits'></span>,
        'PPMXKPIEfforts': <span className='icon icon-kpi-efforts'></span>,
        'PPMXKPIDurationCompleted': <span className='icon icon-kpi-duration-completed'></span>,
        'PPMXKPIImpact': <span className='icon icon-kpi-impact'></span>,
        'PPMXKPIRevenue': <span className='icon icon-kpi-revenue'></span>,
        'PPMXKPIStatus': <span className='icon icon-kpi-status'></span>,
        'PPMXKPIMoney': <span className='icon icon-kpi-money'></span>,
        'PPMXKPIStoryPoints': <span className='icon icon-kpi-storypoints'></span>,
        'PPMXKPIEstimatedCharge': <span className='icon icon-kpi-estimated-charge'></span>,
        'PPMXFilterTask': <span className='icon icon-filter-task'></span>,

        'PPMXFieldDate': <span className='icon icon-field icon-field-date' />,
        'PPMXFieldDecimal': <span className='icon icon-field icon-field-decimal' />,
        'PPMXFieldFlag': <span className='icon icon-field icon-field-flag' />,
        'PPMXFieldFormatCost': <span className='icon icon-field icon-field-format-cost' />,
        'PPMXFieldFormatDays': <span className='icon icon-field icon-field-format-days' />,
        'PPMXFieldFormatDuration': <span className='icon icon-field icon-field-format-duration' />,
        'PPMXFieldFormatPercent': <span className='icon icon-field icon-field-format-percent' />,
        'PPMXFieldHyperlink': <span className='icon icon-field icon-field-hyperlink' />,
        'PPMXFieldImpact': <span className='icon icon-field icon-field-impact' />,
        'PPMXFieldInteger': <span className='icon icon-field icon-field-integer' />,
        'PPMXFieldResource': <span className='icon icon-field icon-field-resource' />,
        'PPMXFieldSelect': <span className='icon icon-field icon-field-select' />,
        'PPMXFieldSlider': <span className='icon icon-field icon-field-slider' />,
        'PPMXFieldText': <span className='icon icon-field icon-field-text' />,
        'PPMXFieldUser': <span className='icon icon-field icon-field-user' />,
        
        "PPMXFieldDragMark": <span className='icon icon-field-drag-mark' />,
        'PPMXColorPicker': <span className='icon icon-color-picker'></span>,
        'PPMXCalculateProgress': '\uE9DF',
        'PPMXColorNone': <span className='icon icon-color-none'></span>,
        'D365CustomerInsights': '\uF3C8',
        'PPMXLessonsLearned': <span className="icon icon-lessons-learned"></span>,
        'PPMXInProgress': '\uE9F3',

        'PPMXUpgradeTrial': <span className='icon icon-upgrade-trial' />,
        'PPMXUploadLogoPlaceholder': <span className='icon icon-upload-logo-placeholder' />,
        
        'PPMXAdministrativeTime': <span className="icon icon-administrative-time"></span>,
        'PPMXSmartSuggestion': <span className="icon icon-smart-suggestion"></span>,
        'PPMXPerson': <span className="icon icon-person"></span>,
        
        'PPMXMoneyHand': <span className="icon icon-money-hand"></span>,
        'O365Logo': <span className="icon icon-o365"></span>,
        'M365Logo': <span className="icon icon-m365"></span>,

        'PPMXO365Document': <span className="icon icon-o365-document"></span>,
        'PPMXO365CompletedOutlookEmail': <span className="icon icon-o365-completed-outlook-email"></span>,
        'PPMXO365SentOutlookEmail': <span className="icon icon-o365-sent-outlook-email"></span>,
        'PPMXO365OutlookCalendarEvent': <span className="icon icon-o365-outlook-calendar-event"></span>,
        'PPMXO365TentativeCalendarEvent': <span className="icon icon-o365-tentative-calendar-event"></span>,
        'PPMXO365ToDoTask': <span className="icon icon-o365-todo-task"></span>,

        'PPMXExternalTime': <span className="icon icon-external-time"></span>,
    }
});
registerIconAlias('Rocket', 'PPMXCompanyObjectives');
registerIconAlias('PPMXSectionKeyResults', 'Flag');
registerIcons({
    style: iconsStyle,
    fontFace: {
        fontFamily: `"PpmxAssets"`
    },
    icons: {
        // ppmx_font_sources/readme.txt
        'PPMXProjectInsights': '\uE900',
        'PPMXKeyResultsEmptyState': '\uE901',
        'PPMXStrategicPriorities': '\uE902',
        'PPMXIndentArrow': '\uE903',
        'PPMXOutdentArrow': '\uE904',
        'PPMXQuestionMarkInCircle': '\uE905',
        'PPMXMyAssignmentsActive': '\uE906',
        'PPMXAutoSchedule': '\uE907',
        'PPMXManualSchedule': '\uE908',
        'PPMXCollapseAll': '\uE909',
        'PPMXExpandAll': '\uE90a',
        'PPMXDeliverables': '\uE90b',
        'PPMXBenefits': '\uE90c',
        'PPMXInsights': '\uE90d',
        'PPMXDragHandle': '\uE90e',
        'PPMXPrioritizationFieldsGroup': '\uE90f',
    }
})

type InitialInfo = {
    isAuth: boolean,
    tenantNoAccess?: boolean,
    inviteNotFound?: boolean,
    usernameInUse?: boolean,
    tenantNotFound?: boolean,
    teamsAppId: string,
    initialState: ApplicationState,
    regions: RegionInfo[],
    invite?: { authProvider: AuthProvider }
}

const decodedUrl = decodeURIComponent(window.location.href);
if(window.location.href !== decodedUrl) {
    window.location.href = decodedUrl;
}

const region: string | null = getCookie("ppmx-region");
if (region) {
    setupFetchDefaults({
        withCredentials: true,
        headers: {
            "X-Timezone-Offset": new Date().getTimezoneOffset().toString(),
            "ppmx-region": region
        }
    });

    const targetTenantUrl = window.location.pathname.split("/").find(_ => _.startsWith("@"))
        ?? new URLSearchParams(window.location.search).get("redirectUri")?.split("/").find(_ => _.startsWith("@"));

    get<InitialInfo>(
        `${(targetTenantUrl && targetTenantUrl.startsWith("@") ? `${targetTenantUrl}/` : "")}api/tenant/configuration`,
        { path: window.location.pathname, search: window.location.search })
        .then(renderApp)
        .catch(_ => {
            console.error("Unable to get tenant configuration", _);
        })
} else {
    renderError("Internal error");
    console.error("Unable to get tenant region");
}

function renderError(title: string) {
    ReactDOM.render(
        <ErrorPage title={title} />,
        document.getElementById('react-app'));
}

function renderApp(info: InitialInfo) {
    //to handle oauth failure on remote servers - for popups mode only
    if (window.opener && window.opener !== window && window.location.search && window.location.search.toLowerCase().indexOf("remotefail=true") !== -1) {
        window.close();
    }

    let url = `/${info.initialState.tenant.region}`;
    if (info.isAuth && info.initialState.tenant.url) {
        url += `/@${info.initialState.tenant.url}`
    }
    const history = createBrowserHistory({ basename: url });
    const store = configureStore(history, info.initialState);

    store.dispatch({ type: 'SET_THEME', themeType: info.initialState.tenant.styleSettings?.themeType ?? ThemeType.Dark });
    store.dispatch({ type: 'TEAMS_APP_INIT', id: info.teamsAppId });

    document.getElementsByTagName('base')[0].setAttribute('href', url);
    baseUrl.value = url;

    if (info.initialState.tenant?.id) {
        appendFetchHeaderDefaults({ "tenantId": info.initialState.tenant.id });
    }

    if (info.initialState.financials?.currency) {
        currency.code = info.initialState.financials.currency;
    }

    if (info.initialState.tenant?.globalSettings) {
        cultureInfo.name = info.initialState.tenant?.globalSettings.cultureName;
    }    

    //configure segment
    const analyticsKey = info.initialState.analytics?.segmentWriteKey;
    if (analyticsKey) {
        analytics.load(analyticsKey);
        history.listen(() => analytics.page());
        if (info.initialState.tenant.id && info.initialState.user?.id) {
            analytics.identifyAndGroup(info.initialState.user, info.initialState.tenant);
        }
    }

    if (!info.isAuth) {
        renderComponent(
            <LoginApp 
                disableSignUp={info.initialState.enviroment.disabledSignUp || !!info.invite}
                regions={info.regions}
                region={info.initialState.tenant.region} 
                authProviders={info.initialState.enviroment.authProviders}
                inviteAuthProvider={info.invite?.authProvider}
                allowedAuthProviders={info.initialState.tenant.security?.allowedAuthProviders} />);
    } else if (info.tenantNotFound) {
        renderComponent(
            <ErrorApp
                title="Tenant not found"
                description="Please, check the requested Url."
            />);
    } else if (info.tenantNoAccess) {
        renderComponent(
            <ErrorApp
                title="No access"
                description="Please verify an account you are trying to log in or contact PPM Express administrator to request access."
                allowChangeAccount
            />);
    } else if (info.inviteNotFound) {
        renderComponent(
            <ErrorApp
                title="Invitation is not found"
                description="Please, contact your PPM Express administrator to obtain new invitation."
            />);
    } else if (info.usernameInUse) {
        renderComponent(
            <ErrorApp
                title="Username already in use"
                description="Please, contact your PPM Express administrator to obtain more details."
            />);
    } else {
        if (!window.location.pathname.startsWith(`/${info.initialState.tenant.region}/@${info.initialState.tenant.url}`)) {
            let path = window.location.pathname + window.location.search;
            if (path.startsWith(`/${info.initialState.tenant.region}`)) {
                path = path.substring(`/${info.initialState.tenant.region}`.length);
            }
            if (path.startsWith(`/@${info.initialState.tenant.url}`)) {
                path = path.substring(`/@${info.initialState.tenant.url}`.length);
            }
            if (path.startsWith(`/`)) {
                path = path.substring(`/`.length);
            }

            history.replace(`/${path}`);
        }
        
        store.dispatch({ type: 'SIGNALR_CREATE_CONNECTION' });

        renderComponent(<App history={history} routes={routes} />, false);
    }
    
    function renderComponent(component: JSX.Element, withRouter: boolean = true) {
        ReactDOM.render(
            <Provider store={store}>
                {withRouter
                    ? <ConnectedRouter history={history}>{component}</ConnectedRouter>
                    : component}
            </Provider>,
            document.getElementById('react-app'));
    }
}

function getCookie(name: string) {
    const v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return v ? v[2] : null;
}
