import React, { useState } from 'react'
import { CopyBillableAndCostType, DefaultCostType, GetCostType, IsEnterpriseTimeTracker, TimeTrackingEntryAttr, TimeTrackingProjectDetails } from "../../store/TimeTrackingStore";
import TimeTrackingImportProjectsPanel from './import/TimeTrackingImportProjectsPanel';
import EditTimeTrackingEntry from './EditTimeTrackingEntry';
import { ITimeframe, ServerEntityType } from '../../entities/common';
import { GetNewEntryDate, LoadTimeTrackingProjectDetails, TimeType } from './common';
import { ApplicationState } from '../../store';
import { connect } from 'react-redux';
import { TimeTrackingGlobalSettings } from '../../store/Tenant';

type OwnProps = {
    onComplete: (id: string | null, attributes: TimeTrackingEntryAttr) => void;
    onDismiss: () => void;
    timeFrame: ITimeframe;
    project?: TimeTrackingProjectDetails;
};

type StateProps = {
    timeTrackingSettings: TimeTrackingGlobalSettings;
    isEnterpriseTimeTracker: boolean;
};

type Props = OwnProps & StateProps;

function EditCustomTimeTrackingEntry(props: Props) {

    const { timeTrackingSettings, isEnterpriseTimeTracker, project } = props;

    const [entry, setEntry] = useState<Partial<TimeTrackingEntryAttr>>(() => {
        const attributes: Partial<TimeTrackingEntryAttr> = {
            Date: GetNewEntryDate(props.timeFrame),
            Project: project && {
                id: project.id,
                name: project.name
            }
        };

        if (isEnterpriseTimeTracker && project) {
            _fillupBillableAndCostType(attributes, project, timeTrackingSettings);
        }

        return attributes;
    });

    const canChangeProject = !props.project;

    return (
        <>
            {entry.Project
                ? <EditTimeTrackingEntry entry={{
                    attributes: entry
                }}
                    timeType={TimeType.CustomEvent}
                    onComplete={props.onComplete}
                    onDismiss={props.onDismiss}
                    onChangeProject={canChangeProject
                        ? () => {
                            setEntry({
                                ...entry,
                                Project: undefined
                            });
                        }
                        : undefined} />
                : <TimeTrackingImportProjectsPanel
                    timeFrame={props.timeFrame}
                    onDismiss={props.onDismiss}
                    onImport={(entityDescriptor) => {

                        entry.Project = { id: entityDescriptor.id, name: entityDescriptor.name! };

                        if (isEnterpriseTimeTracker) {
                            LoadTimeTrackingProjectDetails([entityDescriptor.id])
                                .then(projects => {
                                    _fillupBillableAndCostType(entry, projects[0], timeTrackingSettings);
                                    setEntry({ ...entry });
                                });
                        }
                        else {
                            setEntry({ ...entry });
                        }
                    }} />
            }
        </>
    );
}

const _fillupBillableAndCostType = (
    entry: Partial<TimeTrackingEntryAttr>,
    project: TimeTrackingProjectDetails,
    timeTrackingSettings: TimeTrackingGlobalSettings) => {
    
    if (timeTrackingSettings.billableSource === ServerEntityType.Project) {
        CopyBillableAndCostType(entry, project, true);

        if (timeTrackingSettings.costTypeSource !== timeTrackingSettings.billableSource) {
            entry.CostType = DefaultCostType;
        }
    }
    else if (timeTrackingSettings.costTypeSource === ServerEntityType.Project) {
        entry.CostType = GetCostType(project);
    }
};

function mapStateToProps(state: ApplicationState, ownProps: OwnProps): StateProps {
    return {
        timeTrackingSettings: state.tenant.timeTracking.globalSettings,
        isEnterpriseTimeTracker: IsEnterpriseTimeTracker(state.tenant.subscription)
    };
}

export default connect(mapStateToProps)(EditCustomTimeTrackingEntry);