import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { ApplicationState } from '../../store';
import * as ProjectsListStore from '../../store/ProjectsListStore';
import * as PortfoliosListStore from '../../store/PortfoliosListStore';
import * as ProgramsListStore from '../../store/ProgramsListStore';
import * as Metadata from "../../entities/Metadata";
import { IArchivingResult, IDeletionResult } from '../../store/services/storeHelper';
import {
    PrimaryButton, DefaultButton, IContextualMenuItem, IDialogContentProps, MessageBar, MessageBarType, Selection
} from 'office-ui-fabric-react';
import { Dictionary, EntityType, ppmxTaskConnectionId, SourceInfo } from "../../entities/common";
import { Reporting, ReportNav, ReportsNav } from "../utils/reporting";
import EntitiesCardList from '../common/EntitiesCardList';
import ProjectCard, { ProjectCardState } from '../views/card/ProjectCard';
import { getActiveViewType, IEntitiesScreenProps } from "../common/EntitiesScreen";
import { IEntitiesScreenView, IHeaderProps } from "../common/EntitiesScreenHeader";
import EntitiesScreenBuilder from "../common/EntitiesScreen";
import EmptyEntitiesScreen from "../common/EmptyEntitiesScreen";
import ListSubView, { getPngExportConfigSelectors } from "../views/list/ListSubView";
import EditListSubView from "../views/list/EditListSubView";
import RemoveDialog from '../common/RemoveDialog';
import ImportPanel, { supported } from "../import/project/ImportPanel";
import { SortService } from "../../services/SortService";
import { toDictionaryById, notUndefined, toDictionaryByName } from "../utils/common";
import Spinner from "../common/Spinner";
import { Validator } from "../../validation";
import DatePickerInput from '../common/inputs/DatePickerInput';
import ProjectCreation from './ProjectCreation';
import { IBulkEditInput } from '../common/EntitiesBulkEdit';
import { ProjectFilterValue, FilterHelper, LocationState, ActiveFilter } from '../../store/project/filters';
import { UserState } from "../../store/User";
import { contains, CommonOperations, canCreate, canUpdate, LicenseType } from "../../store/permissions";
import * as Notifications from "../../store/NotificationsStore";
import * as LayoutsStore from "../../store/layouts";
import * as FiltersStore from "../../store/filters";
import * as ViewsStore from "../../store/views";
import { buildEntityColumn, IDetailsProps, IListProps, TInlineEditProps, TOnInlineEditComplete } from '../common/extensibleEntity/EntityDetailsList';
import { ITimelineProps } from '../common/extensibleEntity/EntityTimelineList';
import { buildTimelineItem } from './timeline';
import { Visibility } from '../common/timeline/TimelineSegment';
import { StatusCalculationTypes, Integrations, Subscription, PPMFeatures, TenantState } from '../../store/Tenant';
import ProjectsFilter from './ProjectsFilter';
import { LayoutService } from '../utils/LayoutService';
import SharePanel from '../common/SharePanel';
import MenuWithReports from '../reporting/MenuWithReports';
import { getStatusesInputs } from '../portfolio/PortfoliosList';
import * as CommonTimeline from '../portfolio/commonTimeline';
import { Sorter } from '../utils/HierarchyManager';
import { nameof, namesof } from '../../store/services/metadataService';
import PngExporter, { PngExportConfig } from '../common/PngExporter';
import { PngExportControlDetails } from '../../store/PngExporterStore';
import { getPortfolios } from '../../store/project/utils';
import { SourceType, SourceType_ } from '../../store/ExternalEpmConnectStore';
import { urlParamsBuilder } from '../../entities/Subentities';
import { IInputProps } from '../common/interfaces/IInputProps';
import { buildExportToCsvMenuItem } from '../common/headerMenuItemBuilders';
import AIDigestPanel from './AIDigestPanel';
import { History } from 'history';
import { MenuTitleBuilder } from '../MenuTitleBuilder';
import ApplyLayoutConfirmationDialog from '../common/ApplyLayoutConfirmationDialog';
import RowMenuWithExtensions from '../common/extensibleEntity/RowMenuWithExtensions';
import { buildIWithBenefitsCustomRenders, buildIWithBenefitsValidators, rendersBuilder, validators } from '../field/Fields';
import { buildReadOnlyStatusFieldsNames } from '../common/sectionsControl/uiControls/fieldsArea/common';
import { ViewService } from '../../services/ViewService';
import { IEntityMapHelper, useEntityMapHelper } from '../utils/IEntityMapHelper';

type ActionProps = {
    projectsListStore: typeof ProjectsListStore.actionCreators;
    programsListStore: typeof ProgramsListStore.actionCreators;
    portfoliosListStore: typeof PortfoliosListStore.actionCreators;
    notificationsActions: typeof Notifications.actionCreators;
    filtersActions: ReturnType<typeof FiltersStore.actionCreators.forEntity>;
    viewsActions: ReturnType<typeof ViewsStore.actionCreators.forEntity>;
};
type StateProps = {
    portfolios: PortfoliosListStore.Portfolio[];
    projects: ProjectsListStore.ProjectInfo[];
    projectsMap: IEntityMapHelper<ProjectsListStore.ProjectInfo>;
    programs: ProgramsListStore.Program[];
    isImportDialogOpen: boolean;
    isLoading: boolean;
    isListLoading: boolean;
    isListUpdating: boolean;
    layouts: LayoutsStore.LayoutsState;
    projectFields: Metadata.Field[];
    projectFakeFields: Metadata.Field[];
    keyDateFields: Metadata.Field[];
    activeFilter?: Metadata.IFilter<ProjectFilterValue>;
    autoFilter: Metadata.IFilter<Metadata.BaseFilterValue>;
    preFilterId?: string;
    projectReports: ReportNav[];
    projectsReports: ReportsNav;
    views?: ViewsStore.IViewsState;
    deletionResult: IDeletionResult[] | undefined;
    archivingResult: IArchivingResult[] | undefined;
    user: UserState;
    tenant: TenantState;
    statusCalculation: StatusCalculationTypes;
    integrations: Integrations;
    pngExportDetails?: PngExportControlDetails;
    hasArchiveProjects: boolean;
    hasPortfolioManagement: boolean;
    subscription: Subscription;
};
export type Props = StateProps & ActionProps & RouteComponentProps<{}>;

type State = {
    isCreate: boolean;
    share?: ProjectsListStore.ProjectInfo;
    isImport: boolean;
    projectsToRemove: ProjectsListStore.ProjectInfo[];
    projectsToArchive: ProjectsListStore.ProjectInfo[];
    layoutToApply?: Metadata.Layout;
    canEdit: boolean;
    canManageConfiguration: boolean;
    selectedItemIds: string[];

    isListViewEdit: boolean;
    isTimelineViewEdit: boolean;
    AIDigestProject: ProjectsListStore.ProjectInfo | undefined;

    filtered: string[];
    entityFilterHelper: Metadata.IEntityFilterHelper<ProjectsListStore.ProjectInfo>;
};

const EntitiesScreen = EntitiesScreenBuilder<ProjectsListStore.ProjectInfo>();

export const getArchiveDialogContent = (projects: ProjectsListStore.ProjectInfo[]) => {
    const toArchive = projects.filter(_ => _.isEditable);

    const text = toArchive.length === 1 ? `project "${toArchive[0].attributes.Name}"` : `selected projects (${toArchive.length} items)`;
    return {
        title: `Archive project${toArchive.length === 1 ? "" : "s"}`,
        subText: `Are you sure you want to archive ${text}? 

                Archiving projects removes the projects and their items from all views. 
                Archived projects will be available in read-only mode under the ‘Settings’ (gear button) -> ‘Archived Projects’ option. 
                Only PPM Express Administrators and Project Managers (only the projects they manage) can see the archived projects on this page.`
    }
}

export const buildProjectPpmInsightsMenuItemIfEnabled = (tenant: TenantState, user: UserState, entity: { id: string; isArchived?: boolean }, history: History) =>
    !entity.isArchived && Subscription.contains(tenant.subscription, PPMFeatures.Insights)
        ? {
            key: 'ppm-insights',
            iconProps: { iconName: 'PPMXProjectInsights' },
            name: 'PPM Insights',
            disabled: !contains(user.permissions.common, CommonOperations.InsightsView),
            onClick: () => history.push("/insights", new ActiveFilter("Custom").withProject(entity.id).buildLocationState(null)),
        } : undefined;

export const buildAiProjectDigestMenuItemIfEnabled = (tenant: TenantState, user: UserState, isEntityArchived: boolean | undefined, isEditable: boolean, onClick: () => void) =>
    Subscription.contains(tenant.subscription, PPMFeatures.AiInsights) && !isEntityArchived
        ? {
            key: 'ai-project-digest',
            iconProps: { iconName: 'D365CustomerInsights' },
            name: 'AI Project Digest',
            title: !tenant.aiInsights?.aiEnabled && user.license !== LicenseType.Viewer
                ? "To use AI features, PPM Insights AI should be enabled in the tenant by PPM Express Administrator"
                : undefined,
            disabled: !isEditable || !canUpdate(user.permissions.project) || !tenant.aiInsights?.aiEnabled,
            onClick,
        } : undefined

class ProjectsList extends React.Component<Props, State> {
    private _selection: Selection;

    constructor(props: Props) {
        super(props);

        this.state = {
            isCreate: false,
            isImport: false,
            canEdit: this._canEdit(props),
            canManageConfiguration: contains(props.user.permissions.common, CommonOperations.ConfigurationManage),
            selectedItemIds: [],
            isListViewEdit: false,
            isTimelineViewEdit: false,
            AIDigestProject: undefined,
            projectsToRemove: [],
            projectsToArchive: [],
            filtered: [],
            entityFilterHelper: new FilterHelper({
                portfolios: props.portfolios,
                programs: props.programs,
                projects: props.projects,
                projectFields: props.projectFields,
                layouts: props.layouts.allIds.map(_ => props.layouts.byId[_])
            })
        };

        this._selection = new Selection({
            onSelectionChanged: () => {
                this.setState({ selectedItemIds: (this._selection.getSelection() as ProjectsListStore.ProjectInfo[]).map(_ => _.id) });
            }
        });
    }

    componentWillMount() {
        if (this.props.hasPortfolioManagement) {
            this.props.portfoliosListStore.requestPortfolios();
            this.props.programsListStore.requestPrograms();
        }

        this.props.projectsListStore.requestProjects();
    }

    componentWillReceiveProps(nextProps: Props) {
        if (this.props.portfolios !== nextProps.portfolios) {
            this.setState({ canEdit: this._canEdit(nextProps) });
        }

        if (this.props.portfolios !== nextProps.portfolios
            || this.props.projects !== nextProps.projects
            || this.props.projectFields !== nextProps.projectFields
            || this.props.programs !== nextProps.programs) {
            const entityFilterHelper = new FilterHelper({
                portfolios: nextProps.portfolios,
                programs: nextProps.programs,
                projects: nextProps.projects,
                projectFields: nextProps.projectFields,
                layouts: nextProps.layouts.allIds.map(_ => nextProps.layouts.byId[_])
            });
            this.setState({ entityFilterHelper })
        }
    }

    private _canEdit(props: Props) {
        return canCreate(props.user.permissions.project) || canUpdate(props.user.permissions.project) || props.projects.some(_ => _.isEditable);
    }

    private _viewChanged = (view: IEntitiesScreenView<ProjectsListStore.ProjectInfo>) => {
        this.setState({ selectedItemIds: [] });
        this.props.viewsActions.setActiveView(view.url);
    }

    private _clearPreFilter = () => {
        const state: LocationState = { filter: this.props.autoFilter };
        const query = new URLSearchParams(this.props.history.location.search);
        query.set('filter', this.props.autoFilter.id);
        query.delete('prefilter');
        this.props.history.replace({ search: query.toString(), state: state });
    }

    public render() {
        if (!this.props.isImportDialogOpen && (this.props.isLoading || this.props.isListLoading)) {
            return <Spinner />;
        }

        const { deletionResult, archivingResult, pngExportDetails } = this.props;
        const { projectsToRemove, projectsToArchive, AIDigestProject } = this.state;
        const integrations = this.props.integrations.getAvailable(supported);
        return <>
            {this.props.projects.length === 0
                ? <EmptyEntitiesScreen
                    className="project"
                    title="projects"
                    description="Create online versions of your projects for visibility, status management, and progress tracking. 
 Take your project management process to the next level.">
                    <PrimaryButton disabled={!canCreate(this.props.user.permissions.project)} text="Create Project" onClick={() => this.setState({ isCreate: true })} />
                    {!!integrations.length && <DefaultButton
                        disabled={!canCreate(this.props.user.permissions.project)}
                        text="Import Projects"
                        onClick={() => this.setState({ isImport: true })} />}
                </EmptyEntitiesScreen>
                : <PngExporter details={pngExportDetails} getConfig={this._getPngExportConfig}>
                    <EntitiesScreen {...this._buildEntitiesScreenProps()} />
                </PngExporter>}
            {this.state.share && <SharePanel
                key="share-panel"
                entity={this.state.share}
                entityType={EntityType.Project}
                layouts={this.props.layouts}
                onDismiss={() => this.setState({ share: undefined })} />}
            {this.state.isCreate && <ProjectCreation onDismiss={() => this.setState({ isCreate: false })} openOnComplete={true}
                showConfigureConnectionsOption={Subscription.hasSyncableIntegration(this.props.subscription)} />}
            {this.state.isImport && <ImportPanel integrations={integrations} onDismiss={() => this.setState({ isImport: false })} />}
            {!!projectsToRemove.length && <RemoveDialog
                onClose={() => this.setState({ projectsToRemove: [] })}
                onComplete={() => this.props.projectsListStore.removeProjects(projectsToRemove.filter(_ => _.isEditable).map(_ => _.id))}
                dialogContentProps={this._getRemoveDialogContent(projectsToRemove)}
                confirmButtonProps={{ text: "Delete", disabled: projectsToRemove.filter(_ => _.isEditable).length === 0 }}>
                {projectsToRemove.some(_ => !_.isEditable) && <MessageBar messageBarType={MessageBarType.warning} isMultiline={true}>
                    You don't have permissions to delete the following projects:
                    <ul>
                        {projectsToRemove.filter(_ => !_.isEditable).map(_ => <li key={_.id}>{_.attributes.Name}</li>)}
                    </ul>
                </MessageBar>}
            </RemoveDialog>}
            {deletionResult && <RemoveDialog
                onClose={() => this.props.projectsListStore.dismissDeletionResult()}
                confirmButtonProps={{ text: "Got it" }}
                dialogContentProps={this._getDeletionResultDialogContent(deletionResult)}>
                {deletionResult.length > 1 && deletionResult.some(_ => !_.isDeleted) && <MessageBar messageBarType={MessageBarType.warning} isMultiline={true}>
                    Failed to delete the following projects:
                    <ul>
                        {deletionResult.filter(_ => !_.isDeleted).map(_ => <li key={_.id}>{_.name}</li>)}
                    </ul>
                    Please check if you have necessary permissions.
                </MessageBar>}
            </RemoveDialog>}
            {!!projectsToArchive.length && <RemoveDialog
                onClose={() => this.setState({ projectsToArchive: [] })}
                onComplete={() => this.props.projectsListStore.archiveProjects(projectsToArchive.filter(_ => _.isEditable).map(_ => _.id))}
                dialogContentProps={getArchiveDialogContent(projectsToArchive)}
                confirmButtonProps={{ text: "Archive", disabled: projectsToArchive.filter(_ => _.isEditable).length === 0 }}>
                <MessageBar messageBarType={MessageBarType.warning}>Restoring an archived project will not be feasible, but it will be possible to clone it.</MessageBar>
                {projectsToArchive.some(_ => !_.isEditable) && <p><MessageBar messageBarType={MessageBarType.warning} isMultiline={true}>
                    You don't have permissions to archive the following projects:
                    <ul>
                        {projectsToArchive.filter(_ => !_.isEditable).map(_ => <li key={_.id}>{_.attributes.Name}</li>)}
                    </ul>
                </MessageBar></p>}
            </RemoveDialog>}
            {archivingResult && <RemoveDialog
                onClose={() => this.props.projectsListStore.dismissArchivingResult()}
                confirmButtonProps={{ text: "Got it" }}
                dialogContentProps={this._getArchiveResultDialogContent(archivingResult)}>
                {archivingResult.length > 1 && archivingResult.some(_ => !_.isArchived) && <MessageBar messageBarType={MessageBarType.warning} isMultiline={true}>
                    Failed to archive the following projects:
                    <ul>
                        {archivingResult.filter(_ => !_.isArchived).map(_ => <li key={_.id}>{_.name}: {_.message}</li>)}
                    </ul>
                </MessageBar>}
            </RemoveDialog>}
            {AIDigestProject && <AIDigestPanel
                project={AIDigestProject}
                onDismiss={() => this.setState({ AIDigestProject: undefined })} />}
            {this.state.layoutToApply && <ApplyLayoutConfirmationDialog
                onConfirm={() => {
                    this.props.projectsListStore.applyLayoutMany(this.state.selectedItemIds, this.state.layoutToApply!.id);
                    this.props.notificationsActions.pushNotification({
                        message: `Layout '${this.state.layoutToApply!.name}' applied`,
                        type: Notifications.NotificationType.Info
                    });
                }}
                onDismiss={() => this.setState({ layoutToApply: undefined })}
                entityType={EntityType.Project}
                layoutName={this.state.layoutToApply!.name}
                count={this.state.selectedItemIds.length}
            />}
        </>;
    }

    private _buildEntitiesScreenProps(): IEntitiesScreenProps<ProjectsListStore.ProjectInfo> {
        const { filtered } = this.state;
        return {
            title: "projects",
            canManageConfiguration: this.state.canManageConfiguration,
            clearPreFilter: this._clearPreFilter,
            fields: this.props.projectFields,
            fakeFields: this.props.projectFakeFields,
            bulkEditAttributesCustomRender: this.bulkEditAttributesCustomRenderBuilder(),
            defaultBulkEditColumns: ProjectsListStore.DEFAULT_BULK_EDIT_COLUMNS,
            entities: this.props.projectsMap.getItems(filtered),
            entitiesIsUpdating: this.props.layouts.isApplyingLayout,
            activeViewType: this.props.views!.activeViewType,
            viewChanged: this._viewChanged,
            actions: {
                bulkComponent: {
                    bulkUpdate: this.gridBulkUpdate
                },
                importFromFile: this.props.projectsListStore.importEntitiesFromFile
            },
            views: this._getViews(),
            filter: {
                activeFilter: this.props.activeFilter,
                autoFilterId: this.props.autoFilter.id,
                getAttributeValue: this.getAttributeValue,
                onFilterRender: (isFilterPanelOpen, toggleFilterPanel) => (
                    <ProjectsFilter
                        isFilterPanelOpen={isFilterPanelOpen}
                        toggleFilterPanel={toggleFilterPanel}
                        activeFilterId={this.props.activeFilter?.id}
                        fields={this.props.projectFields}
                        projects={this.props.projects}
                        programs={this.props.programs}
                        portfolios={this.props.portfolios}
                        activePrefilterId={this.props.preFilterId}
                        onFilterChange={(p, filterId, prefilterId) => {
                            this.setState({ filtered: p.map(_ => _.id) });
                            // don't change != to !== 
                            // preFilterId can be null is some cases
                            if (filterId !== this.props.activeFilter?.id || prefilterId != this.props.preFilterId) {
                                this.props.filtersActions.setActiveFilter(filterId, prefilterId);
                            }
                        }}
                    />
                )
            },
            headerProps: this.getHeaderProps(),
            getBulkEditEntities: this.getBulkEditEntities,
            router: {
                history: this.props.history,
                match: this.props.match,
                location: this.props.location
            },
            baseUrl: "/projects",
            canEdit: this.state.canEdit,
            selectedItemIds: this.state.selectedItemIds,
            getPngExportConfig: this._getPngExportConfig
        };
    }

    private _getRemoveDialogContent(projects: ProjectsListStore.ProjectInfo[]) {
        const toRemove = projects.filter(_ => _.isEditable);
        if (toRemove.length === 1) {
            return {
                title: "Delete project",
                subText: `Are you sure you want to delete project "${toRemove[0].attributes.Name}"?`
            }
        }

        return {
            title: "Delete projects",
            subText: toRemove.length
                ? `Are you sure you want to delete selected projects (${toRemove.length} items)?`
                : undefined
        }
    }

    private _getDeletionResultDialogContent(deletionResult: IDeletionResult[]): IDialogContentProps {
        if (deletionResult.length === 1) {
            return deletionResult[0].isDeleted
                ? {
                    title: "Project deletion is complete",
                    subText: `Project "${deletionResult[0].name}" was deleted successfully.`
                }
                : {
                    title: "Unable to delete project. Please check if you have necessary permissions",
                    subText: deletionResult[0].message
                }
        }

        const deleted = deletionResult.filter(_ => _.isDeleted);
        return {
            title: "Projects deletion is complete",
            subText: deleted.length
                ? `Selected projects (${deleted.length} items) were deleted successfully.`
                : undefined
        };
    }

    private _getArchiveResultDialogContent(archiveResult: IArchivingResult[]): IDialogContentProps {
        if (archiveResult.length === 1) {
            return archiveResult[0].isArchived
                ? {
                    title: "Project archiving is complete",
                    subText:
                        `Project "${archiveResult[0].name}" was archived. You can review a project under the ‘Settings’ (gear button) -> ‘Archived Projects’ option.
                         Only PPM Express Administrators and Project Managers (only the projects they manage) can see the archived projects on this page.`
                }
                : {
                    title: "Unable to archive project.",
                    subText: archiveResult[0].message
                }
        }

        const archived = archiveResult.filter(_ => _.isArchived);
        return {
            title: "Projects archiving is complete",
            subText: archived.length
                ? `Selected projects (${archived.length} items) were archived. You can review a project under the ‘Settings’ (gear button) -> ‘Archived Projects’ option.
                   Only PPM Express Administrators and Project Managers (only the projects they manage) can see the archived projects on this page.`
                : undefined
        };
    }

    private getHeaderProps = (): IHeaderProps => {
        const { projectsReports, history } = this.props;

        const reportItems: IContextualMenuItem[] = projectsReports.packs.map(report => (
            {
                key: report.id,
                name: report.title,
                iconProps: { iconName: "FileSymlink" },
                onClick: () => { Reporting.openReport(history, report) }
            }));

        const projectReportItems: IContextualMenuItem[] = projectsReports.subPacks.map(report => (
            {
                key: report.id,
                name: report.title,
                iconProps: { iconName: "FileSymlink" },
                onClick: () => {
                    Reporting.openProjectsReport(history, report, this.getBulkEditEntities());
                }
            }));

        return {
            entityType: EntityType.Project,
            createEntityTypes: [EntityType.Portfolio, EntityType.Program, EntityType.Project, EntityType.Roadmap, EntityType.Resource, EntityType.PrivateProject],
            importEntityTypes: [EntityType.Project, EntityType.Resource],
            reportProps: {
                reportsButtonAdditionalOptions: Reporting.buildReportsList(projectReportItems, reportItems)
            },
            allowExportToFile: true,
            allowImportFromFile: this.props.user.license === LicenseType.Regular
        };
    }

    private getBulkEditEntities = (): ProjectsListStore.ProjectInfo[] => {
        if (this.state.selectedItemIds.length > 0) {
            return this.props.projectsMap.getItems(this.state.selectedItemIds);
        }
        return this.props.preFilterId ? this.props.projectsMap.getItems(this.state.filtered) : this.props.projects;
    }

    private getAttributeValue = (attrType: keyof ProjectFilterValue, value: any): string[] => {
        return this.state.entityFilterHelper.helpersMap[attrType].getAttributeValues(value);
    }

    private _renderMenu = (entity: ProjectsListStore.ProjectInfo) => {
        let commands: IContextualMenuItem[] = [];

        const openScheduleItem = this._getOpenScheduleItem(entity);

        if (entity.isEditable) {
            commands = commands.concat([
                {
                    key: 'share',
                    name: 'Share',
                    iconProps: { iconName: 'Share' },
                    onClick: () => this.setState({ share: entity })
                },
                buildProjectPpmInsightsMenuItemIfEnabled(this.props.tenant, this.props.user, entity, this.props.history),
                buildAiProjectDigestMenuItemIfEnabled(this.props.tenant, this.props.user, entity.isArchived, entity.isEditable, () => this.setState({ AIDigestProject: entity })),
                openScheduleItem,
                {
                    key: 'edit',
                    name: 'Edit',
                    iconProps: { iconName: "Edit" },
                    onClick: () => this.props.history.push(`/project/${entity.id}`)
                },
                this.props.hasArchiveProjects ? {
                    key: 'archive',
                    name: 'Archive',
                    iconProps: { iconName: "Archive" },
                    disabled: this.props.isLoading || entity.isPrivate,
                    title: entity.isPrivate ? "Private projects cannot be archived." : undefined,
                    onClick: () => this.setState({ projectsToArchive: [entity] })
                } : undefined,
                {
                    key: 'delete',
                    name: 'Delete',
                    iconProps: { iconName: "Delete", style: { color: 'red' } },
                    disabled: this.props.isLoading,
                    style: { color: (this.props.isLoading ? 'initial' : 'red'), backgroundColor: (this.props.isLoading ? 'lightgrey' : 'unset') },
                    onClick: () => this.setState({ projectsToRemove: [entity] })
                }
            ].filter(notUndefined));
        }
        else {
            commands.push(openScheduleItem);
        }

        return entity.isPrivate
            ? <RowMenuWithExtensions item={entity} commands={commands} entityType={EntityType.Project} renderOnlyMenu />
            : <MenuWithReports
                commands={commands}
                item={entity}
                entityType={EntityType.Project}
                reports={this.props.projectReports}
                onClick={_ => Reporting.openProjectReport(this.props.history, _, entity)} />;
    }

    private _getOpenScheduleItem(project: ProjectsListStore.ProjectInfo): IContextualMenuItem {
        const schedulableOrNotCollaborativeSourceInfos = project.sourceInfos
            .filter(_ => !SourceType_.collaborative.includes(_.type) && SourceInfo.isSyncable(_));

        const menuItems: IContextualMenuItem[] = [
            this._buildScheduleMenuItem(SourceType.Ppmx, ppmxTaskConnectionId, project.id),
            ...schedulableOrNotCollaborativeSourceInfos.map(_ => this._buildScheduleMenuItem(_.type, _.connectionId, project.id))
        ];

        return {
            key: 'open-schedule',
            name: 'Open Tasks',
            subMenuProps: { items: menuItems },
            iconProps: { iconName: 'PPMXProjectSchedule' },
        }
    }

    private _buildScheduleMenuItem(sourceType: SourceType, connectionId: string, projectId: string): IContextualMenuItem {
        return {
            key: `${projectId}-${SourceType_.getName(sourceType)}`,
            name: SourceType_.getName(sourceType),
            iconProps: { iconName: SourceType_.getIconName(sourceType), className: 'open-schedule-menu-icon' },
            onClick: () => this._getTasksUrl(connectionId, projectId)
        }
    }

    private _getTasksUrl(connectionId: string, projectId: string) {
        const { location } = this.props;
        const query = new URLSearchParams(location.search);
        query.set(urlParamsBuilder.connectionId, connectionId);
        this.props.history.push(`/project/${projectId}/tasks?${query.toString()}`);
    }

    private getCardView(): IEntitiesScreenView<ProjectsListStore.ProjectInfo> {
        const { card } = this.props.views!;
        const fieldsMap = Metadata.toMap(this.props.projectFields);
        return {
            icon: 'PPMXCardView',
            url: Metadata.ViewTypes.card,
            subViews: card.subViews,
            sortBy: card.sortBy,
            onSortChange: this.props.viewsActions.changeCardViewSort,
            activeSubViewId: card.activeSubViewId,
            onSubViewChange: this.props.viewsActions.setCardActiveSubView,
            render: (key: string, activeSubView: Metadata.ICardSubView, entities: ProjectsListStore.ProjectInfo[]) => {
                const comparer = SortService.getComparer(fieldsMap, card.sortBy.active.orderBy);
                return <EntitiesCardList
                    key={key}
                    entities={entities.sort(comparer)}
                    onCardRender={(entity: ProjectsListStore.ProjectInfo,
                        cardState: ProjectCardState | undefined,
                        persistCardState: (newCardState: Partial<ProjectCardState>) => void) => <ProjectCard
                            key={entity.id}
                            fields={fieldsMap}
                            entity={entity}
                            showCosts={activeSubView.showCosts}
                            onMenuRender={this._renderMenu}
                            state={cardState}
                            onChangeState={persistCardState}
                            onFavoriteChange={_ => this.props.projectsListStore.setFavorite(entity.id, _)}
                        />}
                    cardParams={{ width: 456, height: 263 }} />;
            }
        }
    }

    private onInlineEditComplete = (field: Metadata.Field, item: ProjectsListStore.ProjectInfo, value: any, extraUpdates?: Dictionary<any>) => {
        this.props.projectsListStore.bulkUpdate({ [item.id]: { [field.name]: value } });
    }

    private getInlineEditProps = (): TInlineEditProps | undefined => this._canEdit(this.props)
        ? buildInlineEditProps(this.onInlineEditComplete, this.props.projectFakeFields, this.props.statusCalculation)
        : undefined;

    private getListView(): IEntitiesScreenView<ProjectsListStore.ProjectInfo> {
        const { list } = this.props.views!;
        const fields = list.fakeFields.concat(this.props.projectFields);
        return {
            subViews: list.subViews.allIds.map(_ => list.subViews.byId[_]),
            icon: 'PPMXListView',
            url: Metadata.ViewTypes.list,
            activeSubViewId: list.activeSubViewId,
            onSubViewChange: this.props.viewsActions.setListActiveSubView,
            onAddSubViewClick: () => {
                const subView = Metadata.SubView.empty();
                this.props.viewsActions.addListSubView(subView);
                this.props.history.push(`/projects/list/${subView.id}`);
                this.setState({ isListViewEdit: true });
            },
            onEditSubViewClick: id => {
                if (list.activeSubViewId !== id) {
                    this.props.history.push(`/projects/list/${id}`)
                }
                this.setState({ isListViewEdit: true });
            },
            onCopySubViewClick: this._onCopyListSubView,
            onRemoveSubViewClick: this.props.viewsActions.removeListSubView,
            render: (key: string, activeSubView: Metadata.IListSubView, entities: ProjectsListStore.ProjectInfo[]) => {
                const listProps: Partial<IListProps> & IDetailsProps = {
                    onItemMenuRender: this._renderMenu,
                    isVirtualizationDisabled: this.props.pngExportDetails?.isInProgress,
                    inlineEditProps: this.getInlineEditProps(),
                };
                if (activeSubView.columns) {
                    return [<ListSubView
                        key="details-view"
                        type="Details"
                        entities={entities}
                        entityType={EntityType.Project}
                        selection={this._selection}
                        fields={fields}
                        isFieldFake={this._buildIsFieldFake()}
                        sort={list.sortBy}
                        sorter={this._sorter}
                        onSortChange={this.props.viewsActions.changeListViewSort}
                        view={activeSubView}
                        listProps={listProps}
                        selectionModeItems={this._buildSelectedModeMenuItems(activeSubView)}
                        onColumnResized={(id, w) => this.props.viewsActions.onListColumnResized(activeSubView.id, id, w)}
                        showSpinner={this.props.isListUpdating} />,
                    this.state.isListViewEdit ? <EditListSubView
                        key="create-details-view"
                        subView={activeSubView}
                        entityType={EntityType.Project}
                        selectedByDefault={this.props.views!.list.selectedByDefault}
                        fields={fields}
                        onChange={changes => this.props.viewsActions.updateListSubView(activeSubView.id, changes)}
                        onSave={() => {
                            this.props.viewsActions.saveListSubView(activeSubView, 'projects');
                            this.setState({ isListViewEdit: false });
                        }}
                        onCopy={() => this._onCopyListSubView(activeSubView)}
                        onDismiss={() => this.setState({ isListViewEdit: false })}
                    /> : <span key="no-edit"></span>
                    ];
                }

                return <div></div>;
            }
        }
    }

    private _onCopyListSubView = (view: Metadata.IListSubView) => {
        const subView = Metadata.SubView.copy(view);
        this.props.history.push(`/projects/list/${subView.id}`);
        this.setState({ isListViewEdit: true });
        this.props.viewsActions.saveListSubView(subView, 'projects', undefined, view.id);
    }

    private _buildSelectedModeMenuItems = (activeSubView: Metadata.ISubView): IContextualMenuItem[] | undefined => {

        const { selectedItemIds } = this.state;
        const { projectsMap } = this.props;
        const selectedItems = projectsMap.getItems(selectedItemIds);
        const entitiesScreenProps = this._buildEntitiesScreenProps();
        const exportCsvMenu = buildExportToCsvMenuItem({
            views: entitiesScreenProps.views,
            fields: entitiesScreenProps.fields,
            fakeFields: entitiesScreenProps.fakeFields,
            selectedItemIds: selectedItemIds,
            entityType: EntityType.Project,
            activeSubView: activeSubView,
            selection: this._selection,
            onExportEntitiesToFile: this.props.projectsListStore.exportEntitiesToFile,
        })

        if (!this.state.canEdit) {
            return [exportCsvMenu];
        }

        const layoutMenuItem = LayoutService.buildApplyLayoutMenuItem(this.props.layouts, (layout: Metadata.Layout) => {
            this.setState({ layoutToApply: layout });
        });

        const onlyPrivateProjectsSelected = selectedItems.every(_ => _.isPrivate);

        return [
            {
                key: "bulk-edit",
                text: "Bulk edit",
                iconProps: { iconName: "TripleColumnEdit" },
                onClick: () => this.props.history.push(`/projects/bulk`),
            },
            {
                key: "run-sync",
                text: "Run sync",
                iconProps: { iconName: "Sync" },
                onClick: () => this.props.projectsListStore.scheduleSyncMany(selectedItemIds),
            },
            exportCsvMenu,
            {
                ...layoutMenuItem,
                disabled: !!selectedItems.find(_ => !_.canConfigure),
            },
            this.props.hasArchiveProjects ? {
                key: "archive",
                text: "Archive",
                iconProps: { iconName: "Archive" },
                disabled: onlyPrivateProjectsSelected,
                title: onlyPrivateProjectsSelected ? "Private projects cannot be archived." : undefined,
                onClick: () => this.setState({ projectsToArchive: selectedItems }),
            } : undefined,
            {
                key: "delete",
                text: "Delete",
                title: MenuTitleBuilder.deleteSelectedTitle(EntityType.Project),
                iconProps: { iconName: "Delete" },
                className: "more-deleteButton",
                onClick: () => this.setState({ projectsToRemove: selectedItems }),
            }
        ].filter(notUndefined);
    };

    private getTimelineView(): IEntitiesScreenView<ProjectsListStore.ProjectInfo> {
        const { timeline } = this.props.views!;
        const { isTimelineViewEdit } = this.state;
        const subViews = timeline.subViews.allIds.map(_ => timeline.subViews.byId[_]);
        const fields = this.props.views!.list.fakeFields.concat(this.props.projectFields);
        return {
            icon: 'PPMXTimelineView',
            url: Metadata.ViewTypes.timeline,
            subViews,
            activeSubViewId: timeline.activeSubViewId,
            onSubViewChange: this.props.viewsActions.setTimelineActiveSubView,
            onAddSubViewClick: () => {
                const subView = Metadata.SubView.empty();
                this.props.viewsActions.addTimelineSubView(subView);
                this.props.history.push(`/projects/timeline/${subView.id}`);
                this.setState({ isTimelineViewEdit: true });
            },
            onEditSubViewClick: id => {
                if (timeline.activeSubViewId !== id) {
                    this.props.history.push(`/projects/timeline/${id}`)
                }
                this.setState({ isTimelineViewEdit: true });
            },
            onCopySubViewClick: this._onCopyTimelineSubView,
            onRemoveSubViewClick: this.props.viewsActions.removeTimelineSubView,
            render: (key: string, activeSubView: Metadata.ITimelineSubView, entities: ProjectsListStore.ProjectInfo[]) => {
                const listProps: Partial<IListProps> & ITimelineProps = {
                    buildRow: (project: ProjectsListStore.ProjectInfo) => buildTimelineItem(project, this.props.projectFields, this.props.keyDateFields,
                        Visibility.OnHover, undefined, this.props.projectsMap.getMap()),
                    renderSegmentContent: CommonTimeline.renderSegmentContent,
                    renderSegmentTooltipContent: CommonTimeline.renderSegmentTooltipContent,
                    renderMarkerTooltipContent: (row, marker) => CommonTimeline.renderMarkerTooltipContent(row, marker, true),
                    onItemMenuRender: this._renderMenu,
                    userQuantization: timeline.quantization,
                    userTimeframe: timeline.timeframe,
                    onScaleChange: (timelineChange) => timelineChange.origin && this.props.viewsActions.setTimelineScale(timelineChange.origin),
                    isVirtualizationDisabled: this.props.pngExportDetails?.isInProgress,
                    inlineEditProps: this.getInlineEditProps(),
                };
                return <>
                    <ListSubView
                        key="timeline-view"
                        type="Timeline"
                        entities={entities}
                        selection={this._selection}
                        entityType={EntityType.Project}
                        fields={fields}
                        isFieldFake={this._buildIsFieldFake()}
                        sort={timeline.sortBy}
                        sorter={this._sorter}
                        onSortChange={this.props.viewsActions.changeTimelineViewSort}
                        view={activeSubView}
                        listProps={listProps}
                        selectionModeItems={this._buildSelectedModeMenuItems(activeSubView)}
                        onColumnResized={(id, w) => this.props.viewsActions.onTimelineColumnResized(activeSubView.id, id, w)}
                        showSpinner={this.props.isListUpdating} />
                    {
                        isTimelineViewEdit && <EditListSubView
                            key="create-timeline-view"
                            subView={activeSubView}
                            entityType={EntityType.Project}
                            selectedByDefault={timeline.selectedByDefault}
                            fields={fields}
                            onChange={changes => {
                                this.props.viewsActions.updateTimelineSubView(activeSubView.id, changes);
                            }}
                            onSave={() => {
                                this.props.viewsActions.saveTimelineSubView(activeSubView, 'projects');
                                this.setState({ isTimelineViewEdit: false })
                            }}
                            onCopy={() => this._onCopyTimelineSubView(activeSubView)}
                            onDismiss={() => this.setState({ isTimelineViewEdit: false })}
                        />
                    }
                </>
            }
        }
    }

    private _onCopyTimelineSubView = (view: Metadata.ITimelineSubView) => {
        const subView = Metadata.SubView.copy(view);
        this.props.history.push(`/projects/timeline/${subView.id}`);
        this.setState({ isTimelineViewEdit: true });
        this.props.viewsActions.saveTimelineSubView(subView, 'projects', undefined, view.id);
    }

    private gridBulkUpdate = (updates: Dictionary<any>) => {
        this.props.projectsListStore.bulkUpdate(updates);
    }

    private getFinishFieldConfig(): IBulkEditInput {
        const getValidator = (attributes: ProjectsListStore.ProjectAttrs) => Validator.new().date().dateIsGreaterThenOrEqual(attributes.StartDate).build();
        return {
            validator: (attributes: ProjectsListStore.ProjectAttrs) => getValidator(attributes),
            render: (props: IInputProps, field: Metadata.Field, entity: ProjectsListStore.ProjectInfo) =>
                <DatePickerInput {...props} inputProps={{ readOnly: field.isReadonly }} validator={getValidator(entity.attributes)} minDate={entity.attributes.StartDate} />
        }
    }

    private getStartFieldConfig(): IBulkEditInput {
        const getValidator = (attributes: ProjectsListStore.ProjectAttrs) => Validator.new().date().dateIsLessThenOrEqual(attributes.FinishDate).build();
        return {
            validator: (attributes: ProjectsListStore.ProjectAttrs) => getValidator(attributes),
            render: (props: IInputProps, field: Metadata.Field, entity: ProjectsListStore.ProjectInfo) =>
                <DatePickerInput {...props} inputProps={{ readOnly: field.isReadonly }} validator={getValidator(entity.attributes)} maxDate={entity.attributes.FinishDate} />
        }
    }

    private disableIfPrivateFieldConfig(): IBulkEditInput {
        return {
            editable: (entity: ProjectsListStore.ProjectInfo) => !entity.isPrivate
        }
    }

    private bulkEditAttributesCustomRenderBuilder = (): Dictionary<IBulkEditInput> => {
        return {
            "StartDate": this.getStartFieldConfig(),
            "FinishDate": this.getFinishFieldConfig(),
            "Program": this.disableIfPrivateFieldConfig(),
            "Portfolio": this.disableIfPrivateFieldConfig(),
            ...getStatusesInputs(ProjectsListStore.statuses, this.props.statusCalculation)
        };
    }

    private _sorter: Sorter<ProjectsListStore.ProjectInfo> = (orderBy) => {
        const { list } = this.props.views!;
        const fields = list.fakeFields.concat(this.props.projectFields);
        const isFieldFake = this._buildIsFieldFake();
        const extractor = (item: ProjectsListStore.ProjectInfo, field: Metadata.Field) => {
            if (field.name === nameof<ProjectsListStore.ProjectAttrs>("Portfolio")) {
                return getPortfolios(item);
            }
            if (field.name === LayoutsStore._layoutFakeFieldName) {
                return item.layoutId ? this.props.layouts.byId[item.layoutId]?.name : null;
            }
            return SortService.getFieldValueBaseImpl(field, item, isFieldFake)
        }

        const fieldsMap = Metadata.toMap(fields, isFieldFake);
        return (a, b) => SortService.getComparer(fieldsMap, orderBy, isFieldFake, extractor)(a, b);
    };

    private _buildIsFieldFake = (): (_: Metadata.Field) => boolean => {
        const { list } = this.props.views!;
        const fakeMap = toDictionaryById(list.fakeFields);
        return (_: Metadata.Field) => !!fakeMap[_.id];
    }

    private _getViews = () => [this.getCardView(), this.getListView(), this.getTimelineView()];

    private _getPngExportConfig = (): PngExportConfig => {
        const views = this._getViews();
        const activeView = getActiveViewType(views, this.props.views!.activeViewType);
        return {
            ...getPngExportConfigSelectors(activeView),
            name: "Projects",
            controlId: ProjectsList.name,
            activeView,
            rowsCount: this.state.filtered.length
        };
    }
}

function mapStateToProps(state: ApplicationState, ownProps: RouteComponentProps<{}>): StateProps {
    const projectFields = state.fields[EntityType.Project];
    const keyDateFields = state.fields[EntityType.KeyDate];
    const filters = FiltersStore.getFilter(state.filters, EntityType.Project);
    const autoFilter = Metadata.Filter.getAutoFilter(filters.all) ?? filters.all[0];
    return {
        isImportDialogOpen: state.import.projects.isDialogOpen,
        isLoading: state.projectsList.isLoading || state.portfolios.isLoading,
        isListLoading: state.projectsList.isListLoading,
        isListUpdating: state.projectsList.isListUpdating,
        projects: state.projectsList.allIds.map(_ => state.projectsList.byId[_]),
        programs: state.programs.allIds.map(_ => state.programs.byId[_]),
        projectsMap: useEntityMapHelper(state.projectsList.byId),
        portfolios: state.portfolios.allIds.map(_ => state.portfolios.byId[_]),
        layouts: state.layouts[EntityType.Project],
        projectFields: projectFields.allIds.map(_ => projectFields.byId[_]),
        keyDateFields: keyDateFields.allIds.map(_ => keyDateFields.byId[_]),
        projectFakeFields: state.views[EntityType.Project].list.fakeFields,
        activeFilter: filters.active.filter,
        autoFilter: autoFilter,
        preFilterId: filters.active.preFilterId,
        projectReports: state.tenant.reporting.projectReports.subPacks,
        projectsReports: state.tenant.reporting.projectsReports,
        views: state.views[EntityType.Project],
        deletionResult: state.projectsList.deletionResult,
        archivingResult: state.projectsList.archivingResult,
        user: state.user,
        tenant: state.tenant,
        statusCalculation: state.tenant.insights.project.statusCalculation,
        integrations: new Integrations(state.tenant.subscription.integrations),
        pngExportDetails: state.pngExporter.controls[ProjectsList.name],
        hasArchiveProjects: Subscription.contains(state.tenant.subscription, PPMFeatures.ArchiveProjects),
        hasPortfolioManagement: Subscription.contains(state.tenant.subscription, PPMFeatures.PortfolioManagement),
        subscription: state.tenant.subscription
    };
}

function mergeActionCreators(dispatch: any): ActionProps {
    return {
        projectsListStore: bindActionCreators(ProjectsListStore.actionCreators, dispatch),
        programsListStore: bindActionCreators(ProgramsListStore.actionCreators, dispatch),
        portfoliosListStore: bindActionCreators(PortfoliosListStore.actionCreators, dispatch),
        notificationsActions: bindActionCreators(Notifications.actionCreators, dispatch),
        filtersActions: bindActionCreators(FiltersStore.actionCreators.forEntity(EntityType.Project), dispatch),
        viewsActions: bindActionCreators(ViewsStore.actionCreators.forEntity(EntityType.Project), dispatch)
    };
}

export default connect(mapStateToProps, mergeActionCreators)(ProjectsList);

export const buildProjectItemRender = (
    item: ProjectsListStore.ProjectInfo,
    field: Metadata.Field,
    fields: Metadata.Field[],
    fakeFields: Metadata.Field[],
    statusCalculation: StatusCalculationTypes,
    isTimelineView: boolean,
    columns: Metadata.ISubViewColumn[] | undefined,
    onInlineEditComplete: TOnInlineEditComplete
): JSX.Element => {
    const fieldName = Metadata.findMatchingFieldName(fields, field, (f: Metadata.Field) => fakeFields.some(_ => _.id === f.id));
    const column = fieldName
        ? buildEntityColumn(
            fieldName,
            isTimelineView,
            EntityType.Project,
            undefined,
            (item as any).isArhived,
            toDictionaryByName(fields),
            undefined,
            columns,
            buildInlineEditProps(onInlineEditComplete, fakeFields, statusCalculation)
        ) : null;
    return ViewService.renderCell(column, item);
}

const buildInlineEditProps = (
    onInlineEditComplete: TOnInlineEditComplete,
    fakeFields: Metadata.Field[],
    statusCalculation: StatusCalculationTypes,
): TInlineEditProps  => {
    return {
        onInlineEditComplete: onInlineEditComplete,
        readonlyFields: (entity: ProjectsListStore.ProjectInfo) => [
            ...fakeFields.map(_ => _.name)
                .filter(_ => !(["80815298-792a-4dcb-9735-2ceffb55eb81"/*Favorite*/, "tasks", "linkedSystem", "syncStatus"].includes(_))),
            ...buildReadOnlyStatusFieldsNames(statusCalculation, ProjectsListStore.statuses, !entity?.insights.statusCalculationDisabled),
            ...(entity?.isPrivate ? namesof<ProjectsListStore.ProjectAttrs>(["Program", "Portfolio"]) : [])
        ],
        customFieldValidatorBuilder: { ...validators, ...buildIWithBenefitsValidators() },
        uiControlElementsCustomRender: {
            ...rendersBuilder(),
            ...buildIWithBenefitsCustomRenders()
        },
    };
}
