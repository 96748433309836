type Props = {
    header: string;
    secondaryText?: string;
}

export const PanelHeader = (props: Props) => {
    const { header, secondaryText } = props;
    return <div className="ms-Panel-header">
        <p className="ms-Panel-headerText">{header}</p>
        <div className='ms-Panel-secondaryText'>{secondaryText}</div>
    </div>;
}
