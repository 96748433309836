import * as React from "react";
import { Scope } from "./common";
import { Checkbox } from "office-ui-fabric-react";

type Props = {
    scopes: Scope[],
    onChange: (scopes: Scope[]) => void
}

type ScopeGroup = {
    key: string;
    label: string;
    description?: string;
    scopes: { read: Scope, write?: Scope }
}

const scopeGroups: ScopeGroup[] = [
    {
        key: "user",
        label: "Users",
        scopes: { read: Scope.UserRead }
    },
    {
        key: "resource",
        label: "Resources & Calendars",
        scopes: { read: Scope.ResourceRead, write: Scope.ResourceWrite }
    },
    {
        key: "project",
        label: "Projects",
        scopes: { read: Scope.ProjectRead, write: Scope.ProjectWrite }
    },
    {
        key: "challenges",
        label: "Business Challenges",
        scopes: { read: Scope.ChallengeRead, write: Scope.ChallengeWrite }
    },
    {
        key: "idea",
        label: "Ideas",
        scopes: { read: Scope.IdeaRead, write: Scope.IdeaWrite }
    },
    {
        key: "task",
        label: "Tasks",
        scopes: { read: Scope.TaskRead, write: Scope.TaskWrite }
    },
    {
        key: "keyDate",
        label: "Key Dates",
        scopes: { read: Scope.KeyDateRead, write: Scope.KeyDateWrite }
    },
    {
        key: "program",
        label: "Program",
        scopes: { read: Scope.ProgramRead, write: Scope.ProgramWrite }
    },
    {
        key: "portfolio",
        label: "Portfolio",
        scopes: { read: Scope.PortfolioRead, write: Scope.PortfolioWrite }
    },
    {
        key: "timeTracking",
        label: "Time Tracking",
        scopes: { read: Scope.TimeTrackingRead, write: Scope.TimeTrackingWrite }
    }
]

export default class ScopesSelect extends React.Component<Props> {
    public render() {
        const allChecked = isFullAccess(this.props.scopes);
        return <>
            <div className="scope-group-scopes">
                <Checkbox checked={allChecked} label="Full access" onChange={this._onAllChange} />
            </div>
            <div className="scope-groups">
                {scopeGroups.map(this._renderScopeGroup)}
            </div>
        </>;
    }

    private _onAllChange = (e: any, checked: boolean) => {
        this.props.onChange(checked ? ALL_SCOPES : []);
    }

    private _renderScopeGroup = (group: ScopeGroup) => {
        const { scopes } = this.props;
        const hasRead = !!~scopes.indexOf(group.scopes.read);
        const hasWrite = !!~scopes.indexOf(group.scopes.write!);
        return <div key={group.key} className="scope-group">
            <div className="scope-group-label">{group.label}</div>
            {group.description && <div className="scope-group-description">{group.description}</div>}
            <div className="scope-group-scopes">
                <Checkbox
                    label="Read"
                    disabled={hasWrite}
                    checked={hasRead || hasWrite}
                    onChange={(_, c) => this._onChange([group.scopes.read], c)}
                />
                {group.scopes.write !== undefined &&
                    <Checkbox
                        label="Read & write"
                        checked={hasWrite}
                        onChange={(_, c) => this._onChange([group.scopes.write!, group.scopes.read], c)}
                    />
                }
            </div>
        </div>;
    }

    private _onChange = (scope: Scope[], checked?: boolean) => {
        const { scopes } = this.props;
        this.props.onChange(checked ? scopes.concat(scope) : scopes.filter(_ => !~scope.indexOf(_)));
    }
}

export const ALL_SCOPES = Object.values(Scope)
    .filter(value => typeof value === 'string' && value !== Scope.MPPFileIntegration)
    .map(value => value as Scope);

export const isFullAccess = (scopes: Scope[]) => {
    return !ALL_SCOPES.find(_ => !scopes.includes(_));
}

export const MPPFileIntegrationScopes: Scope[] = [Scope.MPPFileIntegration]

export const isMPPFileIntegrationAccess = (scopes: Scope[]) => {
    return !MPPFileIntegrationScopes.find(_ => !scopes.includes(_));
}