import React from 'react'
import TimeTrackingImportSuggestionsPanelBase, { BaseProps, getStartAndEnd, ImportedSuggestionProps, ImportTimeTrackingSuggestionFilterBase, TimeTrackingSuggestionBase } from './TimeTrackingImportSuggestionsPanelBase';
import { cancellableGet, CancellablePromiseWrapper } from '../../../fetch-interceptor';
import Link from '../../common/Link';
import { TimeTrackingEntry, TimeTrackingEntryAttr, TimeTrackingEntryProject, TimeTrackingEntrySourceInfo } from '../../../store/TimeTrackingStore';
import { ITimeframe } from '../../../entities/common';
import { sortByDateTime, toDate } from '../../utils/common';
import EntityName from '../../views/list/columns/EntityName';
import { Icon, TooltipHost } from 'office-ui-fabric-react';
import { default as TaskTitle } from '../../views/list/columns/task/Title';
import { NameField } from '../common';
import TimeTrackingAdministrativeCategoryEntityName from '../TimeTrackingAdministrativeCategoryEntityName';
import { renderTooltipField } from '../tooltips/cell/TimeTrackingCellTooltip';

type Props = BaseProps<ExternalSuggestion> &
{
    projects: TimeTrackingEntryProject[];
    project?: TimeTrackingEntryProject;

    onDismiss: () => void;
    onImport: (entity: ExternalSuggestion, callback: () => void) => void;
    isEntityImported: (entity: ExternalSuggestion) => ImportedSuggestionProps | undefined;

    timeFrame: ITimeframe;

    resourceId: string;
};

type TimeTrackingSuggestedEntry = {
    attributes: TimeTrackingEntryAttr;
    sourceInfo?: TimeTrackingEntrySourceInfo;
}

type ExternalSuggestion = TimeTrackingSuggestionBase &
{
    entry: TimeTrackingSuggestedEntry
};

type Filter = ImportTimeTrackingSuggestionFilterBase;

export default function TimeTrackingImporExternalSuggestions(props: Props) {
    const { resourceId } = props;
    return (
        <TimeTrackingImportSuggestionsPanelBase
            {...props}
            onDismiss={() => {
                _loadSuggestionInProgress?.cancelTokenSource.cancel();
                props.onDismiss();
            }}
            loadSuggestions={_loadSuggestionsBuilder(resourceId)}
            onRenderHeader={() => (
                <div className="ms-Panel-header">
                    <div className="ms-Panel-headerText">Import External Time</div>
                    <div className="ms-Panel-secondaryText">
                        Import External Activities as PPM Express Time Entries.
                        Please refer to <Link target="_blank" href="https://help.ppm.express/time-tracking/how-to-import-external-activities-as-ppm-express-time-entries">the article</Link> for more details.
                    </div>
                </div>
            )}
            onRenderItemName={(entity: ExternalSuggestion) => {

                return (
                    <TooltipHost
                        tooltipProps={{
                            onRenderContent: () => {
                                return <EntityTooltip entity={entity} />
                            },
                            maxWidth: "440px"
                        }}>

                       <ItemName entity={entity}/>
                    </TooltipHost>
                );
            }}
        />);
}

const ItemName = (props: {
    entity: ExternalSuggestion
}): JSX.Element => {
    const { entity } = props;

    if (entity.entry.attributes.Task) {
        return (
            <TaskTitle
                entity={{ attributes: {}, externalData: {} } as any}
                name={entity.entry.attributes.Task.name}
                field={NameField}
                disableNavigation />
        );
    }

    if (entity.entry.attributes.CustomTimeEntryName) {
        return (
            <EntityName
                name={entity.entry.attributes.CustomTimeEntryName}
                renderLogo={() =>
                    <div className='logo task-logo align-center custom-time-entry-logo'>
                        <Icon iconName="StatusCircleCheckmark" className='icon' />
                        <Icon className='person' iconName='PPMXPerson' />
                    </div>
                } />
        );
    }

    if (entity.entry.attributes.AdministrativeCategory) {
        return (
            <TimeTrackingAdministrativeCategoryEntityName categoryId={entity.entry.attributes.AdministrativeCategory} />
        )
    }

    return <></>
}

const EntityTooltip = (props: {
    entity: ExternalSuggestion
}): JSX.Element => {
    const { entity } = props;

    return (
        <div className='timeline-tooltip time-tracking-tooltip'>
            <div className="header item-name">
                <ItemName entity={entity}/>
            </div>
            <div className="content">
                {renderTooltipField("Description", entity.entry.attributes.Description!)}
            </div>
        </div>
    ); 
}

let _loadSuggestionInProgress: CancellablePromiseWrapper<TimeTrackingEntry[]>;

const _loadSuggestionsBuilder = (resourceId: string) => {
    
    return (timeFrame: ITimeframe, filter: Filter) => {
    
        const { start, end } = getStartAndEnd(timeFrame, filter);
        
        return _loadSuggestions(resourceId, { start, end, searchText: filter.searchText })
    }
};

type LoadSuggestionFilter = {
    start: Date,
    end: Date,
    searchText?: string
};

const _loadSuggestions = (resourceId: string, filter: LoadSuggestionFilter): Promise<ExternalSuggestion[]> => {
    return new Promise<ExternalSuggestion[]>((resolve, reject) => {

        _loadSuggestionInProgress?.cancelTokenSource.cancel();

            _loadSuggestionInProgress = cancellableGet<TimeTrackingEntry[]>(
                `api/timetracking/resource/${resourceId}/suggestions`,
                {
                    start: filter.start.toDateOnlyString(),
                    end: filter.end.toDateOnlyString(),
                    searchText: filter.searchText
                }
            );

            _loadSuggestionInProgress.promise
                .then(data => {
                    resolve(_mapToExternalSuggestion(data));
                })
                .catch(error => error.message !== "canceled" && reject(error));
        });
};

const _mapToExternalSuggestion = (data: TimeTrackingEntry[]) => {
    const suggestions: ExternalSuggestion[] = data.map(_ => {
        return {
            id: _.id,
            date: toDate(_.attributes.Date)!,
            duration: _.attributes.Duration!,
            name: _.attributes.CustomTimeEntryName!,
            project: _.attributes.Project,
            isAdministrative: !!_.attributes.AdministrativeCategory,
            isProjectReadonly: !!_.attributes.Task,
            entry: _
        };
    });

    suggestions.sort((a, b) => sortByDateTime(a.date, b.date));

    return suggestions;
}
