import * as React from 'react';
import { RouteComponentProps } from "react-router-dom";
import {
    IColumn, DefaultButton, Selection, IObjectWithKey, DetailsListLayoutMode, IDetailsRowProps, DetailsRow,
    ScrollbarVisibility, ScrollablePane, IDetailsHeaderProps, IRenderFunction, ITooltipHostProps, TooltipHost, StickyPositionType, Sticky, Spinner as FabricSpinner,
    SpinnerSize, IContextualMenuItem, Overlay, ContextualMenuItemType, MessageBar, MessageBarType, IDialogContentProps, Link, Checkbox
} from 'office-ui-fabric-react';
import { IUser, actionCreators, LicensesUtilization } from "../../../store/UsersListStore";
import { Subscription, checkIfADUsersSyncEnabled } from "../../../store/Tenant";
import { connect } from "react-redux";
import { ApplicationState } from "../../../store/index";
import DetailsListWrapper from "../../common/DetailsListWrapper";
import UserEdit from "../UserEdit";
import { DefaultPermissionsPanel } from "../DefaultPermissionsPanel";
import { PermissionsBulkEditPanel } from "../PermissionsBulkEditPanel";
import UserReplace from '../UserReplace';
import { ViewService, IListViewColumn } from "../../../services/ViewService";
import Spinner from "../../common/Spinner";
import { SortService } from '../../../services/SortService';
import { UserState, UserStatus } from "../../../store/User";
import { contains, CommonOperations, LicenseTypeMap, LicenseType } from "../../../store/permissions";
import { notUndefined } from '../../utils/common';
import InvitePeople from '../../navigation/InvitePeople';
import { PreFilterOption } from '../../../entities/Metadata';
import { ClearFilterComponent } from '../../common/sectionsControl/SectionPlaceholder';
import { AuthProvider, AuthProvidersMap, SortDirection, getCheckboxOptionsBasedOnCommands } from '../../../entities/common';
import RemoveDialog from '../../common/RemoveDialog';
import { AlertDialog } from '../../common/AlertDialog';
import { RowMenu } from '../../common/extensibleEntity/RowMenu';
import { RowMenuColumn } from '../../common/extensibleEntity/RowMenuColumn';
import { buildAllowedAuthProvidersOptions } from '../AuthProviderSelect';
import SelectionModeSwitchableCommandBar from '../../common/SelectionModeSwitchableCommandBar';
import { MenuTitleBuilder } from '../../MenuTitleBuilder';
import { ConfirmationDialog } from '../../common/ConfirmationDialog';
import { IDeletionResult } from '../../../store/services/storeHelper';
import './UsersList.css';

type UsersListOwnProps = RouteComponentProps<{}>;
type StateProps = {
    subscription: Subscription;
    allowedAuthProviders?: AuthProvider[];
    users: IUser[];
    currentUser: UserState;
    isLoading: boolean;
    isProcessing: boolean;
    isADUsersSyncEnabled: boolean;

    isUtilizationLoading: boolean;
    licensesUtilization: LicensesUtilization;
    deletionResult: { users: IDeletionResult[]; resources: IDeletionResult[] } | undefined;
};

type UsersListProps = typeof actionCreators & UsersListOwnProps & StateProps;

type SortBy = {
    fieldName: keyof IUser;
    direction: SortDirection;
};

type UsersListState = {
    selectedCount: number;
    userId: string | null;
    sortBy: SortBy;
    users: IUser[];
    columns: IColumn[];
    canManage: boolean;
    userToReplace?: IUser;
    activePreFilter: PreFilterOption<IUser>;
    selectedLicense?: LicenseType;
    bulkEdit?: boolean;
    showRemoveDialog: boolean;
    usersToDelete: string[];
    deleteResources: boolean;
    activationResult?: { activated: IUser[], notActivated: IUser[] };
    authProviderOptions: IContextualMenuItem[];
    confirmUpdateAuthProvider?: { callback: () => void; count: number; };
};

interface IUserColumn extends IColumn { fieldName: keyof IUser; }

class UsersList extends React.Component<UsersListProps, UsersListState> {
    private _selection: Selection;
    private _preFilterOptions: IContextualMenuItem[];

    constructor(props: UsersListProps) {
        super(props);
        const sortBy: SortBy = {
            fieldName: "fullName",
            direction: SortDirection.ASC
        };

        const preFilterOptions: PreFilterOption<IUser>[] = [
            { key: "all", name: "All", predicate: _ => true },
            { key: "active", name: "Active", predicate: _ => _.status === UserStatus.Active },
            { key: "inactive", name: "Inactive", predicate: _ => _.status === UserStatus.Inactive },
            { key: "pending", name: "Pending Invite", predicate: _ => _.status === UserStatus.PendingInvite },
            { key: "user", name: "User", predicate: _ => _.license === LicenseType.Regular },
            { key: "teamMember", name: "Team Member", predicate: _ => _.license === LicenseType.Viewer },
            { key: "notApplied", name: "Not Applied", predicate: _ => _.license === LicenseType.None },
            { key: "office365", name: "Office 365", predicate: _ => _.authProvider === AuthProvider.o365 },
            { key: "google", name: "Google", predicate: _ => _.authProvider === AuthProvider.google },
            { key: "email ", name: "Email", predicate: _ => _.authProvider === AuthProvider.email }
        ];

        const canManage = contains(props.currentUser.permissions.common, CommonOperations.UserManage);
        this.state = {
            selectedCount: 0,
            userId: null,
            sortBy: sortBy,
            users: props.users,
            columns: this._buildColumns(sortBy, canManage, props.isADUsersSyncEnabled),
            canManage: canManage,
            activePreFilter: preFilterOptions[0],
            showRemoveDialog: false,
            usersToDelete: [],
            deleteResources: false,
            authProviderOptions: buildAllowedAuthProvidersOptions(props.allowedAuthProviders ?? [])
        };
        this._preFilterOptions = [
            { key: 'default-filters', text: 'Default filters', itemType: ContextualMenuItemType.Header },
            ...preFilterOptions.map(_ => ({
                key: _.key,
                text: _.name,
                onClick: () => this.setState({ activePreFilter: _ })
            }))
        ];
        this._selection = new Selection({
            canSelectItem: (user: IObjectWithKey) => (user as IUser).id !== this.props.currentUser.id,
            onSelectionChanged: () => this.setState({
                selectedCount: this._selection.getSelectedCount(),
            }),
            getKey: (_: IObjectWithKey) => (_ as IUser).id
        });
    }

    componentWillMount() {
        this.props.loadUsers();
        this.props.loadLicensesUtilization();
    }

    componentWillReceiveProps(props: UsersListProps) {
        if (this.props.users !== props.users) {
            const users = [...props.users];
            users.sort(this._getComparer(this.state.sortBy));
            this.setState({ users });
        }
    }

    private _onRenderColumn(item: any, index?: number, column?: IColumn, defaultRender?: () => JSX.Element) {
        if (!column) {
            return <span></span>;
        }
        return <span className="overflow-text selectable-text">
            {defaultRender ? defaultRender() : item[column.fieldName!]}
        </span>;
    }

    private _clearFilter = () => this.setState({ activePreFilter: { key: "all", name: "All", predicate: _ => true } })

    public render() {
        if (this.props.isLoading) {
            return <Spinner />;
        }
        const { selectedCount, userId, columns, users, canManage,
            selectedLicense, bulkEdit, activationResult } = this.state;
        const { currentUser, isProcessing, deletionResult } = this.props;
        const filteredUsers = this.applyFilter(users);

        const selectedPendingInviteCount = this._getSelected(UserStatus.PendingInvite).length;
        const selectionModeCommands = this._buildSelectionModeCommands();

        const showDeleteResources = this._getUsersToDelete().some(_ => _.status === UserStatus.PendingInvite && _.linkedResource);

        const notDeletedUsers = deletionResult?.users.filter(_ => !_.isDeleted);
        const notDeletedResources = deletionResult?.resources.filter(_ => !_.isDeleted);
        const showDeleteWarning = (notDeletedUsers?.length ?? 0) > 0 || (notDeletedResources?.length ?? 0) > 0;

        const unassign = notDeletedUsers && notDeletedUsers.length > 0 ? <>
            <span>We recommend using the 'Replace User' option to replace {notDeletedUsers.length > 1 ? "these users" : "this user"} and merge their existing data into another user selected as a replacement.
            If 'Replace User' option is used, linked resources and their corresponding data will be merged as well.
                Alternatively, you can unassign or remove {notDeletedUsers.length > 1 ? "these users" : "this user"} from the fields first. </span>
            <Link href="https://help.ppm.express/89502-ppm-express-how-to-articles/637207-how-to-replace-users?from_search=149628615" target="_blank">
                Learn more
            </Link>
        </>
            : (notDeletedResources && notDeletedResources.length > 0 ? <>
                <span>We recommend using the 'Merge Resources' option to replace {notDeletedResources.length > 1 ? "these resources" : "this resource"} on Resources page and merge their existing data into another resource selected as a replacement.
                    Alternatively, you can unassign or remove {notDeletedResources.length > 1 ? "these resources" : "this resource"} from the fields first. </span>
                <Link href="https://help.ppm.express/111183-resource-management/resource-merging?from_search=149628615" target="_blank">
                    Learn more
                </Link>
            </> : undefined);

            

        return <div className="user-management">
            {this._renderNavigation()}
            <h2>People management</h2>
            <div className="entities-list">
                <div className='entities-list-header'>
                    <SelectionModeSwitchableCommandBar
                        items={[]}
                        farItems={this._getFarCommands()}
                        entityTypeLabels={{ singular: 'person', plural: 'people' }}
                        selectionMode={{
                            enabled: selectedCount > 0,
                            selectedCount,
                            items: selectionModeCommands,
                            onCancel: () => this._selection.setAllSelected(false),
                        }}
                    />
                </div>
                {<ClearFilterComponent items={this.props.users} filteredItems={filteredUsers} onClearFilter={this._clearFilter}>
                    <div className="entities-list-body list-container">
                        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                            <DetailsListWrapper
                                setKey='set'
                                layoutMode={DetailsListLayoutMode.justified}
                                items={filteredUsers}
                                columns={columns}
                                selection={this._selection}
                                onRenderRow={this._onRenderRow}
                                onRenderDetailsHeader={this._onRenderDetailsHeader}
                                onColumnHeaderClick={this._onColumnHeaderClick}
                                {...getCheckboxOptionsBasedOnCommands(selectionModeCommands, true)}
                            />
                        </ScrollablePane>
                        {userId && <UserEdit
                            readOnly={!canManage || (userId === currentUser.id && !contains(currentUser.permissions.common, CommonOperations.Administrate))}
                            isCurrentUser={userId === currentUser.id}
                            onDismiss={() => this.setState({ userId: null })}
                            userId={userId} />}
                        {selectedLicense !== undefined && <DefaultPermissionsPanel
                            readOnly={!canManage}
                            license={selectedLicense}
                            onDismiss={() => this.setState({ selectedLicense: undefined })} />}
                        {bulkEdit && <PermissionsBulkEditPanel
                            ids={this._selection.getSelection().map(_ => (_ as IUser).id)}
                            counters={this._selection.getSelection()
                                .reduce((cum, cur) => ({
                                    ...cum,
                                    [(cur as IUser).license as number]: ((cum[(cur as IUser).license as number] ?? 0) + 1)
                                }),
                                    {} as { [i: number]: number })}
                            onDismiss={() => this.setState({ bulkEdit: undefined })} />}
                    </div>
                </ClearFilterComponent>}
            </div>
            {this.state.showRemoveDialog && <RemoveDialog
                onClose={() => { this.setState({ showRemoveDialog: false }) }}
                onComplete={() => this._deleteUsers()}
                dialogContentProps={{
                    title: "Delete users",
                    subText: this._buildRemoveDialogText(),
                    className: "remove-user-dialog-content"
                }}
                confirmButtonProps={{ text: "Delete" }}>
                {this.state.usersToDelete.length > 1 && this.state.selectedCount > selectedPendingInviteCount && this._buildDeleteUsersMessageBar()}
                <MessageBar messageBarType={MessageBarType.info} isMultiline>
                    If a user has assignments in any default or custom fields of the 'User' type,
                    this user will not be deleted until those assignments are removed. However,
                    if a resource linked to this user has assignments, this resource and its assignments will be preserved,
                    allowing the user to be deleted.
                </MessageBar>
                {showDeleteResources && this._renderDeleteResources()}
            </RemoveDialog>}
            {deletionResult && ((notDeletedUsers && notDeletedUsers.length > 0) || (notDeletedResources && notDeletedResources.length > 0)) && <RemoveDialog
                onClose={() => { this.props.dismissDeletionResult() }}
                confirmButtonProps={{ text: "Got it" }}
                modalProps={{ styles: { main: { minWidth: 500 } } }}
                dialogContentProps={this._getDeletionResultDialogContent(deletionResult)}>
                {showDeleteWarning && <div className="message-bar-holder">
                    {notDeletedUsers && notDeletedUsers.length > 0 && <MessageBar messageBarType={MessageBarType.warning} isMultiline>
                        Failed to delete the following {notDeletedUsers.length > 1 ? `users ${this.state.deleteResources ? "with linked resources" : ""} as they are` : `user ${this.state.deleteResources ? "with linked resource" : ""} as the user is`} in use:
                        <ul>
                            {notDeletedUsers.map(_ => <li key={_.id}>{_.message}</li>)}
                        </ul>
                    </MessageBar>
                    }
                    {notDeletedResources && notDeletedResources.length > 0 && <MessageBar messageBarType={MessageBarType.warning} isMultiline>
                        Failed to delete the following associated {notDeletedResources.length > 1 ? "resources as they are" : "resource as the resource is"} in use:
                        <ul>
                            {notDeletedResources.map(_ => <li key={_.id}>{_.message}</li>)}
                        </ul>
                    </MessageBar>
                    }
                </div>}
                {unassign && <MessageBar messageBarType={MessageBarType.info} isMultiline>
                    {unassign}
                </MessageBar>}
            </RemoveDialog>}
            {this.state.userToReplace && <UserReplace prevUser={this.state.userToReplace} onDismiss={() => this.setState({ userToReplace: undefined })} />}
            {activationResult && <AlertDialog
                onDismiss={() => this.setState({ activationResult: undefined })}
                dialogContentProps={this._getActivationResultDialogContent(activationResult.activated, activationResult.notActivated)}>
                {(activationResult.activated.length && activationResult.notActivated.length === 1 || activationResult.notActivated.length > 1) &&
                    <MessageBar messageBarType={MessageBarType.warning} isMultiline>
                        Failed to activate the following users because authentication provider defined in users’ System is turned off for the organization.
                        Turn on the corresponding authentication provider in the Tenant Settings or invite these users using a different email:
                        <ul>
                            {activationResult.notActivated.map(_ => <li key={_.id}>{_.fullName} "{_.logonAccount}"</li>)}
                        </ul>
                    </MessageBar>}
            </AlertDialog>}
            {this.state.confirmUpdateAuthProvider && <ConfirmationDialog
                onDismiss={() => this.setState({ confirmUpdateAuthProvider: undefined })}
                onYes={this.state.confirmUpdateAuthProvider.callback}
                onNo={() => { }}
                yesButtonProps={{ text: "Confirm" }}
                noButtonProps={{ text: "Cancel" }}
                dialogContentProps={{
                    title: 'Change Authentication Provider',
                    subText: `Are you sure that you want to change authentication provider for selected ${this.state.confirmUpdateAuthProvider.count > 1
                        ? `users? (${this.state.confirmUpdateAuthProvider.count} users)`
                        : "user?"}`
                }} >
                <MessageBar messageBarType={MessageBarType.warning} isMultiline>
                    Selected users with an 'Active' or 'Inactive' status will have their status changed to 'Pending Invite'.<br />
                    The authentication provider will be updated for these users, and an invitation email will be sent
                    to allow them to rejoin PPM Express with the updated authentication provider.<br />
                    <br />
                    For Active Users, their license type, permissions, and assignments will remain unchanged.<br />
                    For Inactive Users, their assignments will be preserved, and upon rejoining the tenant, they will be assigned a default license with default permissions,
                    unless a specific license and permissions have already been set for them.
                </MessageBar>
            </ConfirmationDialog>}
            {isProcessing && <Overlay><Spinner /></Overlay>}
        </div>;
    }

    private _renderDeleteResources() {
        return <>
            <Checkbox
                className="delete-resources-check"
                label={this.state.usersToDelete.length > 1 ? "Delete Linked Resources" : "Delete Linked Resource"}
                checked={this.state.deleteResources}
                onChange={(e, c) => this.setState({ deleteResources: !!c })} />
            <MessageBar messageBarType={MessageBarType.info} isMultiline>
                Resources linked to the selected users will also be deleted unless they have assignments in
                any default or custom 'Resource' fields. If a resource has assignments, this resource will not be
                deleted until those assignments are removed.
            </MessageBar>
        </>
    }

    private _getActivationResultDialogContent(activated: IUser[], notActivated: IUser[]): IDialogContentProps {
        if (!activated.length && notActivated.length === 1) {
            return {
                title: "Unable to activate user",
                subText: `Failed to activate ${notActivated[0].fullName} "${notActivated[0].logonAccount}" because authentication provider defined in user’s System is turned off \
                    for the organization. Turn on the corresponding authentication provider in the Tenant Settings or invite this user using a different email`
            };
        }

        return activated.length === 1
            ? {
                title: "User activation is complete",
                subText: `User ${activated[0].fullName} "${activated[0].logonAccount}" was activated successfully.`
            }
            : activated.length
                ? {
                    title: "User activation is complete",
                    subText: `Selected users (${activated.length} users) were activated successfully.`
                }
                : {
                    title: "User activation failed"
                };
    }

    private _deleteUsers = () => {
        const pendingInviteUsers = this._getUsersToDelete().filter(_ => _.status === UserStatus.PendingInvite);
        this.props.removeUsers(pendingInviteUsers.map(_ => _.id), this.state.deleteResources);
        this.setState({ usersToDelete: [] });
    }

    private _buildRemoveDialogText = () => {
        const pendingInviteUsers = this._getUsersToDelete().filter(_ => _.status === UserStatus.PendingInvite);

        if (pendingInviteUsers.length === 1) {
            return `Are you sure you want to delete a user ${pendingInviteUsers[0].fullName}?`;
        }

        return `Are you sure you want to delete selected users (${pendingInviteUsers.length} users)?`;
    }

    private _buildDeleteUsersMessageBar = () => {
        const activatedUsers = this._getUsersToDelete().filter(_ => _.status !== UserStatus.PendingInvite);
        if (!activatedUsers.length) {
            return null;
        }
        const message = activatedUsers.length === 1
            ? `${activatedUsers[0].fullName} does not have the "Pending Invite" status and will not be deleted.`
            : `${activatedUsers.length} selected users do not have the "Pending Invite" status and will not be deleted.`;

        return <MessageBar messageBarType={MessageBarType.warning} isMultiline>{message}</MessageBar>
    }

    private _getDeletionResultDialogContent(deletionResult: { users: IDeletionResult[], resources: IDeletionResult[] }): IDialogContentProps {
        if (deletionResult.users.length === 1 && !deletionResult.users[0].isDeleted) {
            return {
                title: "Unable to delete user",
            };
        }
        const deletedUsers = deletionResult.users.filter(_ => _.isDeleted) ?? [];
        return deletedUsers.length === 1
            ? {
                title: "User deletion is complete",
                subText: `User "${deletedUsers[0].name}" was deleted successfully.`
            }
            : deletedUsers.length
                ? {
                    title: "User deletion is complete",
                    subText: `Selected user (${deletedUsers.length} users) were deleted successfully.`
                }
                : {
                    title: "Users deletion failed"
                };
    }

    private _getUsersToDelete = () => this.state.users.filter(_ => this.state.usersToDelete.includes(_.id));

    private _renderNavigation = (): JSX.Element => {
        const { subscription, isUtilizationLoading, licensesUtilization } = this.props;
        return <div className="page-navigation">
            <InvitePeople />
            <DefaultButton
                className="dropdown-button"
                iconProps={{ iconName: 'Edit' }}
                text='Default Permissions'
                menuProps={{ items: this._getDefaultPermissionsItems() }} />
            {subscription.subscriptionId && <div className="align-right licenses-utilization">
                <div>{LicenseTypeMap[LicenseType.Regular].label}s: {isUtilizationLoading
                    ? <FabricSpinner size={SpinnerSize.xSmall} />
                    : <span>{licensesUtilization.users.allocated}/{licensesUtilization.users.total}</span>
                }
                </div>
                {!!subscription.viewersLimit &&
                    <div>{LicenseTypeMap[LicenseType.Viewer].label}s: {isUtilizationLoading
                        ? <FabricSpinner size={SpinnerSize.xSmall} />
                        : <span>{licensesUtilization.viewers.allocated}/{licensesUtilization.viewers.total}</span>
                    }
                    </div>
                }
            </div>}
        </div>;
    }

    private _getDefaultPermissionsItems = () => {
        const { subscription } = this.props;
        const items = [{
            key: 'regular',
            iconProps: { iconName: 'Edit' },
            text: `${LicenseTypeMap[LicenseType.Regular].label} License`,
            onClick: () => this.setState({ selectedLicense: LicenseType.Regular })
        }]

        if (subscription.viewersLimit > 0) {
            items.push({
                key: 'teammember',
                iconProps: { iconName: 'Edit' },
                text: `${LicenseTypeMap[LicenseType.Viewer].label} License`,
                onClick: () => this.setState({ selectedLicense: LicenseType.Viewer })
            });
        }

        return items;
    }

    private _setUsersToDelete = () => {
        const usersToDelete = this._selection.getSelection().map(_ => (_ as IUser));
        this.setState({
            usersToDelete: usersToDelete.map(_ => _.id),
            showRemoveDialog: true,
            deleteResources: usersToDelete.some(_ => _.status === UserStatus.PendingInvite && _.linkedResource)
        });
    }

    private _buildSelectionModeCommands = (): IContextualMenuItem[] => {
        const { canManage, authProviderOptions } = this.state;
        const selectedActiveCount = this._getSelected(UserStatus.Active).length;
        const selectedInactiveCount = this._getSelected(UserStatus.Inactive).length;
        const selectedPendingInviteCount = this._getSelected(UserStatus.PendingInvite).length;

        const { updateStatuses, isADUsersSyncEnabled } = this.props;
        return [
            selectedActiveCount > 0 ? {
                key: 'deactivate',
                text: "Deactivate",
                disabled: !canManage || isADUsersSyncEnabled,
                iconProps: { iconName: "Blocked2" },
                onClick: () => updateStatuses(this._getSelected(UserStatus.Active).map(_ => _.id), UserStatus.Inactive),
            } : undefined,
            selectedInactiveCount > 0 ? {
                key: 'activate-selected',
                text: "Activate Selected",
                disabled: !canManage || isADUsersSyncEnabled,
                iconProps: { iconName: "AddTo" },
                onClick: () => this._activate(this._getSelected(UserStatus.Inactive))
            } : undefined,
            selectedPendingInviteCount > 0 ? {
                key: 'delete',
                text: "Delete",
                title: MenuTitleBuilder.deleteSelectedTitle("Users"),
                iconProps: { iconName: "Delete" },
                onClick: this._setUsersToDelete
            } : undefined,
            {
                key: 'bulk-edit',
                text: "Bulk Edit",
                disabled: !canManage || !this._selection.getSelection().some(_ => (_ as IUser).license !== LicenseType.None),
                iconProps: { iconName: "TripleColumnEdit" },
                onClick: () => this.setState({ bulkEdit: true }),
            },
            selectedPendingInviteCount > 0 ? {
                key: 'resend-invitation',
                text: "Resend Invitation",
                disabled: !canManage,
                iconProps: { iconName: "Sync" },
                onClick: () => this.props.resendInvite(this._getSelected(UserStatus.PendingInvite).map(_ => _.id)),
            } : undefined,
            {
                key: 'change-authProvider',
                text: "Change Auth Provider",
                disabled: !canManage,
                iconProps: { iconName: 'TemporaryUser' },
                subMenuProps: {
                    items: authProviderOptions,
                    onItemClick: (e: any, item?: IContextualMenuItem) => {
                        item && this._updateAuthProvider(this._selection.getSelection().map(_ => _ as IUser), item.key as AuthProvider);
                    }
                }
            }
        ].filter(notUndefined);
    }

    private _onRenderDetailsHeader(props: IDetailsHeaderProps, defaultRender?: IRenderFunction<IDetailsHeaderProps>): JSX.Element {
        return (
            <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced={true}>
                {defaultRender!({
                    ...props,
                    onRenderColumnHeaderTooltip: (tooltipHostProps: ITooltipHostProps) => <TooltipHost {...tooltipHostProps} />
                })}
            </Sticky>
        );
    }

    private applyFilter = (_: IUser[]) => {
        return _.filter(this._isItemVisible);
    }

    private _isItemVisible = (item: IUser): boolean => {
        const { activePreFilter } = this.state;
        return !activePreFilter || activePreFilter.predicate(item);
    }

    private _onColumnHeaderClick = (ev?: React.MouseEvent<HTMLElement>, column?: IUserColumn) => {
        if (!column) {
            return;
        }
        const { fieldName, direction } = this.state.sortBy;
        const sortBy = column.fieldName === fieldName
            ? {
                fieldName,
                direction: direction === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC
            }
            : {
                fieldName: column.fieldName,
                direction: SortDirection.ASC
            }

        this.setState({
            sortBy: sortBy,
            users: this.state.users.map(_ => _).sort(this._getComparer(sortBy)),
            columns: this._buildColumns(sortBy, this.state.canManage, this.props.isADUsersSyncEnabled)
        });
    }

    private _getComparer(sortBy: SortBy) {
        const { fieldName, direction } = sortBy;
        const isDesc = direction === SortDirection.DESC;
        return (a: any, b: any) => SortService.compareWithUndefined(a, b, isDesc,
            (aF: any, bF: any) => {
                const _a = typeof aF[fieldName] === "string" ? aF[fieldName].toLowerCase() : aF[fieldName];
                const _b = typeof bF[fieldName] === "string" ? bF[fieldName].toLowerCase() : bF[fieldName];
                return (_a === _b
                    ? 0
                    : ((isDesc ? (_a > _b) : (_a < _b)) ? -1 : 1));
            });
    }

    private _buildColumns = (sortBy: SortBy, canManage: boolean, isADUsersSyncEnabled: boolean): IColumn[] => {
        const { subscription } = this.props;
        return [
            {
                key: "fullName",
                fieldName: "fullName",
                iconName: "Emoji2",
                name: "User Name",
                headerClassName: "with-icon",
                minWidth: 100,
                maxWidth: 500,
                isResizable: true,
                onRender: (item: IUser) => {
                    const component = ViewService.createListColumn<IListViewColumn<IUser>>('user/Name');
                    return <RowMenuColumn
                        onItemMenuRender={() => <RowMenu item={item} commands={this._getItemCommands(item, canManage, isADUsersSyncEnabled)} />} >
                        {React.createElement(component, { entity: item, onClick: () => this.setState({ userId: item.id }) })}
                    </RowMenuColumn>;
                }
            },
            {
                key: "email",
                fieldName: "email",
                name: "Email",
                minWidth: 100,
                maxWidth: 500,
                isResizable: true,
                onRender: this._onRenderColumn
            },
            {
                key: "logonAccount",
                fieldName: "logonAccount",
                name: "Logon Account",
                minWidth: 100,
                maxWidth: 500,
                isResizable: true,
                onRender: this._onRenderColumn
            },
            {
                key: "status",
                fieldName: "status",
                name: "Status",
                minWidth: 100,
                maxWidth: 100,
                isResizable: true,
                onRender: (item: any) => {
                    const component = ViewService.createListColumn<IListViewColumn<IUser>>('user/Status');
                    return React.createElement(component, { entity: item });
                }
            },
            subscription.subscriptionId ? {
                key: "license",
                fieldName: "license",
                name: "License",
                minWidth: 100,
                maxWidth: 100,
                isResizable: true,
                onRender: (user: IUser) => {
                    const map = LicenseTypeMap[user.license];
                    return <span className={`license ${map.cssClassName}`}>{map.label}</span>;
                }
            } : undefined,
            {
                key: "authProvider",
                fieldName: "authProvider",
                name: "Auth Provider",
                minWidth: 100,
                maxWidth: 100,
                isResizable: true,
                onRender: (user: IUser, index: number, column: IColumn, defaultRender?: () => JSX.Element) => {
                    return user.authProvider && <span className="overflow-text selectable-text">{AuthProvidersMap[user.authProvider]?.friendlyName}</span>;
                }
            }
        ].filter(notUndefined)
            .map(_ => ({
                ..._,
                isSorted: _.fieldName === sortBy.fieldName,
                isSortedDescending: _.fieldName === sortBy.fieldName && sortBy.direction === SortDirection.DESC
            }));
    }

    private _onRenderRow = (props: IDetailsRowProps): JSX.Element => {
        return <DetailsRow {...props} className={`${props.className} ${props.item.status === UserStatus.Inactive ? "inactive" : ""}`} />;
    }

    private _getSelected(status: UserStatus) {
        return this._selection.getSelection().map(_ => _ as IUser).filter(_ => _.status === status);
    }

    private _activate(toActivate: IUser[]) {
        this.props.updateStatuses(toActivate.map(_ => _.id), UserStatus.Active,
            (updatedUsers) => {
                if (toActivate.length === 1 && updatedUsers.length === 1) {
                    this.setState({ userId: updatedUsers[0].id });
                } else {
                    this.setState({
                        activationResult: {
                            activated: updatedUsers,
                            notActivated: toActivate.filter(_ => updatedUsers.findIndex(__ => __.id === _.id) === -1)
                        }
                    });
                }
            });
    }

    private _updateAuthProvider(toUpdate: IUser[], authProvider: AuthProvider) {
        const callback = () => this.props.updateAuthProvider(toUpdate.map(_ => _.id), authProvider);
        if (!toUpdate.every(_ => _.status === UserStatus.PendingInvite)) {
            this.setState({ confirmUpdateAuthProvider: { callback, count: toUpdate.length } });
        } else {
            callback();
        }
    }

    private _getFarCommands = (): IContextualMenuItem[] => {
        const { activePreFilter } = this.state;
        return [
            {
                key: 'filter',
                className: "dropdown-button",
                iconProps: { iconName: 'Filter' },
                text: activePreFilter.name,
                subMenuProps: { items: this._preFilterOptions },
            }
        ];
    }

    private _getItemCommands = (user: IUser, canManage: boolean, isADUsersSyncEnabled: boolean) => {
        return canManage && user.id !== this.props.currentUser.id
            ? [
                user.status !== UserStatus.Inactive ? {
                    key: "edit",
                    name: "Edit",
                    iconProps: { iconName: 'Edit' },
                    onClick: () => { this.setState({ userId: user.id }); }
                } : undefined,
                user.status === UserStatus.PendingInvite ? {
                    key: 'resend',
                    name: 'Resend Invitation',
                    iconProps: { iconName: 'Sync' },
                    onClick: () => { this.props.resendInvite([user.id]); }
                } : undefined,
                {
                    key: 'changeAuthProvider',
                    name: 'Change Auth Provider',
                    iconProps: { iconName: 'TemporaryUser' },
                    subMenuProps: {
                        items: this.state.authProviderOptions,
                        onItemClick: (e: any, item?: IContextualMenuItem) => { item && this._updateAuthProvider([user], item.key as AuthProvider) }
                    }
                },
                user.status === UserStatus.Active ? {
                    key: 'deactivate',
                    name: 'Deactivate',
                    disabled: isADUsersSyncEnabled,
                    iconProps: { iconName: 'Blocked2' },
                    onClick: () => this.props.updateStatuses([user.id], UserStatus.Inactive)
                } : undefined,
                user.status !== UserStatus.Active ? {
                    key: 'replace',
                    name: 'Replace',
                    iconProps: { iconName: 'UserRemove', style: { color: 'red' } },
                    onClick: () => this.setState({ userToReplace: user })
                } : undefined,
                user.status === UserStatus.Inactive ? {
                    key: 'activate',
                    name: 'Activate',
                    disabled: isADUsersSyncEnabled,
                    iconProps: { iconName: 'AddTo' },
                    onClick: () => this._activate([user])
                } : undefined,
                user.status === UserStatus.PendingInvite ? {
                    key: 'delete',
                    name: 'Delete',
                    disabled: isADUsersSyncEnabled,
                    style: isADUsersSyncEnabled ? {} : { color: 'red' },
                    iconProps: { iconName: 'Delete', style: isADUsersSyncEnabled ? {} : { color: 'red' } },
                    onClick: this._setUsersToDelete
                } : undefined,
            ].filter(notUndefined)
            : [
                contains(this.props.currentUser.permissions.common, CommonOperations.Administrate)
                    ? {
                        key: "edit",
                        name: "Edit",
                        iconProps: { iconName: 'Edit' },
                        onClick: () => { this.setState({ userId: user.id }); }
                    }
                    : {
                        key: "view",
                        name: "View",
                        iconProps: { iconName: 'View' },
                        onClick: () => { this.setState({ userId: user.id }); }
                    }
            ];
    }
}

function mapStateToProps(state: ApplicationState, ownProps: UsersListOwnProps): StateProps {
    const byId = state.users.byId;
    return {
        subscription: state.tenant.subscription,
        allowedAuthProviders: state.tenant.security?.allowedAuthProviders,
        users: state.users.allIds.map(_ => byId[_]),
        currentUser: state.user,
        isADUsersSyncEnabled: checkIfADUsersSyncEnabled(state.tenant),
        isLoading: state.users.isLoading,
        isProcessing: state.users.isProcessing || state.tenant.isProcessing,
        licensesUtilization: state.users.licensesUtilization,
        isUtilizationLoading: state.users.isUtilizationLoading,
        deletionResult: state.users.deletionResult
    }
}

export default connect(mapStateToProps, { ...actionCreators })(UsersList);