import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import { get, post, remove } from "../fetch-interceptor";
import * as analytics from '../analytics';
import { UserState, ActionTypes as UserActionTypes, UserActions } from "./User";
import {
    contains as enumContains, CommonOperations, LicenseType, IPermissions, IServerPermissions, toPermissions, toServerPermissions,
    contains as permissionsContains, ResourceOperations, containsAny as enumContainsAny
} from "./permissions";
import { FinancialsState, ActionTypes as FinancialsActionTypes, FinancialsActions } from "./FinancialsStore";
import { TimeState, ActionTypes as TimeActionTypes, TimeActions } from "./TimeStore";
import { catchApiError, defaultCatch } from "./utils";
import { RouterAction, replace } from 'react-router-redux';
import { SourceType } from './ExternalEpmConnectStore';
import { ReportsNav } from '../components/utils/reporting';
import { baseUrl, cultureInfo, toDateTime } from '../components/utils/common';
import { IFieldMapping } from './integration/common';
import { GroupInfo, OrganizationInfo } from './integration/Office365Store';
import * as Notifications from "./NotificationsStore";
import { AuthProvider, AuthProvidersMap, EntityType, MaybeDate, ProgressCalculationType, ServerEntityType } from '../entities/common';
import { addOrUpdateOrRemove } from './services/storeHelper';
// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export class Tenant {
    [key: string]: string;
    companyName: string = '';
    companyAdress: string = '';
    companyWebSite: string = '';
    personName: string = '';
    personJobTitle: string = '';
    personEmail: string = '';
    personPhoneNumber: string = '';
    referralCode: string = '';
    plan: string = '';
    timezone: string = '';
}

export type GlobalSettings = {
    timezone: string;
    cultureName: string;
}

export type ResourcePlanningSettings = {
    resourcePlanningLevel: ServerEntityType
}

export type DefaultProjectSettings = {
    progressCalculationType: ProgressCalculationType;
}

export type SyncSettings = {
    schedule: SyncSchedule,
    alertDelay: string | null,
    alertRecipientIds: string[]
}

export type SyncSchedule = {
    isPerpetual: boolean;
    days: any[];
    times: any[];
}

export type NotificationSchedule = {
    isUserNotificationsEnabled: boolean;
    dailySchedule: HealthReportingSchedule;
    weeklySchedule: HealthReportingSchedule;
}

export enum StatusCalculationTypes {
    Auto = 0x1,
    Manual = 0x2,

    AutoManual = Auto | Manual
}

export type HealthReportingSchedule = {
    days: any[];
    times: any[];
}

export type AzureADSyncSettings = {
    organization: OrganizationInfo;
    lastSyncTime?: string;
    lastSyncErrorMessage?: string;
    schedule: AzureADSyncSchedule;
    mapping: IFieldMapping[];
}

export type AzureADSyncSchedule = {
    days: any[];
    times: any[];
}

export type AzureADUsersSyncSettings = {
    organization: OrganizationInfo;
    usersGroup: GroupInfo;
    teamMembersGroup: GroupInfo;
    isADUsersSyncEnabled: boolean;
    syncType: AdUsersSyncType;
    lastSyncTime?: string;
    lastSyncErrorMessage?: string;
    schedule: AzureADSyncSchedule;
}

type StyleSettings = {
    logoId: string | undefined;
    themeType: ThemeType;
}

export enum AdUsersSyncType {
    DoNotSendInvites,
    SendInvites,
    AutoActivation
}

export function checkIfADUsersSyncEnabled(tenant: TenantState) {
    return permissionsContains(tenant.subscription.features, PPMFeatures.AzureADUsersSync) && !!tenant.adUsersSyncSettings?.isADUsersSyncEnabled;
}

export enum ProductType {
    Core = 1,
    Portfolio = 2,
    Product = 3,
    Work = 4,
    Resource = 5,
    Ideation = 6,
    Enterprise = 7
}

export enum PaymentType {
    Card = 1,
    Direct = 2
}

export enum PaymentPeriod {
    Unknown = 0,
    Day = 1,
    Week = 2,
    Month = 3,
    Year = 4,
    Custom = 5
}

export namespace ProductType {
    const _map = {
        [ProductType.Portfolio]: {
            icon: "PPMXPortfolioPlan",
            title: "Portfolio"
        },
        [ProductType.Product]: {
            icon: "PPMXProductManagementPlan",
            title: "Product"
        },
        [ProductType.Core]: {
            icon: "PPMXCorePlan",
            title: "Core"
        },
        [ProductType.Work]: {
            icon: "PPMXCorePlan",
            title: "Project"
        },
        [ProductType.Resource]: {
            icon: 'PPMXResourcePlan',
            title: 'Resource'
        },
        [ProductType.Ideation]: {
            icon: 'PPMXIdeationPlan',
            title: 'Demand and Innovation Management'
        },
        [ProductType.Enterprise]: {
            icon: 'PPMXEnterprisePlan',
            title: 'Enterprise'
        }
    }

    export function getIcoName(productType: ProductType): string | undefined {
        return _map[productType]?.icon;
    }

    export function getTitle(productType: ProductType): string | undefined {
        return _map[productType]?.title;
    }
}

export const showDemo = (productType: ProductType) => productType !== ProductType.Work && productType !== ProductType.Resource;
export const isAzureAdAvailable = (productType: ProductType) => !([ProductType.Work, ProductType.Resource, ProductType.Ideation].includes(productType));

export enum SubscriptionStatus {
    Disabled = 0,
    Active = 1,
    NotPaid = 2
}

export enum PPMIntegrations {
    None = 0,

    Financials = 1,
    TimeTracker = 2,
    VSTS = 4,
    Jira = 8,
    File = 16,
    Spo = 32,
    O365Planner = 64,
    O365User = 128,
    O365Group = 256,
    O365TeamsChannel = 512,
    MondayCom = 1024,
    Smartsheet = 2048,
    MPPFile = 4096,
    P4W = 8192,

    Collaborative = O365Group | O365TeamsChannel,
    O365 = O365Planner | O365User | O365Group | O365TeamsChannel,
    Syncable = VSTS | Jira | Spo | O365Planner | MondayCom | Smartsheet | P4W,
    All = Syncable | O365 | Financials | TimeTracker | File | MPPFile
}

export class Integrations {
    private _integrations: PPMIntegrations;

    constructor(integrations: PPMIntegrations) {
        this._integrations = integrations;
    }

    public has = (required: PPMIntegrations) => {
        return enumContains(this._integrations, required);
    }

    public isDisabled = (sourceType: SourceType) => {
        const integration = this.map[sourceType]
        return !enumContains(this._integrations, integration);
    }

    public isEnabled = (sourceType: SourceType) => {
        const integration = this.map[sourceType];
        return enumContains(this._integrations, integration);
    }

    public getAvailable = (types: SourceType[], sourceInfos?: { type: SourceType }[]) => {
        if (sourceInfos) {
            const linked = sourceInfos.map(_ => _.type);
            return types.filter(_ => this.isEnabled(_) || ~linked.indexOf(_))
        }

        return types.filter(this.isEnabled);
    }

    private map: { [k: number]: PPMIntegrations } = {
        [SourceType.Ppmx]: PPMIntegrations.None,
        [SourceType.O365Group]: PPMIntegrations.O365Group,
        [SourceType.VSTS]: PPMIntegrations.VSTS,
        [SourceType.Jira]: PPMIntegrations.Jira,
        [SourceType.File]: PPMIntegrations.File,
        [SourceType.O365Planner]: PPMIntegrations.O365Planner,
        [SourceType.O365User]: PPMIntegrations.O365User,
        [SourceType.Spo]: PPMIntegrations.Spo,
        [SourceType.MondayCom]: PPMIntegrations.MondayCom,
        [SourceType.Smartsheet]: PPMIntegrations.Smartsheet,
        [SourceType.O365TeamsChannel]: PPMIntegrations.O365TeamsChannel,
        [SourceType.Slack]: PPMIntegrations.None,
        [SourceType.Confluence]: PPMIntegrations.None,
        [SourceType.PpmxTime]: PPMIntegrations.TimeTracker,
        [SourceType.MPPFile]: PPMIntegrations.MPPFile,
        [SourceType.P4W]: PPMIntegrations.P4W
    }
}

export enum PPMFeatures {
    None = 0x0,
    Insights = 0x1,
    Prioritization = 0x2,
    Ideation = 0x4,
    EmbeddedPowerBI = 0x8,
    PerpetualSync = 0x10,
    ML = 0x20,
    ResourceManagement = 0x40,
    OKR = 0x80,
    Roadmap = 0x100,
    API = 0x200,
    IntegrationAPI = 0x400,
    AzureADUsersSync = 0x800,
    ArchiveProjects = 0x1000,
    PortfolioManagement = 0x2000,
    AiInsights = 0x4000,
    PartnersSupport = 0x8000,
    ProjectManagement = 0x10000,
    TimeTracking = 0x20000,
    TimeTrackingEnterprise = 0x40000 | TimeTracking,
    PrivateProjects = 0x80000
}

export namespace PPMFeatures {
    const _titleMap = {
        [PPMFeatures.Roadmap]: "Roadmaps",
        [PPMFeatures.Insights]: "PPM Insights AI",
        [PPMFeatures.ML]: "Status Prediction AI",
        [PPMFeatures.ProjectManagement]: "Project Management",
        [PPMFeatures.PortfolioManagement]: "Portfolio Management",
    }

    export function getTitle(feature: PPMFeatures) {
        return _titleMap[feature] || PPMFeatures[feature];
    }

    export function getFeaturesNumbers(): number[] {
        return Object.values(PPMFeatures).filter(x => typeof x === 'number') as number[];
    }
}

export interface Subscription {
    subscriptionId?: string;
    plan: string;
    endDate: string;
    productType: ProductType;
    paymentType: PaymentType;
    paymentPeriod: PaymentPeriod;
    isEnterprise: boolean;
    status: SubscriptionStatus;
    dataSyncCountLimit: number;
    gracePeriodDuration: number;
    isTrial: boolean;
    allFeatures: PPMFeatures;
    features: PPMFeatures;
    integrations: PPMIntegrations;
    viewersLimit: number;
    helpUrl?: string;
}

export namespace Subscription {
    const MAX_SUBSCRIPTION_PROCESSING_DURATION_DAYS = 1;
    const PORTFOLIO_MANAGEMENT_ENTITY_TYPES = [EntityType.Portfolio, EntityType.Program];

    export function isDirect(subscription: { paymentType: PaymentType }) {
        return subscription.paymentType === PaymentType.Direct;
    }

    export function isExpired(subscription: { endDate?: string }): boolean {
        const endDate = getEndDate(subscription);
        return !!endDate && endDate.addDays(MAX_SUBSCRIPTION_PROCESSING_DURATION_DAYS) < new Date();
    }

    export function contains(subscription: { features: PPMFeatures }, features: PPMFeatures): boolean {
        return subscription && enumContains(subscription.features, features);
    }

    export function containsAny(subscription: { features: PPMFeatures }, features: PPMFeatures): boolean {
        return subscription && enumContainsAny(subscription.features, features);
    }

    export function hasIntegration(subscription: { integrations: PPMIntegrations }, integration: PPMIntegrations): boolean {
        return subscription && enumContains(subscription.integrations, integration);
    }

    export function hasSyncableIntegration(subscription: { integrations: PPMIntegrations }): boolean {
        return subscription && (subscription.integrations & PPMIntegrations.Syncable) !== 0;
    }

    function getEndDate(subscription: { endDate?: string }): Date | undefined {
        return subscription.endDate ? toDateTime(subscription.endDate) : undefined;
    }

    export function canViewInsights(subscription: { features: PPMFeatures }, user: UserState): boolean {
        return Subscription.contains(subscription, PPMFeatures.Insights)
            && enumContains(user.permissions.common, CommonOperations.InsightsView);
    }

    export function canViewOKR(subscription: { features: PPMFeatures }, user: UserState): boolean {
        return Subscription.contains(subscription, PPMFeatures.OKR)
            && permissionsContains(user.permissions.objective.global, ResourceOperations.Read);
    }

    export function getCreateEntityTypes(subscription: { features: PPMFeatures, isEnterprise: boolean }, settings: DefaultProjectSettings | undefined, entityTypes: EntityType[]) {
        if (!Subscription.contains(subscription, PPMFeatures.PortfolioManagement)) {
            entityTypes = entityTypes.filter(_ => !PORTFOLIO_MANAGEMENT_ENTITY_TYPES.includes(_));
        }

        if (!Subscription.contains(subscription, PPMFeatures.ProjectManagement)) {
            entityTypes = entityTypes.filter(_ => _ !== EntityType.Project);
        }

        if (!Subscription.contains(subscription, PPMFeatures.PrivateProjects)) {
            entityTypes = entityTypes.filter(_ => _ !== EntityType.PrivateProject);
        }

        return entityTypes
    }

    export function canODataView(user: UserState): boolean {
        return enumContains(user.permissions.common, CommonOperations.ODataView);
    }
}

export type IsRouteAvailable = (subscription: Subscription, user: UserState, settings: RouteAvailabilitySettings) => boolean;

export type RouteAvailabilitySettings = {
    newTimeTrackingEnabled: boolean;
}

export const ToRouteAvailabilitySettings = (tenant: TenantState): RouteAvailabilitySettings => {
    return {
        newTimeTrackingEnabled: tenant.timeTracking?.globalSettings.newTimeTrackingEnabled || false
    }
}

interface RoutesAvailabilityMap {
    [name: string]: IsRouteAvailable;
}

const createAvailabilityMap = <M extends RoutesAvailabilityMap>(things: M) => things

export const RoutesAvailability = createAvailabilityMap({
    Automation: (subscription, user) => Subscription.containsAny(subscription, PPMFeatures.API | PPMFeatures.IntegrationAPI) && user.license === LicenseType.Regular,
    ArchivedProjects: (subscription, _) => Subscription.contains(subscription, PPMFeatures.ArchiveProjects),
    Billing: (subscription, _) => !!subscription?.subscriptionId,
    StatusSettings: (subscription, user) => Subscription.containsAny(subscription, PPMFeatures.AiInsights | PPMFeatures.PortfolioManagement | PPMFeatures.ProjectManagement | PPMFeatures.OKR)
        && enumContains(user.permissions.common, CommonOperations.AdministrateView),
    TenantSettings: (_, user) => enumContains(user.permissions.common, CommonOperations.ScheduleView),
    Users: (_, user) => enumContains(user.permissions.common, CommonOperations.UserView),
    Challenges: (subscription, _) => Subscription.contains(subscription, PPMFeatures.Ideation),
    Priorities: (subscription, _) => Subscription.contains(subscription, PPMFeatures.Prioritization),
    Objectives: Subscription.canViewOKR,
    Roadmaps: (subscription, _) => Subscription.contains(subscription, PPMFeatures.Roadmap),
    Portfolios: (subscription, _) => Subscription.contains(subscription, PPMFeatures.PortfolioManagement),
    Programs: (subscription, _) => Subscription.contains(subscription, PPMFeatures.PortfolioManagement),
    Insights: Subscription.canViewInsights,
    ResourcePlan: (subscription, _) => Subscription.contains(subscription, PPMFeatures.ResourceManagement) 
        && Subscription.containsAny(subscription, PPMFeatures.PortfolioManagement | PPMFeatures.ProjectManagement),
    Projects: (subscription, _) => Subscription.contains(subscription, PPMFeatures.ProjectManagement),
    MySpace: (subscription, _) => Subscription.containsAny(subscription, PPMFeatures.PortfolioManagement | PPMFeatures.ProjectManagement),
    TimeTrackingtSettings: (subscription, user, settings) => settings.newTimeTrackingEnabled
            && enumContains(user.permissions.common, CommonOperations.TimeTrackingView )
            && Subscription.contains(subscription, PPMFeatures.TimeTracking),
    MyTime: (subscription, user, settings) => settings.newTimeTrackingEnabled
        && Subscription.contains(subscription, PPMFeatures.TimeTracking),
    ReportedTime: (subscription, user, settings) => settings.newTimeTrackingEnabled
        && Subscription.contains(subscription, PPMFeatures.TimeTracking),
})

export interface Insights {
    projectWarningsCalculationDisabled: boolean;
    programWarningsCalculationDisabled: boolean;
    portfolioWarningsCalculationDisabled: boolean;
    keyDate: CalculationSettings<GenericCalculationThresholds>;
    task: CalculationSettings<GenericCalculationThresholds>;
    project: CalculationSettings<ProjectStatusCalculationThresholds>;
    program: CalculationSettings<PortfolioOrProgramStatusCalculationThresholds>;
    portfolio: CalculationSettings<PortfolioOrProgramStatusCalculationThresholds>;
    keyResult: CalculationSettings<GenericCalculationThresholds>;
}

export interface AiInsightsSettings {
    aiEnabled: boolean;
    customApiKey?: string;
    modelType?: AiModelType;
    temperature?: number;
    customPrompt?: string;
}

export enum AiModelType {
    GPT_4o_mini = 0,
    GPT_4o = 1 
}

export interface ICalculationSettings {
    statusCalculation: StatusCalculationTypes;
    manualDescription?: boolean;
}

export interface CalculationSettings<T> extends ICalculationSettings {
    thresholds: T;
}

export interface GenericCalculationThresholds {
    minMax: MinMax<number | null>;
}
export interface ProjectStatusCalculationThresholds {
    lateTasks: MinMax<number | null>;
    lateKeyDates: MinMax<number | null>;
    resourcesWithLateTasks: MinMax<number | null>;
    overdueIssues: MinMax<number | null>;
    overdueRisks: MinMax<number | null>;
    costOverrun: MinMax<number | null>;
    effortsOverrun: MinMax<number | null>;
    durationOverrun: MinMax<number | null>;
}

export interface PortfolioOrProgramStatusCalculationThresholds {
    costOverrun: MinMax<number | null>;
    lateKeyDates: MinMax<number | null>;
}

export type MinMax<T> = { min: T, max: T }

export interface IReportingInfo {
    odataUrl: string;
    sampleUrl?: string;
    error?: string;
    portfolioReports: ReportsNav;
    portfoliosReports: ReportsNav;
    programReports: ReportsNav;
    programsReports: ReportsNav;
    projectReports: ReportsNav;
    projectsReports: ReportsNav;
    resourceReports: ReportsNav;
    resourcesReports: ReportsNav;
    challengesReports: ReportsNav;
    objectivesReports: ReportsNav;
    pbixReports?: IPbixReports[]
}

interface IPbixReports {
    name: string;
    version: string;
}

export interface SecuritySettings {
    isAutoRegistrationDisabled: boolean;
    allowAutoRegistration: boolean;
    allowEveryoneToInviteUsers: boolean;
    allowedAuthProviders: AuthProvider[];
    defaultAuthProvider: AuthProvider;
}

export enum ThemeType {
    Dark,
    Light,
    Solid,
}

export interface IDefaultPermissions<T> {
    regular: T;
    viewer: T;
}

export interface TimeTrackingSettings {
    globalSettings: TimeTrackingGlobalSettings;
    administrativeCategories: TimeTrackingAdministrativeCategory[];
}

export interface TimeTrackingGlobalSettings {
    newTimeTrackingEnabled: boolean;

    timeReportingLock: MaybeDate;

    calculateCompletedWorkBasedOnReportedTime: boolean;
    calculateResourcePlanActualsBasedOnReportedTime: boolean;

    minReportingDurationMinutes: number;
    reportingIncrementMinutes: number;

    costTypeSource: ServerEntityType;
    billableSource: ServerEntityType;

    enableEditingBillable: boolean;
    enableEditingCostType: boolean;

    enabledAssignmentSmartSuggestions: boolean;
    enablaResourcePlanSmartSuggestion: boolean;
    enableO365Connector: boolean;

    enableReportingOnCustomTimeTrackingEntries: boolean;
    enableReportingOnNotAssignedTasks: boolean;

    enableExternalSuggestions: boolean;
}

interface TimeTrackingAdministrativeCategoryBase {
    title: string;
    description: string;
    isEnabled: boolean;
    color: string;
    type: TimeTrackingAdministrativeCategoryType
}

export enum TimeTrackingAdministrativeCategoryType {
    NonProjectTime,
    NotWorkingTime
}

export const TimeTrackingAdministrativeCategoryTypeLabelsMap = {
    [TimeTrackingAdministrativeCategoryType.NonProjectTime]: "Non-project Time",
    [TimeTrackingAdministrativeCategoryType.NotWorkingTime]: "Non-working Time"
};

export interface TimeTrackingAdministrativeCategory extends TimeTrackingAdministrativeCategoryBase {
    id: string;
}

export interface TimeTrackingAdministrativeCategorySave extends TimeTrackingAdministrativeCategoryBase {
    id?: string;
}

export function getDefaultPermissions<T>(license: LicenseType, defaultPermissions?: IDefaultPermissions<T>) {
    return license === LicenseType.Regular
        ? defaultPermissions?.regular
        : license === LicenseType.Viewer
            ? defaultPermissions?.viewer
            : undefined;
}

export interface TenantState {
    region: string;
    id: string;
    externalId: string;
    externalAuthProvider?: AuthProvider;
    companyName: string;
    isReadOnly: boolean;
    isDemoDataApplied: boolean;
    name: string;
    url: string,
    styleSettings: StyleSettings;
    globalSettings?: GlobalSettings;
    resourcePlanningSettings?: ResourcePlanningSettings;
    syncSettings?: SyncSettings;
    healthReportingSettings?: NotificationSchedule;
    adSyncSettings?: AzureADSyncSettings;
    adUsersSyncSettings?: AzureADUsersSyncSettings;
    security?: SecuritySettings;
    reporting: IReportingInfo;
    insights: Insights;
    aiInsights: AiInsightsSettings;
    subscription: Subscription;
    isProcessing: boolean;
    isLoading: boolean;
    defaultPermissions?: IDefaultPermissions<IPermissions>;
    defaultProjectSettings?: DefaultProjectSettings;
    timeTracking: TimeTrackingSettings;
}

export enum ActionTypes {
    REGISTER_TENANT = 'REGISTER_TENANT',
    UPDATE_TENANT = 'UPDATE_TENANT',
    UPDATE_AVAILABLE_TENANTS = 'UPDATE_AVAILABLE_TENANTS',
    UPDATING_TENANT = 'UPDATING_TENANT',
    UPDATE_SCHEDULE = 'UPDATE_SCHEDULE',
    UPDATE_AD_SYNC_SETTINGS = 'UPDATE_AD_SYNC_SETTINGS',
    UPDATE_AD_USERS_SYNC_SETTINGS = 'UPDATE_AD_USERS_SYNC_SETTINGS',
    UPDATE_DEFAULT_PROJECT_SETTINGS = 'UPDATE_DEFAULT_PROJECT_SETTINGS',
    UPDATE_SETTINGS = 'UPDATE_SETTINGS',
    UPDATE_EXPORTIMPORT_SETTINGS = 'UPDATE_EXPORTIMPORT_SETTINGS',
    UPDATE_RESOURCE_PLANNING_SETTINGS = 'UPDATE_RESOURCE_PLANNING_SETTINGS',
    UPDATE_SECURITY = 'UPDATE_SECURITY',
    UPDATE_IS_DEMO_DATA_APPLIED = 'UPDATE_IS_DEMO_DATA_APPLIED',
    UPDATE_SUBSCRIPTION = 'UPDATE_SUBSCRIPTION',
    UPDATE_INSIGHTS = 'UPDATE_INSIGHTS',
    UPDATE_AI_INSIGHTS = 'UPDATE_AI_INSIGHTS',
    RECEIVED_REPORTING_INFO = 'RECEIVED_REPORTING_INFO',
    DEFAULT_PERMISSIONS_LOADED = 'DEFAULT_PERMISSIONS_LOADED',
    BEGIN_LOADING = "TENANT_BEGIN_LOADING",
    LINK_TENANT = "LINK_TENANT",
    UPDATE_LOGO = "UPDATE_LOGO",
    SET_THEME = "SET_THEME",
    UPDATE_TIMETRACKING_GLOBAL_SETTINGS = 'UPDATE_TIMETRACKING_GLOBAL_SETTINGS',
    UPDATE_TIMETRACKING_ADMINISTRATIVE_CATEGORY = 'CREATE_TIMETRACKING_ADMINISTRATIVE_CATEGORY'
}

type RegisterTenantAction = { type: ActionTypes.REGISTER_TENANT }
type UpdateTenantAction = { type: ActionTypes.UPDATE_TENANT, tenant: TenantState }
type LinkTenantAction = { type: ActionTypes.LINK_TENANT, externalId: string, externalAuthProvider: AuthProvider }
type UpdateIsDemoDataApplied = { type: ActionTypes.UPDATE_IS_DEMO_DATA_APPLIED, isDemoDataApplied: true }
type UpdatingTenantAction = { type: ActionTypes.UPDATING_TENANT, isProcessing?: boolean; }
type UpdateSettingsAction = { type: ActionTypes.UPDATE_SETTINGS, globalSettings: GlobalSettings }
type UpdateResourcePlanningSettingsAction = { type: ActionTypes.UPDATE_RESOURCE_PLANNING_SETTINGS, resourcePlanningSettings: ResourcePlanningSettings }
type UpdateScheduleAction = { type: ActionTypes.UPDATE_SCHEDULE, syncSchedule: SyncSchedule, alertDelay: string | null, alertRecipientIds: string[], notificationSchedule: NotificationSchedule }
type UpdateADSyncSettingsAction = { type: ActionTypes.UPDATE_AD_SYNC_SETTINGS, adSyncSettings: AzureADSyncSettings }
type UpdateADUsersSyncSettingsAction = { type: ActionTypes.UPDATE_AD_USERS_SYNC_SETTINGS, adUsersSyncSettings: AzureADUsersSyncSettings }
type UpdateDefaultProjectSettingsAction = { type: ActionTypes.UPDATE_DEFAULT_PROJECT_SETTINGS, defaultProjectSettings: DefaultProjectSettings }
type UpdateSecurityAction = { type: ActionTypes.UPDATE_SECURITY, security: SecuritySettings }
type DefaultPermissionsLoadedAction = { type: ActionTypes.DEFAULT_PERMISSIONS_LOADED; permissions: IDefaultPermissions<IServerPermissions>; }
type UpdateSubscriptionAction = { type: ActionTypes.UPDATE_SUBSCRIPTION, subscription: Subscription }
type UpdateInsightsAction = { type: ActionTypes.UPDATE_INSIGHTS, insights: Insights }
type UpdateAiInsightsAction = { type: ActionTypes.UPDATE_AI_INSIGHTS, aiInsights: AiInsightsSettings }
type BeginLoadingActionAction = { type: ActionTypes.BEGIN_LOADING }
type UpdateLogoAction = { type: ActionTypes.UPDATE_LOGO, logoId: string | undefined }
type UpdateThemeAction = { type: ActionTypes.SET_THEME, themeType: ThemeType }

export type ReceivedReportingInfoAction = { type: ActionTypes.RECEIVED_REPORTING_INFO; info: IReportingInfo; }
type UpdateTimeTrackingGlobalSettingsAction = { type: ActionTypes.UPDATE_TIMETRACKING_GLOBAL_SETTINGS; settings: TimeTrackingGlobalSettings };
type UpdateTimeTrackingAdministrativeCategory = {
    type: ActionTypes.UPDATE_TIMETRACKING_ADMINISTRATIVE_CATEGORY;
    addOrUpdate?: TimeTrackingAdministrativeCategory[];
    remove?: string[];
};

export type TenantActions = RegisterTenantAction
    | UpdateTenantAction
    | UpdatingTenantAction
    | UpdateScheduleAction
    | UpdateADSyncSettingsAction
    | UpdateADUsersSyncSettingsAction
    | UpdateDefaultProjectSettingsAction
    | UpdateSettingsAction
    | UpdateResourcePlanningSettingsAction
    | UpdateSecurityAction
    | DefaultPermissionsLoadedAction
    | UpdateSubscriptionAction
    | UpdateInsightsAction
    | UpdateAiInsightsAction
    | ReceivedReportingInfoAction
    | UpdateIsDemoDataApplied
    | BeginLoadingActionAction
    | LinkTenantAction
    | UpdateLogoAction
    | UpdateThemeAction
    | UpdateTimeTrackingGlobalSettingsAction
    | UpdateTimeTrackingAdministrativeCategory;

export const actionCreators = {
    registerTenant: (tenant: Tenant & { plan: string }): AppThunkAction<TenantActions | UserActions | FinancialsActions | TimeActions | RouterAction> => (dispatch, getState) => {
        const tenantState = getState().tenant;
        if (!(tenantState && tenantState.id)) {
            post<{ tenant: TenantState, user: UserState, financials: FinancialsState, time: TimeState }>(`api/tenant/register`, { ...tenant, url: baseUrl.value.split('@')[1] })
                .then(data => {
                    analytics.identifyAndGroup(data.user, data.tenant);
                    dispatch({ type: ActionTypes.UPDATE_TENANT, tenant: data.tenant });
                    dispatch({ type: UserActionTypes.UPDATE_USER, user: data.user });
                    dispatch({ type: FinancialsActionTypes.UPDATE_FINANCIALS, financials: data.financials });
                    dispatch({ type: TimeActionTypes.UPDATE_TIME, time: data.time });
                    dispatch(showDemo(data.tenant.subscription.productType)
                        ? replace({ pathname: '/setup/demo' })
                        : replace({ pathname: '/quickstart' }));
                    analytics.gtag('event', 'tenant_created', { userSignInSystem: AuthProvidersMap[data.user.signInAuthProvider].friendlyName });
                    analytics.track('Register Tenant', {
                        tenantId: data.tenant.id,
                        tenantName: data.tenant.name,
                        tenantCompanyName: data.tenant.companyName,
                        userSignInSystem: AuthProvidersMap[data.user.signInAuthProvider].friendlyName,
                        plan: tenant.plan
                    });
                })
                .catch(defaultCatch(dispatch));

            dispatch({ type: ActionTypes.REGISTER_TENANT });
        }
    },
    updateSyncSchedule: (syncSchedule: SyncSchedule,
        alertDelay: string | null,
        alertRecipientIds: string[],
        notificationSchedule: NotificationSchedule): AppThunkAction<TenantActions> => (dispatch, getState) => {
            post<{
                syncSchedule: SyncSchedule, alertDelay: string | null, alertRecipientIds: string[],
                notificationSchedule: NotificationSchedule
            }>(`api/schedule/settings`,
                { syncSchedule, alertDelay, alertRecipientIds, notificationSchedule })
                .then(data => {
                    dispatch({
                        type: ActionTypes.UPDATE_SCHEDULE,
                        syncSchedule: data.syncSchedule,
                        alertDelay: data.alertDelay,
                        alertRecipientIds: data.alertRecipientIds,
                        notificationSchedule: data.notificationSchedule
                    });
                })
                .catch(defaultCatch(dispatch));

            dispatch({ type: ActionTypes.UPDATING_TENANT });
        },
    loadADSyncSettings: (): AppThunkAction<TenantActions> => (dispatch, getState) => {
        get<AzureADSyncSettings>(`api/adsync/settings`)
            .then(data => dispatch({ type: ActionTypes.UPDATE_AD_SYNC_SETTINGS, adSyncSettings: data }))
            .catch(defaultCatch(dispatch));
        dispatch({ type: ActionTypes.UPDATING_TENANT });
    },
    updateADSyncSettings: (schedule: AzureADSyncSchedule, mapping: IFieldMapping[], organization: OrganizationInfo): AppThunkAction<TenantActions> => (dispatch, getState) => {
        post<AzureADSyncSettings>(`api/adsync/settings`, { schedule, mapping, organization })
            .then(data => dispatch({ type: ActionTypes.UPDATE_AD_SYNC_SETTINGS, adSyncSettings: data }))
            .catch(defaultCatch(dispatch));
        dispatch({ type: ActionTypes.UPDATING_TENANT });
    },
    runADSync: (): AppThunkAction<TenantActions | Notifications.KnownAction> => (dispatch, getState) => {
        post<GlobalSettings>(`api/adsync/run`, {})
            .catch(defaultCatch(dispatch));
        dispatch(Notifications.actionCreators.pushNotification({ message: "Azure Active Directory synchronization is in progress", type: Notifications.NotificationType.Success }));
    },
    loadADUsersSyncSettings: (): AppThunkAction<TenantActions> => (dispatch, getState) => {
        get<AzureADUsersSyncSettings>(`api/adUsersSync/settings`)
            .then(data => dispatch({ type: ActionTypes.UPDATE_AD_USERS_SYNC_SETTINGS, adUsersSyncSettings: data }))
            .catch(defaultCatch(dispatch));
        dispatch({ type: ActionTypes.UPDATING_TENANT });
    },
    updateADUsersSyncSettings: (schedule: AzureADSyncSchedule, organization: OrganizationInfo, usersGroup: GroupInfo, teamMembersGroup: GroupInfo,
        isADUsersSyncEnabled: boolean, syncType: AdUsersSyncType): AppThunkAction<TenantActions> => (dispatch, getState) => {
            post<AzureADUsersSyncSettings>(`api/adUsersSync/settings`, { schedule, organization, usersGroup, teamMembersGroup, isADUsersSyncEnabled, syncType })
                .then(data => dispatch({ type: ActionTypes.UPDATE_AD_USERS_SYNC_SETTINGS, adUsersSyncSettings: data }))
                .catch(defaultCatch(dispatch));
            dispatch({ type: ActionTypes.UPDATING_TENANT });
        },
    runADUsersSync: (): AppThunkAction<TenantActions | Notifications.KnownAction> => (dispatch, getState) => {
        post<GlobalSettings>(`api/adUsersSync/run`, {})
            .catch(defaultCatch(dispatch));
        dispatch(Notifications.actionCreators.pushNotification({
            message: "Azure Active Directory users synchronization is in progress",
            type: Notifications.NotificationType.Success
        }));
    },
    loadDefaultProjectSettings: (): AppThunkAction<TenantActions> => (dispatch, getState) => {
        get<DefaultProjectSettings>('api/defaultProjectSettings')
            .then(data => dispatch({ type: ActionTypes.UPDATE_DEFAULT_PROJECT_SETTINGS, defaultProjectSettings: data }))
            .catch(defaultCatch(dispatch));
        dispatch({ type: ActionTypes.UPDATING_TENANT });
    },
    updateDefaultProjectSettings: (defaultProjectSettings: DefaultProjectSettings) : AppThunkAction<TenantActions> => (dispatch, getState) => {
        post<DefaultProjectSettings>(`api/defaultProjectSettings`, { ...defaultProjectSettings })
            .then(data => dispatch({ type: ActionTypes.UPDATE_DEFAULT_PROJECT_SETTINGS, defaultProjectSettings: data }))
            .catch(defaultCatch(dispatch));
        dispatch({ type: ActionTypes.UPDATING_TENANT });
    },
    updateGlobalSettings: (timezone?: string, cultureName?: string): AppThunkAction<TenantActions> => (dispatch, getState) => {
        post<GlobalSettings>(`api/global/settings`, { timezone, cultureName })
            .then(data => { dispatch({ type: ActionTypes.UPDATE_SETTINGS, globalSettings: data }); })
            .catch(defaultCatch(dispatch));

        dispatch({ type: ActionTypes.UPDATING_TENANT });
    },
    updateResourcePlanningSettings: (resourcePlanningLevel: EntityType, callback?: () => void): AppThunkAction<TenantActions> => (dispatch, getState) => {
        post<ResourcePlanningSettings>(`api/resourcePlanning/settings`, { resourcePlanningLevel })
            .then(data => {
                dispatch({ type: ActionTypes.UPDATE_RESOURCE_PLANNING_SETTINGS, resourcePlanningSettings: data });
                callback?.();
            })
            .catch(defaultCatch(dispatch));

        dispatch({ type: ActionTypes.UPDATING_TENANT });
    },
    applyDemoData: (): AppThunkAction<TenantActions> => (dispatch, getState) => {
        post<GlobalSettings>(`api/tenant/applyDemoData`, {})
            .then(data => { dispatch({ type: ActionTypes.UPDATE_IS_DEMO_DATA_APPLIED, isDemoDataApplied: true }); })
            .catch(defaultCatch(dispatch));

        dispatch({ type: ActionTypes.UPDATING_TENANT });
    },
    updateSecurity: (security: SecuritySettings): AppThunkAction<TenantActions> => (dispatch, getState) => {
        post<SecuritySettings>(`api/security`, security)
            .then(data => { dispatch({ type: ActionTypes.UPDATE_SECURITY, security: data }); })
            .catch(defaultCatch(dispatch));
    },
    loadDefaultPermissions: (): AppThunkAction<TenantActions> => (dispatch, getState) => {
        dispatch({ type: ActionTypes.BEGIN_LOADING });

        get<IDefaultPermissions<IServerPermissions>>('api/security/permissions')
            .then(_ => dispatch({ type: ActionTypes.DEFAULT_PERMISSIONS_LOADED, permissions: _ }))
            .catch(defaultCatch(dispatch));
    },
    updateDefaultPermissions: (license: LicenseType, permissions: IPermissions): AppThunkAction<TenantActions> => (dispatch, getState) => {
        post<IDefaultPermissions<IServerPermissions>>('api/security/permissions', { license, permissions: toServerPermissions(permissions) })
            .then(_ => dispatch({ type: ActionTypes.DEFAULT_PERMISSIONS_LOADED, permissions: _ }))
            .catch(defaultCatch(dispatch));
        dispatch({ type: ActionTypes.UPDATING_TENANT });
    },
    updateSubscription: (subscription: Subscription): AppThunkAction<TenantActions> => (dispatch, getState) => {
        dispatch({ type: ActionTypes.UPDATE_SUBSCRIPTION, subscription: subscription });
    },
    updateInsights: (insights: Insights): AppThunkAction<TenantActions> => (dispatch, getState) => {
        post<Insights>(`api/insights/settings`, insights)
            .then(data => {
                dispatch({ type: ActionTypes.UPDATE_INSIGHTS, insights: data });
            })
            .catch(defaultCatch(dispatch));

        dispatch({ type: ActionTypes.UPDATING_TENANT });
    },
    linkToO365Organization: (connectionId: string): AppThunkAction<TenantActions | Notifications.KnownAction> => (dispatch, getState) => {
        post<{ externalId: string, externalAuthProvider: AuthProvider }>(`api/tenant/linkToO365Organi=zation`, { connectionId })
            .then(data => { dispatch({ type: ActionTypes.LINK_TENANT, ...data }); })
            .catch(catchApiError(_ => {
                dispatch(Notifications.actionCreators
                    .pushNotification({ message: `Failed to link. ${_ ? _ : ""}`, type: Notifications.NotificationType.Error }))
            }));

        dispatch({ type: ActionTypes.UPDATING_TENANT });
    },
    updateAiInsightsSettings: (aiInsights: AiInsightsSettings, callback: () => void): AppThunkAction<TenantActions> => (dispatch, getState) => {
        post<AiInsightsSettings>(`api/ai/insights/settings`, aiInsights)
            .then(data => {
                dispatch({ type: ActionTypes.UPDATE_AI_INSIGHTS, aiInsights: data });
                callback();
            })
            .catch(defaultCatch(dispatch));

        dispatch({ type: ActionTypes.UPDATING_TENANT });
    },
    validateAiInsightsSettings: (aiInsights: AiInsightsSettings, callback: (error: string) => void): AppThunkAction<TenantActions> => (dispatch, getState) => {
        post<any>(`api/ai/insights/validate`, aiInsights)
            .then(data => {
                dispatch({ type: ActionTypes.UPDATING_TENANT, isProcessing: false });
                callback(data.errorMessage)
            })
            .catch(defaultCatch(dispatch));

        dispatch({ type: ActionTypes.UPDATING_TENANT });
    },
    uploadLogo: (logo: File): AppThunkAction<TenantActions> => (dispatch, getState) => {
        const data = new FormData();
        data.set('image', logo);

        post<{ imageId: string }>(`api/style/logo`, data)
            .then(_ => dispatch({ type: ActionTypes.UPDATE_LOGO, logoId: _.imageId }))
            .catch(defaultCatch(dispatch));
    },
    removeLogo: (): AppThunkAction<TenantActions> => (dispatch, getState) => {
        remove<void>(`api/style/logo`)
            .then(_ => dispatch({ type: ActionTypes.UPDATE_LOGO, logoId: undefined }))
            .catch(defaultCatch(dispatch));
    },
    changeTheme: (themeType: ThemeType): AppThunkAction<TenantActions> => (dispatch, getState) => {
        post<StyleSettings>(`api/style/theme`, { themeType })
            .then(_ => dispatch({ type: ActionTypes.SET_THEME, themeType: _.themeType }))
            .catch(defaultCatch(dispatch));
    },
    createOrUpdateTimeTrackingAdministrativeCategory:
        (
            category: TimeTrackingAdministrativeCategorySave
        ): AppThunkAction<TenantActions> =>
            (dispatch, getState) => {
                post<any>(`api/timetracking/administrative`, category)
                    .then(data => {
                        dispatch({
                            type: ActionTypes.UPDATE_TIMETRACKING_ADMINISTRATIVE_CATEGORY,
                            addOrUpdate: [data]
                        });
                    })
                    .catch(defaultCatch(dispatch));

                dispatch({ type: ActionTypes.UPDATING_TENANT });
            },
    deleteTimeTrackingAdministrativeCategory:
        (categoryId: string): AppThunkAction<TenantActions | Notifications.KnownAction> =>
            (dispatch, getState) => {
                remove<any>(`api/timetracking/administrative/${categoryId}`)
                    .then(data => {
                        dispatch({
                            type: ActionTypes.UPDATE_TIMETRACKING_ADMINISTRATIVE_CATEGORY,
                            remove: [categoryId]
                        });
                    })
                    .catch(catchApiError(_ => {
                        dispatch(Notifications.actionCreators
                            .pushNotification({ message: _, type: Notifications.NotificationType.Error }))
                    }));

                dispatch({ type: ActionTypes.UPDATING_TENANT });
            },
    updateTimeTrackingSettings:
        (settings: Partial<TimeTrackingGlobalSettings>, callback?: () => void): AppThunkAction<TenantActions | Notifications.KnownAction> =>
            (dispatch, getState) => {
                post<TimeTrackingGlobalSettings>(`api/timetracking/settings`, settings)
                    .then(data => {
                        dispatch({
                            type: ActionTypes.UPDATE_TIMETRACKING_GLOBAL_SETTINGS,
                            settings: data
                        });

                        callback?.();
                    })
                    .catch(catchApiError(_ => {
                        dispatch(Notifications.actionCreators
                            .pushNotification({ message: _, type: Notifications.NotificationType.Error }))
                    }));
    
                dispatch({ type: ActionTypes.UPDATING_TENANT });
            },
    runTaskCompletedWorkRecalculation: (): AppThunkAction<TenantActions | Notifications.KnownAction> => (dispatch, getState) => {
        post<GlobalSettings>(`/api/timetracking/sync/completed-work`, {})
            .catch(defaultCatch(dispatch));
        dispatch(Notifications.actionCreators.pushNotification({
            message: "Tasks Completed Work recalculation is in progress",
            type: Notifications.NotificationType.Success
        }));
    },
    updateFeatures: (features: PPMFeatures): AppThunkAction<TenantActions> => 
        (dispatch, getState) => {
            post<{features: PPMFeatures}>(`api/tenant/features`, {features: features})
                .then(data =>{
                    const subscription = getState().tenant.subscription;
                    dispatch({ type: ActionTypes.UPDATE_SUBSCRIPTION, subscription: {...subscription, features: data.features } })
                    dispatch({ type: ActionTypes.UPDATING_TENANT, isProcessing: false });
                })
                .catch(defaultCatch(dispatch));
                
            dispatch({ type: ActionTypes.UPDATING_TENANT });
        },
    runResourcePlanActualsRecalculation: (): AppThunkAction<TenantActions | Notifications.KnownAction> => (dispatch, getState) => {
        post<GlobalSettings>(`/api/timetracking/sync/resource-plan`, {})
            .catch(defaultCatch(dispatch));
        dispatch(Notifications.actionCreators.pushNotification({
            message: "Resource Plan Actuals recalculation is in progress",
            type: Notifications.NotificationType.Success
        }));
    },
};

const initState: TenantState = {
    region: "",
    id: "",
    url: "",
    isReadOnly: false,
    isDemoDataApplied: false,
    name: "",
    externalId: "",
    companyName: "",
    isProcessing: false,
    isLoading: false,
    reporting: {
        odataUrl: "",
        portfolioReports: { packs: [], subPacks: [] },
        portfoliosReports: { packs: [], subPacks: [] },
        programReports: { packs: [], subPacks: [] },
        programsReports: { packs: [], subPacks: [] },
        projectReports: { packs: [], subPacks: [] },
        projectsReports: { packs: [], subPacks: [] },
        resourceReports: { packs: [], subPacks: [] },
        resourcesReports: { packs: [], subPacks: [] },
        challengesReports: { packs: [], subPacks: [] },
        objectivesReports: { packs: [], subPacks: [] }
    },
    insights: {
        portfolioWarningsCalculationDisabled: false,
        projectWarningsCalculationDisabled: false,
        programWarningsCalculationDisabled: false,
        keyDate: {
            statusCalculation: StatusCalculationTypes.Auto,
            thresholds: { minMax: { min: null, max: null } }
        },
        task: {
            statusCalculation: StatusCalculationTypes.Auto,
            thresholds: { minMax: { min: null, max: null } }
        },
        keyResult: {
            statusCalculation: StatusCalculationTypes.Auto,
            thresholds: { minMax: { min: null, max: null } }
        },
        project: {
            statusCalculation: StatusCalculationTypes.AutoManual,
            thresholds: {
                costOverrun: { min: null, max: null },
                lateKeyDates: { min: null, max: null },
                lateTasks: { min: null, max: null },
                overdueIssues: { min: null, max: null },
                overdueRisks: { min: null, max: null },
                resourcesWithLateTasks: { min: null, max: null },
                effortsOverrun: { min: null, max: null },
                durationOverrun: { min: null, max: null }
            }
        },
        portfolio: {
            statusCalculation: StatusCalculationTypes.AutoManual,
            thresholds: {
                costOverrun: { min: null, max: null },
                lateKeyDates: { min: null, max: null }
            }
        },
        program: {
            statusCalculation: StatusCalculationTypes.AutoManual,
            thresholds: {
                costOverrun: { min: null, max: null },
                lateKeyDates: { min: null, max: null }
            }
        }
    },
    aiInsights: {
        aiEnabled: false
    },
    subscription: {
        endDate: "",
        productType: ProductType.Core,
        paymentType: PaymentType.Card,
        paymentPeriod: PaymentPeriod.Month,
        isEnterprise: false,
        status: SubscriptionStatus.Disabled,
        dataSyncCountLimit: 0,
        plan: "",
        gracePeriodDuration: 0,
        allFeatures: PPMFeatures.None,
        features: PPMFeatures.None,
        integrations: PPMIntegrations.None,
        isTrial: false,
        viewersLimit: 0
    },
    styleSettings: {
        logoId: undefined,
        themeType: ThemeType.Dark,
    },
    timeTracking: {
        administrativeCategories: [],
        globalSettings: {
            minReportingDurationMinutes: 15,
            reportingIncrementMinutes: 5,
            newTimeTrackingEnabled: true,
            calculateCompletedWorkBasedOnReportedTime: false,
            calculateResourcePlanActualsBasedOnReportedTime: false,
            timeReportingLock: null,
            costTypeSource: ServerEntityType.Task,
            billableSource: ServerEntityType.Task,
            enableEditingBillable: true,
            enableEditingCostType: true,
            enabledAssignmentSmartSuggestions: true,
            enablaResourcePlanSmartSuggestion: false,
            enableO365Connector: true,
            enableReportingOnCustomTimeTrackingEntries: true,
            enableReportingOnNotAssignedTasks: true,
            enableExternalSuggestions: false
        }
    }
}

export const reducer: Reducer<TenantState> = (state: TenantState = initState, incomingAction: Action) => {
    const action = incomingAction as TenantActions;
    switch (action.type) {
        case ActionTypes.REGISTER_TENANT: return {
            ...state,
            isProcessing: true
        }
        case ActionTypes.UPDATE_TENANT: return {
            region: action.tenant.region,
            id: action.tenant.id,
            isReadOnly: action.tenant.isReadOnly,
            isDemoDataApplied: action.tenant.isDemoDataApplied,
            name: action.tenant.name,
            url: action.tenant.url,
            externalId: action.tenant.externalId,
            externalAuthProvider: action.tenant.externalAuthProvider,
            companyName: action.tenant.companyName,
            styleSettings: action.tenant.styleSettings,
            globalSettings: action.tenant.globalSettings,
            syncSettings: action.tenant.syncSettings,
            adSyncSettings: action.tenant.adSyncSettings,
            adUsersSyncSettings: action.tenant.adUsersSyncSettings,
            defaultProjectSettings: action.tenant.defaultProjectSettings,
            healthReportingSettings: action.tenant.healthReportingSettings,
            resourcePlanningSettings: action.tenant.resourcePlanningSettings,
            subscription: action.tenant.subscription,
            security: action.tenant.security,
            reporting: action.tenant.reporting,
            insights: action.tenant.insights,
            aiInsights: action.tenant.aiInsights,
            timeTracking: action.tenant.timeTracking,
            isLoading: false,
            isProcessing: false
        }
        case ActionTypes.UPDATING_TENANT: return {
            ...state,
            isProcessing: action.isProcessing ?? true
        }
        case ActionTypes.LINK_TENANT: return {
            ...state,
            isProcessing: false,
            externalId: action.externalId,
            externalAuthProvider: action.externalAuthProvider
        }
        case ActionTypes.UPDATE_SCHEDULE: return {
            ...state,
            isProcessing: false,
            syncSettings: {
                schedule: action.syncSchedule,
                alertDelay: action.alertDelay,
                alertRecipientIds: action.alertRecipientIds
            },
            healthReportingSettings: action.notificationSchedule
        }
        case ActionTypes.UPDATE_AD_SYNC_SETTINGS: return {
            ...state,
            isProcessing: false,
            adSyncSettings: {
                lastSyncErrorMessage: action.adSyncSettings.lastSyncErrorMessage,
                lastSyncTime: action.adSyncSettings.lastSyncTime,
                schedule: action.adSyncSettings.schedule,
                organization: action.adSyncSettings.organization,
                mapping: action.adSyncSettings.mapping
            },
        }
        case ActionTypes.UPDATE_AD_USERS_SYNC_SETTINGS: return {
            ...state,
            isProcessing: false,
            adUsersSyncSettings: {
                isADUsersSyncEnabled: action.adUsersSyncSettings.isADUsersSyncEnabled,
                lastSyncErrorMessage: action.adUsersSyncSettings.lastSyncErrorMessage,
                lastSyncTime: action.adUsersSyncSettings.lastSyncTime,
                schedule: action.adUsersSyncSettings.schedule,
                organization: action.adUsersSyncSettings.organization,
                usersGroup: action.adUsersSyncSettings.usersGroup,
                teamMembersGroup: action.adUsersSyncSettings.teamMembersGroup,
                syncType: action.adUsersSyncSettings.syncType
            },
        }
        case ActionTypes.UPDATE_DEFAULT_PROJECT_SETTINGS: return {
            ...state,
            isProcessing: false,
            defaultProjectSettings: {
                progressCalculationType: action.defaultProjectSettings.progressCalculationType
            }
        }
        case ActionTypes.UPDATE_SECURITY: return {
            ...state,
            isProcessing: false,
            security: action.security
        }
        case ActionTypes.UPDATE_IS_DEMO_DATA_APPLIED: return {
            ...state,
            isProcessing: false,
            isDemoDataApplied: action.isDemoDataApplied
        }
        case ActionTypes.UPDATE_SETTINGS:
            {
                cultureInfo.name = action.globalSettings.cultureName;
                return {
                    ...state,
                    isProcessing: false,
                    globalSettings: action.globalSettings
                }
        }
        case ActionTypes.UPDATE_RESOURCE_PLANNING_SETTINGS: return {
            ...state,
            isProcessing: false,
            resourcePlanningSettings: action.resourcePlanningSettings
        }
        case ActionTypes.UPDATE_SUBSCRIPTION: return {
            ...state,
            subscription: action.subscription
        }
        case ActionTypes.RECEIVED_REPORTING_INFO: return {
            ...state,
            reporting: action.info
        }
        case ActionTypes.UPDATE_INSIGHTS: return {
            ...state,
            isProcessing: false,
            insights: action.insights
        }
        case ActionTypes.UPDATE_AI_INSIGHTS: return {
            ...state,
            isProcessing: false,
            aiInsights: action.aiInsights
        }
        case ActionTypes.BEGIN_LOADING: return {
            ...state,
            isLoading: true
        }
        case ActionTypes.DEFAULT_PERMISSIONS_LOADED: return {
            ...state,
            isLoading: false,
            isProcessing: false,
            defaultPermissions: {
                regular: toPermissions(action.permissions.regular),
                viewer: toPermissions(action.permissions.viewer)
            }
        }
        case ActionTypes.UPDATE_LOGO: return {
            ...state,
            styleSettings: {
                ...state.styleSettings,
                logoId: action.logoId,
            }
        }
        case ActionTypes.SET_THEME: return {
            ...state,
            styleSettings: {
                ...state.styleSettings,
                themeType: action.themeType,
            }
        }
        case ActionTypes.UPDATE_TIMETRACKING_GLOBAL_SETTINGS:

            return {
                ...state,
                isLoading: false,
                isProcessing: false,
                timeTracking: {
                    ...state.timeTracking,
                    globalSettings: action.settings
                } 
            };
        case ActionTypes.UPDATE_TIMETRACKING_ADMINISTRATIVE_CATEGORY:
            return {
                ...state,
                isLoading: false,
                isProcessing: false,
                timeTracking: {
                    ...state.timeTracking!,
                    administrativeCategories: addOrUpdateOrRemove(
                        state.timeTracking!.administrativeCategories,
                        action.addOrUpdate,
                        action.remove
                    )
                }
            };
        default:
            const exhaustiveCheck: never = action;
    }

    return state;
};