import "./QuickStart.css";
import * as React from 'react';
import LetsStart from "../LetsStart";
import MoreResources from "../MoreResources";
import RequestDemo from "./RequestDemo";
import ContactUs from "./ContactUs";
import HelpGuide from "./HelpGuide";
import WhatsNew from "../WhatsNew";
import VideoBox from "../VideoBox";
import AiInsights from "../AiInsights";
import { ProductType } from "../../../store/Tenant";

type Props = {
    isTrial: boolean;
    hasPartnersSupport: boolean;
    hasAiInsights: boolean;
    productType: ProductType;
}

const QuickStartTeamMember = (props: Props) => {

    const _renderVideo = () => {
        switch (props.productType) {
            case ProductType.Enterprise:
                return <>
                    <VideoBox text="PPM Express Overview" href="https://vimeo.com/685714660" />
                    <VideoBox text="Overview for Team Members" href="https://vimeo.com/1027302351" />
                    <VideoBox text="PPM Express Time Tracking" href="https://vimeo.com/991511091" />
                </>
            case ProductType.Product:
            case ProductType.Portfolio:
                return <>
                    <VideoBox text="PPM Express Overview" href="https://vimeo.com/685714660" />
                    <VideoBox text="Overview for Team Members" href="https://vimeo.com/1027302351" />
                    <VideoBox text="PPM Express Task Management" href="https://vimeo.com/991507302" />
                </>
            default:
                return <>
                    <VideoBox text="PPM Express Overview" href="https://vimeo.com/991507263" />
                    <VideoBox text="Overview for Team Members" href="https://vimeo.com/1027302351" />
                    <VideoBox text="PPM Express Time Tracking" href="https://vimeo.com/991511091" />
                </>
        }
    }

    return <>
        {props.hasAiInsights && <div className="row"><AiInsights /></div>}
        <div className="row">
            <div className="column double">
                <div className="column">
                    <LetsStart />
                </div>
            </div>
            <div className="row-double">
                {props.hasPartnersSupport
                    ? undefined
                    : props.isTrial
                        ? <RequestDemo />
                        : <ContactUs />}
                <HelpGuide />
            </div>
        </div>
        <div className="row withVideo">
            {_renderVideo()}
        </div>
        <div className="row">
            <div className="column double">
                <div className="column">
                    <MoreResources />
                </div>
            </div>
            <WhatsNew />
        </div>
    </>;
}

export default QuickStartTeamMember;
