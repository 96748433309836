import { CalendarDataSet, splitToDays } from "../../../store/CalendarStore";
import { toDate } from "../../../components/utils/common";
import { DayOfWeek } from "office-ui-fabric-react";
import { MaybeDate, SortDirection } from "../../../entities/common";
declare global {
    interface Document {
        documentMode?: any;
    }
    interface Date {
        addDays(days: number): Date;
        addWorkingDays(days: number, calendarSettings: CalendarDataSet): Date;
        isWorkingDay(calendarSettings: CalendarDataSet): boolean;
        getWorkingHours(calendarSettings: CalendarDataSet): number;
        trimHours(): Date;
        clone(): Date;
        getBeginOfDay(): Date;
        getEndOfDay(): Date;
        getWeekStartDate(): Date;
        getWeekAgo(): Date;
        getThisWeek(): { start: Date, end: Date };
        getNextWeek(): { start: Date, end: Date };
        getThisMonth(): { start: Date, end: Date };
        getNextMonth(): { start: Date, end: Date };
        getMonthStartDate(): Date;
        getMonthEndDate(): Date;
        getWeekAgo(): Date;
        toDateOnlyString(): string;
    }
    interface String {
        htmlEncode(): string;
        toDateFromUTC(): Date | null;
        getRenderWidth(font: string): number;
        format(...args: string[]): string;
    }
}

const MILLISECONDS_PER_DAY = 1000 * 60 * 60 * 24;

String.prototype.toDateFromUTC = function () {
    const pattern = new RegExp("(\\d{4})-(\\d{2})-(\\d{2})T(\\d{2}):(\\d{2}):(\\d{2})");
    const parts = this.match(pattern);
    if (!parts) {
        return null;
    }

    return new Date(Date.UTC(
        parseInt(parts[1]), parseInt(parts[2], 10) - 1, parseInt(parts[3], 10), parseInt(parts[4], 10), parseInt(parts[5], 10), parseInt(parts[6], 10), 0
    ));
};

let canvas: HTMLCanvasElement;
String.prototype.getRenderWidth = function (font: string) {
    if (!canvas) {
        canvas = document.createElement("canvas");
    }
    const context = canvas.getContext("2d");
    context!.font = font;
    const metrics = context!.measureText(`${this}`);
    return metrics.width;
};

String.prototype.format = function (...args: string[]) {
    return this.replace(/{([0-9]+)}/g, function (match, index) {
        return typeof args[index] == 'undefined' ? match : args[index];
    });
};

Date.prototype.toDateOnlyString = function (): string {
    return formatDate("yy-mm-dd", this);
};

Date.prototype.addDays = function (days: number): Date {
    this.setDate(this.getDate() + days);
    const dayPart = days % 1;
    if (dayPart > 0) {
        this.setTime(this.getTime() + MILLISECONDS_PER_DAY * dayPart);
    }
    return this;
};

//similar method is in DateTimeExtensions on the backend
Date.prototype.isWorkingDay = function (calendar: CalendarDataSet): boolean {
    return this.getWorkingHours(calendar) > 0;
};

//similar method is in DateTimeExtensions on the backend
Date.prototype.getWorkingHours = function (calendar: CalendarDataSet): number {
    return calendar.exceptionsHoursMap[this.getBeginOfDay().getTime()] ?? calendar.workDayExpectedHrs[this.getDay()];
};

//similar method is in DateTimeExtensions on the backend
Date.prototype.addWorkingDays = function (days: number, calendar: CalendarDataSet): Date {
    const dateTime = this.clone();
    days = days > 0 ? Math.ceil(days) : Math.floor(days);

    if (days === 0) {
        return this;
    }

    const calendarDaysPerWeek = 7;
    const workDaysPerWeek = Object.keys(calendar.workDayExpectedHrs).filter(_ => calendar.workDayExpectedHrs[_] > 0).length;
    let step = days > 0 ? 1 : -1;

    const wholeWeeks = Math.floor(Math.abs(days) / workDaysPerWeek);
    this.addDays(step * wholeWeeks * calendarDaysPerWeek);

    while (!this.isWorkingDay(calendar)) {
        this.addDays(-step);
    }

    const from = days > 0 ? dateTime : this;
    const to = days < 0 ? dateTime : this;

    days -= step * (getWorkingDaysBetweenDates(from, to, calendar) - (dateTime.isWorkingDay(calendar) ? 1 : 0));

    step = days > 0 ? 1 : -1;
    while (days !== 0) {
        this.addDays(step);
        if (this.isWorkingDay(calendar)) {
            days -= step;
        }
    }

    return this;
}

//similar method is in DateTimeExtensions on the backend
export function getWorkingDaysBetweenDates(startDate: Date, endDate: Date, calendar: CalendarDataSet): number {
    startDate = startDate.getBeginOfDay();
    endDate = endDate.getBeginOfDay();

    if (endDate < startDate) {
        return 0;
    }

    const calendarDaysPerWeek = 7;
    const workingDaysPerWeek = Object.keys(calendar.workDayExpectedHrs).filter(_ => calendar.workDayExpectedHrs[_] > 0).length;

    let calendarTotalDays = Math.round(diffDays(startDate.getBeginOfDay(), endDate.getEndOfDay()));
    let wholeWeeks = Math.floor(calendarTotalDays / calendarDaysPerWeek);

    let exceptionWorkingDays = splitToDays(calendar.exceptions
        .filter(_ => _.start <= endDate && _.end >= startDate))
        .filter(_ => startDate <= _.date && _.date <= endDate && calendar.workDayExpectedHrs[_.date.getDay()] > 0 != _.isWorkingDay)
        .reduce((sum, _) => (sum + (_.isWorkingDay ? 1 : -1)), 0);

    let extraWorkingDays = calendarTotalDays % calendarDaysPerWeek == 0 ? 0 :
        Array.from(Array(7).keys())
            .filter(_ => calendar.workDayExpectedHrs[_] > 0
                && (startDate.getDay() <= endDate.getDay()
                    ? (_ >= startDate.getDay() && _ <= endDate.getDay())
                    : (_ >= startDate.getDay() || _ <= endDate.getDay())))
            .length;

    return wholeWeeks * workingDaysPerWeek + extraWorkingDays + exceptionWorkingDays;
}

export function getDaysBetweenDates(start: Date, end: Date): Date[] {
    const result: Date[] = [];
    start = start.clone();

    while (start <= end) {
        result.push(start.getBeginOfDay());
        start.addDays(1);
    }

    return result;
}

export function getDuration(start: Date, end: Date): number {
    return Math.round(diffDays(start.getBeginOfDay(), end.getEndOfDay()));
}

export function getWorkingHoursBetweenDates(startDate: Date, endDate: Date, calendar: CalendarDataSet): number {
    if (endDate < startDate) {
        return 0;
    }

    const calendarDaysPerWeek = 7;

    let calendarTotalDays = Math.round(diffDays(startDate.getBeginOfDay(), endDate.getEndOfDay()));
    let wholeWeeks = Math.floor(calendarTotalDays / calendarDaysPerWeek);

    startDate = startDate.getBeginOfDay();
    endDate = endDate.getBeginOfDay();

    let exceptionWorkingHours = splitToDays(calendar.exceptions
        .filter(_ => _.start <= endDate && _.end >= startDate))
        .filter(_ => startDate <= _.date && _.date <= endDate && calendar.workDayExpectedHrs[_.date.getDay()] != _.expectedHrs)
        .reduce((sum, _) => (sum + _.expectedHrs - calendar.workDayExpectedHrs[_.date.getDay()]), 0);

    let extraWorkingHours = calendarTotalDays % calendarDaysPerWeek == 0 ? 0 :
        Array.from(Array(7).keys())
            .filter(_ => startDate.getDay() <= endDate.getDay()
                ? (_ >= startDate.getDay() && _ <= endDate.getDay())
                : (_ >= startDate.getDay() || _ <= endDate.getDay()))
            .reduce((sum, _) => (sum + calendar.workDayExpectedHrs[_]), 0);

    return wholeWeeks * calendar.workingHoursPerWeek + extraWorkingHours + exceptionWorkingHours;
}

Date.prototype.clone = function (): Date {
    const copiedDate = new Date(this.getTime());
    return copiedDate;
};

Date.prototype.trimHours = function (): Date {
    const date = new Date(this);
    date.setHours(0, 0, 0, 0);
    return date;
};

Date.prototype.getBeginOfDay = function (): Date {
    return this.trimHours();
};

Date.prototype.getEndOfDay = function (): Date {
    const date = new Date(this);
    date.setHours(23, 59, 59, 999);
    return date;
}

Date.prototype.getMonthStartDate = function () {
    return new Date(this.getFullYear(), this.getMonth());
}

Date.prototype.getMonthEndDate = function () {
    return new Date(this.getFullYear(), this.getMonth() + 1, 0);
}

Date.prototype.getWeekStartDate = function () {
    const result = new Date(this);
    result.setDate(this.getDate() - this.getDay());
    return result;
}

Date.prototype.getThisWeek = function () {
    const start = this.getWeekStartDate().getBeginOfDay();
    return { start, end: start.clone().addDays(6).getEndOfDay() }
}

Date.prototype.getNextWeek = function () {
    return this.clone().addDays(7).getThisWeek();
}

Date.prototype.getThisMonth = function () {
    const start = this.getMonthStartDate().getBeginOfDay();
    const end = this.getMonthEndDate().getEndOfDay();
    return { start, end };
}

Date.prototype.getNextMonth = function () {
    return this.getMonthEndDate().addDays(1).getThisMonth();
}

Date.prototype.getWeekAgo = function () {
    const date = this.getBeginOfDay();
    date.setDate(date.getDate() - 7);
    return date;
}

const _ticksTo1970: number = (((1970 - 1) * 365 + Math.floor(1970 / 4) - Math.floor(1970 / 100) +
    Math.floor(1970 / 400)) * MILLISECONDS_PER_DAY * 10000);

export function formatDate(format: any, date: any, settings?: any) {
    if (!date) {
        return "";
    }

    let iFormat: any,
        dayNamesShort = (settings ? settings.dayNamesShort : null) ||
            ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
        dayNames = (settings ? settings.dayNames : null) ||
            ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
        monthNamesShort = (settings ? settings.monthNamesShort : null) ||
            ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        monthNames = (settings ? settings.monthNames : null) ||
            [
                "January", "February", "March", "April", "May", "June", "July", "August", "September", "October",
                "November", "December"
            ],
        // Check whether a format character is doubled
        lookAhead = (match: any) => {
            const matches = (iFormat + 1 < format.length && format.charAt(iFormat + 1) === match);
            if (matches) {
                iFormat++;
            }
            return matches;
        },
        // Format a number, with leading zero if necessary
        formatNumber = (match: any, value: any, len: any) => {
            let num = "" + value;
            if (lookAhead(match)) {
                while (num.length < len) {
                    num = "0" + num;
                }
            }
            return num;
        },
        // Format a name, short or long as requested
        formatName =
            (match: any, value: any, shortNames: any, longNames: any) => lookAhead(match)
                ? longNames[value]
                : shortNames[value],
        output = "",
        literal = false;

    if (date) {
        for (iFormat = 0; iFormat < format.length; iFormat++) {
            if (literal) {
                if (format.charAt(iFormat) === "'" && !lookAhead("'")) {
                    literal = false;
                } else {
                    output += format.charAt(iFormat);
                }
            } else {
                switch (format.charAt(iFormat)) {
                    case "d":
                        output += formatNumber("d", date.getDate(), 2);
                        break;
                    case "D":
                        output += formatName("D", date.getDay(), dayNamesShort, dayNames);
                        break;
                    case "o":
                        output += formatNumber("o",
                            Math.round((new Date(date.getFullYear(), date.getMonth(), date.getDate()).getTime() -
                                new Date(date.getFullYear(), 0, 0).getTime()) /
                                86400000),
                            3);
                        break;
                    case "m":
                        output += formatNumber("m", date.getMonth() + 1, 2);
                        break;
                    case "M":
                        output += formatName("M", date.getMonth(), monthNamesShort, monthNames);
                        break;
                    case "y":
                        output += (lookAhead("y")
                            ? date.getFullYear()
                            : (date.getYear() % 100 < 10 ? "0" : "") + date.getYear() % 100);
                        break;
                    case "@":
                        output += date.getTime();
                        break;
                    case "!":
                        output += date.getTime() * 10000 + _ticksTo1970;
                        break;
                    case "'":
                        if (lookAhead("'")) {
                            output += "'";
                        } else {
                            literal = true;
                        }
                        break;
                    default:
                        output += format.charAt(iFormat);
                }
            }
        }
    }
    return output;
}

export function min(dates: (Date | string | undefined | null)[]): Date | undefined {
    return toDate(dates.reduce((accumulator, currentValue) => {
        const d1 = toDate(accumulator);
        const d2 = toDate(currentValue);
        return (d1 && d2)
            ? (d1 < d2 ? d1 : d2)
            : (d1 || d2);
    }, null));
}

export function max(dates: MaybeDate[]): Date | undefined {
    return toDate(dates.reduce((accumulator, currentValue) => {
        const d1 = toDate(accumulator);
        const d2 = toDate(currentValue);
        return (d1 && d2)
            ? (d1 > d2 ? d1 : d2)
            : (d1 || d2);
    }, null));
}

export function maxBy<T>(array: T[], select: (props: T) => MaybeDate): T | null {
    return array.reduce((accumulator: T | null, element) => {
        if (!accumulator) {
            return element;
        }

        const d1 = toDate(accumulator && select(accumulator));
        const d2 = toDate(select(element));
        return (d1 && d2)
            ? (d1 > d2 ? accumulator : element)
            : d1 ? accumulator : element;
    }, null);
}

export interface IMinMax {
    minDate: Date | undefined,
    maxDate: Date | undefined
}

export function minMax(dates: MaybeDate[]): IMinMax {
    let initialValue: IMinMax = { minDate: undefined, maxDate: undefined };
    return dates.reduce((accumulator, currentValue) => {
        const date = toDate(currentValue);
        if (date) {
            accumulator.minDate = accumulator.minDate && accumulator.minDate < date
                ? accumulator.minDate
                : date;
            accumulator.maxDate = accumulator.maxDate && accumulator.maxDate > date
                ? accumulator.maxDate
                : date
        }
        return accumulator;
    }, initialValue);
}

export function splitString(str: string | null, externalSeparator?: string) {
    if (!str) {
        return [];
    }
    const separator = externalSeparator || ',';
    const commaSubstitutor = 'comma-substitutor';
    return str
        .replace(new RegExp(separator + separator, 'g'), commaSubstitutor)
        .split(separator)
        .map(_ => _.replace(new RegExp(commaSubstitutor, 'g'), separator));
}

export function calculateFractionDate(startDate: Date, finishDate: Date, progress: number) {
    const duration = diffDays(startDate, finishDate);
    return startDate.clone().addDays(duration * progress);
}

export function diffDays(startDate: Date, finishDate: Date, keepSign?: boolean) {
    const diff = (finishDate.getTime() - startDate.getTime()) / MILLISECONDS_PER_DAY;
    return keepSign ? diff : Math.abs(diff);
}

export function dateRangeOverlaps(a_start: Date, a_end: Date, b_start: Date, b_end: Date) {
    b_start = b_start || b_end;
    b_end = b_end || b_start;
    return a_start <= b_end! && b_start! <= a_end;
}

export function remainingDaysToString(finish: string | Date | undefined, isTrial?: boolean): string {
    const finishDate = toDate(finish);
    if (!finishDate) {
        return "";
    }

    if (finishDate < new Date().getBeginOfDay()) {
        return isTrial ? "" : "Expired";
    }

    const count = remainDays(finish)
    return count == 0 ? "Expires today" : `${count}${isTrial ? " trial" : ""} day${count == 1 ? '' : 's'} left`;
}

export function remainDays(finish: string | Date | undefined): number | undefined {
    const finishDate = toDate(finish);

    if (!finishDate)
        return undefined;

    const now = new Date();
    if (now > finishDate) {
        return 0;
    }

    finishDate.setHours(24, 0, 0, 0);
    return Math.floor(diffDays(finishDate, now));
}

export function printDaysOfWeek(days: DayOfWeek[]): string {
    return days
        .filter((_, idx, arr) => arr.indexOf(_) === idx)
        .sort()
        .map((_, idx, arr) => {
            if (idx === 0) {
                return DayOfWeek[_];
            }
            if ((arr[idx - 1] + 1) !== _) {
                return ', ' + DayOfWeek[_];
            }
            if (arr.length >= idx && _ === (arr[idx + 1] - 1)) {
                return ' - ';
            }
            const prev2_offset = 2;
            return (idx > 1 && arr[idx - prev2_offset] + prev2_offset === _) ? DayOfWeek[_] : (', ' + DayOfWeek[_]);
        }).filter((_, idx, arr) => idx === 0 || arr[idx - 1] !== _).join('');
}

// idField neccesary to make stable sort
export function getComparer(fieldName: string, direction: SortDirection, idField?: string): (a: any, b: any) => number {
    return direction == SortDirection.ASC
        ? (idField
            ? (a, b) => a[fieldName] == b[fieldName] ? (a[idField] > b[idField] ? 1 : -1) : (a[fieldName] > b[fieldName] ? 1 : -1)
            : (a, b) => a[fieldName] == b[fieldName] ? 0 : (a[fieldName] > b[fieldName] ? 1 : -1)
        )
        : (idField
            ? (a, b) => a[fieldName] == b[fieldName] ? (a[idField] > b[idField] ? -1 : 1) : (a[fieldName] > b[fieldName] ? -1 : 1)
            : (a, b) => a[fieldName] == b[fieldName] ? 0 : (a[fieldName] > b[fieldName] ? -1 : 1)
        );
}