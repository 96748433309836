import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ExpandablePanel from "../../common/ExpandablePanel";
import {
    CheckboxVisibility,
    IColumn,
    IDetailsHeaderProps,
    IDetailsRowProps,
    IRenderFunction,
    ITooltipHostProps,
    Overlay,
    PanelType,
    ScrollablePane,
    ScrollbarVisibility,
    SearchBox,
    Spinner,
    Sticky,
    StickyPositionType,
    TooltipHost,
    Spinner as FabricSpinner,
    Icon,
    SpinnerSize,
    ActionButton,
    IGroup,
    IDetailsGroupDividerProps,
    IconButton,
    DefaultButton,
    IDropdownOption,
} from "office-ui-fabric-react";
import { EXTRA_LARGE_PANEL_WIDTH, ITimeframe } from "../../../entities/common";
import { ResultsNotFoundPlaceholder } from "../../common/sectionsControl/SectionPlaceholder";
import DetailsListWrapper from "../../common/DetailsListWrapper";
import { DebouncedAction, formatValue, groupBy, IsEmptyObject, toDateTime } from "../../utils/common";
import * as Metadata from "../../../entities/Metadata";
import { EditableCell, EditorProps, FormatterProps } from "../../import/common/EditableCell";
import TextInput from "../../common/inputs/TextInput";
import "./TimeTrackingImportPanel.css";
import { FixMinAndIncrementDuration } from "../Fields";
import { TimeTrackingEntryProject } from "../../../store/TimeTrackingStore";
import DropdownInput, { TextOption } from "../../common/inputs/DropdownInput";
import { FilterAttribute } from "../../common/FilterAttributes/FilterAttribute";
import { TimeTrackingGlobalSettings } from "../../../store/Tenant";
import { ApplicationState } from "../../../store";
import { connect } from "react-redux";
import { catchApiError } from "../../../store/utils";
import EntityPickerInput, { IFindResult } from "../../common/inputs/EntityPickerInput";
import { formatDate } from "../../common/timeline/utils";

export type BaseProps<TSuggestion extends TimeTrackingSuggestionBase> = {
    projects: TimeTrackingEntryProject[];
    project?: TimeTrackingEntryProject;

    onDismiss: () => void;
    onImport: (entity: TSuggestion, callback: () => void) => void;
    isEntityImported: (entity: TSuggestion) => ImportedSuggestionProps | undefined;

    timeFrame: ITimeframe;
}

type OwnProps<
    TSuggestion extends TimeTrackingSuggestionBase,
    TFilter extends ImportTimeTrackingSuggestionFilterBase
    > = BaseProps<TSuggestion> &
    {
        loadSuggestions: (timeFrame: ITimeframe, filter: TFilter) => Promise<TSuggestion[]>;
        onRenderHeader: () => JSX.Element;
        onRenderItemName: (item: TSuggestion) => JSX.Element;
        onRenderCustomFilters?: (filter: TFilter, onFilterChange: (change: Partial<TFilter>) => void) => JSX.Element;
    };

type StateProps = {
    timeTrackingSettings: TimeTrackingGlobalSettings;
};

type Props<TSuggestion extends TimeTrackingSuggestionBase, TFilter extends ImportTimeTrackingSuggestionFilterBase> = OwnProps<TSuggestion, TFilter> & StateProps;

enum EntityStatus {
    Added,
    Add,
    Processing,
}

export type TimeTrackingSuggestionBase = {
    id: string;
    name: string;
    date: Date;
    duration: number;
    project: TimeTrackingEntryProject | undefined;
    isAdministrative?: boolean;
    isProjectReadonly?: boolean;
};

export type ImportedSuggestionProps = {
    project: TimeTrackingEntryProject;
    duration: number;
};

export type ImportTimeTrackingSuggestionFilterBase = {
    searchText?: string;
    date?: {
        from: string;
        to: string;
    };

    readers?: string[];
    hideAdded?: boolean;
};

function TimeTrackingImportSuggestionsPanelBase<
    TSuggestion extends TimeTrackingSuggestionBase,
    TFilter extends ImportTimeTrackingSuggestionFilterBase
>(props: Props<TSuggestion, TFilter>) {
    const { onDismiss, isEntityImported, timeFrame, projects, onImport, timeTrackingSettings, project } = props;
    const { onRenderHeader, onRenderItemName, onRenderCustomFilters } = props;

    const [suggestions, setSuggestions] = useState<TSuggestion[]>([]);

    const [processingSuggestions, setProcessingSuggestions] = useState<string[]>([]);
    const processingSuggestionsRef = useRef<string[]>([]);
    //to track the latest changes in callbacks
    processingSuggestionsRef.current = processingSuggestions;

    const emptyFilter: TFilter = {} as TFilter;

    const [filter, setFilter] = useState<TFilter>(emptyFilter);

    const { isLoading, loadingError, loadSuggestions } = useLoadSuggestions(timeFrame, timeTrackingSettings, project, setSuggestions, isEntityImported, props.loadSuggestions);

    useEffect(() => {
        loadSuggestions(filter);
    }, []);

    const debouncedFiltering = useMemo(
        () =>
            new DebouncedAction((filters: TFilter[]) => {
                if (filters?.length) {
                    const lastFitler = filters[filters.length - 1];
                    loadSuggestions(lastFitler);
                }
            }).callAction,
        [loadSuggestions]
    );

    const onFilterChange = (changes: Partial<TFilter>) => {
        const newFilter = { ...filter, ...changes };
        setFilter(newFilter);

        if (changes.hideAdded === undefined) {
            debouncedFiltering(newFilter);
        }
    };

    const clearFilter = () => {
        setFilter(emptyFilter);
        debouncedFiltering(emptyFilter);
    };

    const getItemStatus = (suggestion: TSuggestion) => {
        if (processingSuggestions?.includes(suggestion.id)) {
            return EntityStatus.Processing;
        }

        if (isEntityImported(suggestion)) {
            return EntityStatus.Added;
        }

        return EntityStatus.Add;
    };

    const onImportSuggestion = (suggestion: TSuggestion) => {
        setProcessingSuggestions([...processingSuggestionsRef.current, suggestion.id]);
        onImport(suggestion, () => {
            setProcessingSuggestions(processingSuggestionsRef.current.filter(_ => _ !== suggestion.id));
        });
    };

    const editSuggestion = (suggestion: TSuggestion) => {
        const newSuggestions = suggestions.map(_ => {
            if (_.id === suggestion.id) {
                return { ...suggestion };
            }

            return _;
        });

        setSuggestions(newSuggestions);
    };

    const getColumns = useCallback(
        () =>
            _getColumns<TSuggestion>(
                projects,
                timeTrackingSettings,
                onRenderItemName,
                onImportSuggestion,
                editSuggestion,
                getItemStatus
            ),
        [projects, suggestions, processingSuggestions, isEntityImported, onRenderItemName]
    );

    const visibleSuggestions = suggestions.filter(_ => !filter.hideAdded || getItemStatus(_) !== EntityStatus.Added);
    const groups = useDateGroups(visibleSuggestions);

    return (
        <ExpandablePanel
            className={`search-item-import-panel import-tt-suggestions-panel ${suggestions?.length ? "has-items" : ""}`}
            isLightDismiss
            type={PanelType.custom}
            customWidth={EXTRA_LARGE_PANEL_WIDTH}
            isOpen
            onDismiss={onDismiss}
            onRenderHeader={onRenderHeader}>
            <SearchBox
                placeholder="Search activity by Name"
                value={filter.searchText ?? ""}
                onChange={(e: any, v: string) => onFilterChange({searchText: v} as Partial<TFilter>)}
            />

            <div className="align-center">
                <Filters
                    timeFrame={timeFrame}
                    filter={filter}
                    onFilterChange={onFilterChange}
                    onRenderCustomFilters={onRenderCustomFilters} />
                {!IsEmptyObject(filter) && (
                    <DefaultButton
                        className="filter-button"
                        iconProps={{ iconName: "ClearFilter" }}
                        text="Clear Filter"
                        onClick={clearFilter}
                    />
                )}
            </div>
            <div data-is-scrollable={true} className="list-container">
                {loadingError ? (
                    <div className="error-message">{loadingError}</div>
                ) : (
                    <>
                        {isLoading === false && visibleSuggestions.length === 0 ? (
                            <div className="placeholder">
                                <ResultsNotFoundPlaceholder clearFilter={ IsEmptyObject(filter) ? undefined : clearFilter} />
                            </div>
                        ) : (
                            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                                <DetailsListWrapper
                                    checkboxVisibility={CheckboxVisibility.hidden}
                                    items={visibleSuggestions}
                                    //it starts jumping with virtualizating on small screens due to grouping
                                    onShouldVirtualize={() => false}
                                    groups={groups}
                                    groupProps={{
                                        onRenderHeader: (renderProps: IDetailsGroupDividerProps) => (
                                            <div className="group-bydate-header">
                                                <IconButton
                                                    title={!renderProps.group?.isCollapsed ? "Expand" : "Collapse"}
                                                    iconProps={{
                                                        iconName: renderProps.group?.isCollapsed
                                                            ? "ChevronUp"
                                                            : "ChevronDown",
                                                    }}
                                                    onClick={e => {
                                                        renderProps.onToggleCollapse?.(renderProps.group!);
                                                        e.preventDefault();
                                                    }}
                                                />
                                                <div className="group-name">{renderProps.group?.name}</div>
                                            </div>
                                        ),
                                    }}
                                    columns={getColumns()}
                                    onRenderRow={(
                                        detailsRowProps: IDetailsRowProps,
                                        defaultRender?: (props?: IDetailsRowProps) => JSX.Element | null
                                    ) => {
                                        const row = defaultRender?.(detailsRowProps);
                                        const item = detailsRowProps.item as TSuggestion;
                                        const status = getItemStatus(item);
                                        if (row && status === EntityStatus.Added) {
                                            return React.cloneElement(row, { className: "item-added" });
                                        }
                                        return row || null;
                                    }}
                                    disableSelectionZone
                                    onRenderDetailsHeader={_onRenderDetailsHeader}
                                    className="not-clickable"
                                />
                            </ScrollablePane>
                        )}
                    </>
                )}
                {isLoading && (
                    <Overlay>
                        <Spinner />
                    </Overlay>
                )}
            </div>
        </ExpandablePanel>
    );
}

const useDateGroups = (suggestions: TimeTrackingSuggestionBase[]) => {
    return useMemo(() => {
        const byDate = groupBy(suggestions, _ => formatDate("M dd, D", _.date!));

        const groupsByDay: IGroup[] = [];
        for (const [date, suggestionsByDate] of Object.entries<TimeTrackingSuggestionBase[]>(byDate)) {
            groupsByDay.push({
                key: date,
                name: date,
                count: suggestionsByDate.length,
                startIndex: suggestions.indexOf(suggestionsByDate[0]),
            });
        }

        return groupsByDay;
    }, [suggestions.map(_ => _.id).join()]);
};

function useLoadSuggestions<TSuggestion extends TimeTrackingSuggestionBase, TFilter extends ImportTimeTrackingSuggestionFilterBase>(
    timeFrame: ITimeframe,
    timeTrackingSettings: TimeTrackingGlobalSettings,
    project: TimeTrackingEntryProject | undefined,
    onLoaded: (suggestion: TSuggestion[]) => void,
    isEntityImported: (entity: TSuggestion) => ImportedSuggestionProps | undefined,
    loadSuggestionsImp: (timeFrame: ITimeframe, filter: TFilter) => Promise<TSuggestion[]>
) {
    const [isLoading, setIsLoading] = useState(false);
    const [loadingError, setLoadingError] = useState<string>();

    const loadSuggestions = useCallback(
        (filter: TFilter) => {
            setIsLoading(true);
            setLoadingError("");

            loadSuggestionsImp(timeFrame, filter)
                .then(data => {
                    data.forEach(_ => {
                        const isImported = isEntityImported(_);
                        if (isImported) {
                            _.duration = isImported.duration;
                            _.project = isImported.project;
                        }
                        else {
                            _.duration = _.duration && FixMinAndIncrementDuration(_.duration, timeTrackingSettings.minReportingDurationMinutes, timeTrackingSettings.reportingIncrementMinutes);
                            _.project ||= project;
                        }
                    });

                    onLoaded(data);
                    setIsLoading(false);
                })
                .catch(_ => {
                    catchApiError(apiError => setLoadingError(apiError))(_);
                    setIsLoading(false);
                    onLoaded([]);
                });
        },
        [isEntityImported]
    );

    return { isLoading, loadingError, loadSuggestions };
};

type FilterProps<TFilter extends ImportTimeTrackingSuggestionFilterBase = ImportTimeTrackingSuggestionFilterBase> = {
    timeFrame: ITimeframe,
    filter: TFilter,
    onFilterChange: (change: Partial<TFilter>) => void,
    onRenderCustomFilters?: (filter: TFilter, onFilterChange: (change: Partial<TFilter>) => void) => JSX.Element
};

const Filters = (
    props: FilterProps): JSX.Element => {
    const { timeFrame, filter, onFilterChange, onRenderCustomFilters } = props;
    return (
        <div className="filter-attributes-container align-center">
            <FilterAttribute
                key="date"
                field={{
                    ..._fakeDateField,
                    settings: {
                        ..._fakeDateField.settings,
                        minValue: timeFrame.start,
                        maxValue: timeFrame.end
                    }
                }}
                value={filter.date}
                onEditComplete={newValue => {
                    onFilterChange({ date: newValue as any });
                }}
            />
        
            {
                onRenderCustomFilters?.(filter, onFilterChange)
            }

            <FilterAttribute
                key="hideAdded"
                field={_fakeHideAddedField}
                value={filter.hideAdded}
                onEditComplete={(newValue: boolean) => {
                    if(newValue !== !!filter.hideAdded){
                        onFilterChange({ hideAdded: newValue });
                    }
                }}
            />
        </div>
    );
};

const _fakeDateField: Metadata.Field = {
    id: "dateField",
    type: Metadata.FieldType.Date,
    name: "Date",
    label: "Date",
    group: Metadata.FieldGroup.SystemFields,
    isNative: false,
    isReadonly: false,
    isSystem: false,
    isCustom: false,
};

const _fakeHideAddedField: Metadata.Field = {
    id: "hideAddedField",
    name: "hideAddedField",
    label: "Hide Added",
    isNative: false,
    isReadonly: false,
    isCustom: false,
    isSystem: false,
    type: Metadata.FieldType.Flag,
    group: Metadata.FieldGroup.SystemFields,
}

const _onRenderDetailsHeader = (
    props: IDetailsHeaderProps,
    defaultRender?: IRenderFunction<IDetailsHeaderProps>
): JSX.Element => {
    return (
        <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced={true}>
            {defaultRender!({
                ...props,
                onRenderColumnHeaderTooltip: (tooltipHostProps: ITooltipHostProps) => <TooltipHost {...tooltipHostProps} />,
            })}
        </Sticky>
    );
};

function _getColumns<TSuggestion extends TimeTrackingSuggestionBase>(
    projects: TimeTrackingEntryProject[],
    timeTrackingSettings: TimeTrackingGlobalSettings,
    onRenderItemName: (item:TSuggestion) => JSX.Element,
    onImport: (suggestion: TSuggestion) => void,
    onSuggestionEdit: (suggestion: TSuggestion) => void,
    getSuggestionStatus: (suggestion: TSuggestion) => EntityStatus
): IColumn[] {
    const timeWidth = 60;
    const statusWidth = 60;
    const nameWidth = 365;

    const result: IColumn[] = [
        {
            minWidth: nameWidth,
            maxWidth: nameWidth,
            key: "name",
            name: "Name",
            fieldName: "entityName",
            onRender: (item) => (
                <div className="item-name">
                    {onRenderItemName(item)}
                </div>
            ),
        },
        {
            minWidth: timeWidth,
            maxWidth: timeWidth,
            key: "time",
            name: "Time",
            fieldName: "time",
            onRender: (entity: TSuggestion) => {
                const isReadonly = getSuggestionStatus(entity) === EntityStatus.Added;
                return (
                    <div className={`full-width ${isReadonly ? "readonly" : ""}`}>
                        <EditableCell
                            item={entity}
                            value={entity.duration}
                            allowOutsideClick
                            readonly={isReadonly}
                            formatter={_durationFormatter}
                            editor={_durationEditorBuilder(timeTrackingSettings)}
                            onEditComplete={(value: number) => {
                                onSuggestionEdit({ ...entity, duration: value });
                            }}
                        />
                    </div>);
            },
        },
        {
            minWidth: nameWidth,
            maxWidth: nameWidth,
            key: "project",
            name: "Project",
            fieldName: "project",
            onRender: (entity: TSuggestion) => (
                <ProjectEditableCell
                    entity={entity}
                    isAdded={getSuggestionStatus(entity) === EntityStatus.Added}
                    projects={projects}
                    onSuggestionEdit={onSuggestionEdit}
                />
            ),
        },
        {
            minWidth: statusWidth,
            maxWidth: statusWidth,
            key: "status",
            name: "Status",
            fieldName: "status",
            onRender: (item: TSuggestion, index?: number, column?: IColumn) => {
                const status = getSuggestionStatus(item);
                if (status === EntityStatus.Add) {
                    const isValid = !!item.duration && (item.isAdministrative || !!item.project);
    
                    return (
                        <ActionButton
                            iconProps={{ iconName: "Add" }}
                            title={"Add"}
                            disabled={!isValid}
                            onClick={() => onImport(item)}>
                            Add
                        </ActionButton>
                    );
                }
                if (status === EntityStatus.Added) {
                    return (
                        <div className="align-center status-cell">
                            <Icon className="main-color font-14" iconName="LocationDot" />
                            <div className="font-14 label">Added</div>
                        </div>
                    );
                }
                if (status === EntityStatus.Processing) {
                    return (
                        <div className="align-center status-cell spinner-status">
                            <FabricSpinner size={SpinnerSize.small} title="Processing" />
                        </div>
                    );
                }
                return null;
            },
        }
    ];

    return result;
};

const _durationFormatter = (props: FormatterProps<TimeTrackingSuggestionBase, number>): JSX.Element => {
    return <div className="formatted-editable-value">{formatValue(props.value, Metadata.FormatType.Duration)}</div>;
};

const _durationEditorBuilder =
    (timeTrackingSettings: TimeTrackingGlobalSettings) => (props: EditorProps<TimeTrackingSuggestionBase, number>) => {
        return (
            <TextInput
                value={props.value ? props.value.toString() : ""}
                inputRef={props.inputRef}
                selectOnFocus
                format={Metadata.FormatType.Duration}
                onEditComplete={value => {
                    let duration = Number(value);

                    if (duration) {
                        duration = FixMinAndIncrementDuration(
                            duration,
                            timeTrackingSettings.minReportingDurationMinutes,
                            timeTrackingSettings.reportingIncrementMinutes
                        );
                    }

                    props.onEditComplete?.(duration || 0);
                }}
            />
        );
    };

type ProjectEditableCellProps = {
    entity: TimeTrackingSuggestionBase;
    projects: TimeTrackingEntryProject[];
    onSuggestionEdit: (suggestion: TimeTrackingSuggestionBase) => void;
    isAdded: boolean;
};

const ProjectEditableCell = (props: ProjectEditableCellProps) => {
    const { entity, onSuggestionEdit, projects, isAdded } = props;
    const editableCellRef = useRef<EditableCell<unknown, unknown>>();
    const [isPickerShown, setIsPickerShown] = useState(false);

    const isReadonly = isAdded || entity.isProjectReadonly && !!entity.project;

    const projectsList = useMemo(() => {
        return entity.project?.id && !projects.some(_ => _.id === entity.project?.id)
            ? [...projects, entity.project]
            : projects;
    }, [projects, entity.project]);

    if (entity.isAdministrative) {
        return (<></>);
    }

    return (
        <div className={`full-width ${isReadonly ? "readonly" : ""}`}>
            <EditableCell
                item={entity}
                value={entity.project}
                readonly={isReadonly}
                ref={_ => (editableCellRef.current = _ as any)}
                formatter={(formatterProps: EditorProps<TimeTrackingSuggestionBase, TimeTrackingEntryProject | null | undefined>) => (
                    <div className="formatted-editable-value" title={formatterProps.value?.name}>
                        {formatterProps.value?.name ?? "Select Project"}
                    </div>
                )}
                allowOutsideClick={isPickerShown}
                editor={(editorProps: EditorProps<TimeTrackingSuggestionBase, TimeTrackingEntryProject | null | undefined>) => {
                    const typeToSearchProjectId = "typeToSearchProjectId";

                    const onEditComplete = (project: TimeTrackingEntryProject | undefined | null) => {
                        editorProps.onChange(project);

                        if (project) {
                            editorProps.onEditComplete(project);
                        } else {
                            //to set empty value a moment after props.onChange(project)
                            setTimeout(() => editorProps.onEditComplete());
                        }
                    };

                    if (isPickerShown) {
                        return (
                            <EntityPickerInput
                                searchUrl={`api/project/find`}
                                inputRef={editorProps.inputRef}
                                inputProps={{ placeholder: "Type to search" }}
                                onBlur={() => {
                                    editorProps.onEditComplete();
                                    setIsPickerShown(false);
                                }}
                                onEditComplete={(_: IFindResult[] | null) => {
                                    const project = _?.[0];

                                    onEditComplete(project);
                                    setIsPickerShown(false);
                                }}
                            />
                        );
                    }

                    return (
                        <DropdownInput
                            value={editorProps.value?.id ?? ""}
                            onEditComplete={(projectId: string) => {
                                if (projectId === typeToSearchProjectId) {
                                    setIsPickerShown(true);

                                    //re-focus when entity picker is shown
                                    setTimeout(() => editableCellRef.current?.focus());
                                } else {
                                    onEditComplete(projectsList.find(_ => _.id === projectId));
                                }
                            }}
                            inputRef={editorProps.inputRef}                            
                            inputProps={{
                                calloutProps: { preventDismissOnResize: true, preventDismissOnLostFocus: true },
                                options: [
                                    {
                                        key: "",
                                        text: "",
                                    },
                                    ...projectsList.map(_ => ({
                                        key: _.id,
                                        text: _.name,
                                    })),
                                    {
                                        key: typeToSearchProjectId,
                                        text: "Click here to search globally",
                                    },
                                ],
                                onRenderOption: (item: IDropdownOption) => {

                                    return item?.key === typeToSearchProjectId
                                        ? (<span className="import-tt-suggestions-panel-global-search">{item.text}</span>)
                                        : TextOption(item);
                                }
                            }}
                        />
                    );
                }}
                onEditComplete={(value: TimeTrackingEntryProject) => {
                    onSuggestionEdit({ ...entity, project: value });
                }}
            />
        </div>
    );
};

export const getStartAndEnd = (timeFrame: ITimeframe, filter: ImportTimeTrackingSuggestionFilterBase): ITimeframe => {
    let start = timeFrame.start;
    let end = timeFrame.end;

    if (filter.date?.from) {
        start = toDateTime(filter.date.from)!;

        if (filter.date.to) {
            end = toDateTime(filter.date.to)!;
        }
        else {
            end = start;
        }
    }
    else if (filter.date?.to) {
        start = toDateTime(filter.date.to)!;
        end = toDateTime(filter.date.to)!;
    }

    return { start, end };
 }
    

function mapStateToProps(state: ApplicationState): StateProps {
    return {
        timeTrackingSettings: state.tenant.timeTracking.globalSettings,
    };
}

export default connect(mapStateToProps)(TimeTrackingImportSuggestionsPanelBase);