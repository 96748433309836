import * as React from 'react';
import { Field, FieldGroup, FieldType, FormatType } from '../../../../../entities/Metadata';
import { KeyDate } from '../../../../../entities/Subentities';
import { IListViewFieldColumn } from '../../../../../services/ViewService';
import { DisplayFieldService } from '../../../../common/DisplayFieldService';

const componentPath = "keyDate/Baseline";

export const BaselineFakeFields: Field[] = [{
    id: "b7e9d477-1e6a-4d22-9da7-03b471673de9",
    label: "Baseline Date",
    name: "date",
    type: FieldType.Date,
    isNative: false,
    isCustom: false,
    isReadonly: false,
    isSystem: false,
    isFake: true,
    group: FieldGroup.SystemFields,
    settings: { views: { list: { componentPath, maxWidth: 150 } } },
}, {
    id: "3b5c9a8d-4423-4f3f-9b94-91626e29482c",
    label: "Date Variance",
    name: "dateVariance",
    type: FieldType.Integer,
    isNative: false,
    isCustom: false,
    isReadonly: false,
    isSystem: false,
    isFake: true,
    group: FieldGroup.SystemFields,
    settings: { views: { list: { componentPath, maxWidth: 150 } }, format: FormatType.Days }
}];

export default class Baseline extends React.Component<IListViewFieldColumn<KeyDate>> {
    render() {
        const { field, entity } = this.props;
        const value = entity.baseline?.[field.name];
        const Formatter = DisplayFieldService.getFieldFormatter(field, false);
        if (Formatter) {
            return <Formatter value={value} />;
        }
        return <>{value !== undefined && value !== null ? `${value}` : ''}</>;
    }
}
export function getValue(entity: KeyDate, field: Field): string {
    return entity.baseline?.[field.name];
}