import * as React from 'react';
import { Icon, DefaultButton, Link } from 'office-ui-fabric-react';

type Props = {
    title?: string;
    description?: string | JSX.Element;
    iconName?: string;
    className?: string;
}

export const SectionControlPlaceholder = (props: React.PropsWithChildren<Props>) => (
    <div className={`section-placeholder ${props.className ?? ''}`}>
        <div className="container">
            {props.iconName && <div className="icon-container align-center"><Icon iconName={props.iconName} /></div>}
            {props.title && <div className="title">{props.title}</div>}
            {props.description && <div className="description">{props.description}</div>}
        </div>
        <div className="buttons-container">{props.children}</div>
    </div>
);

export const LoadingError = (props: { onRefreshClick: () => void }) => (
    <SectionControlPlaceholder
        title="Ooops, something went wrong"
        description="Sorry, we could't load information for this section"
        iconName="LoadingError">
            <DefaultButton text="Refresh" onClick={props.onRefreshClick} />
    </SectionControlPlaceholder>
);

export const ResultsNotFoundPlaceholder = (props: { clearFilter?: () => void }) => (
    <SectionControlPlaceholder
        title="Results not found"
        className="not-found"
        iconName="PPMXResultsNotFound">
        <div className="description">Please try turning off the filtering or changing filter settings.&nbsp;
            {props?.clearFilter && <Link onClick={(e) => {
                e.preventDefault();
                props?.clearFilter?.();
            }}>Clear Filter</Link>}</div>
    </SectionControlPlaceholder>
);

export const ClearFilterComponent = <T extends any>(props: React.PropsWithChildren<{ filteredItems: T[], items: T[], onClearFilter: () => void }>) => {
    if (!props.filteredItems.length && props.items.length) {
        return <ResultsNotFoundPlaceholder clearFilter={props.onClearFilter} />
    }
    return <>{props.children}</>;
}
