import React from 'react'
import { EntityDescriptor } from '../../../store/RoadmapsListStore';
import { IExtensibleEntity, ITimeframe, ServerEntityType } from '../../../entities/common';
import SearchItemsImportPanel from '../../common/SearchItemsImportPanel';
import { Resource } from '../../../store/ResourcesListStore';
import { TimeTrackingEntryProject } from '../../../store/TimeTrackingStore';
import { ApplicationState } from '../../../store';
import { connect } from 'react-redux';
import { TimeTrackingGlobalSettings } from '../../../store/Tenant';

type OwnProps = {

    project?: TimeTrackingEntryProject;

    resource: Resource;
    timeFrame: ITimeframe | undefined;

    onDismiss: () => void,
    onImport: (descriptor: EntityDescriptor, callback: () => void, entity: IExtensibleEntity) => void;
    onUndoImport: (type: ServerEntityType, externalId: string, callback: () => void) => void;
    isEntityImported: (externalEntityId: string) => boolean;

    exceptIds: string[]
}

type StateProps = {
    timeTrackingSettings: TimeTrackingGlobalSettings;
}

type Props = OwnProps & StateProps;

function TimeTrackingImportTasksPanel(props: Props) {

    const { timeFrame, resource, project, exceptIds, timeTrackingSettings} = props;
    const hiddenFilterFields: string[] = timeTrackingSettings.enableReportingOnNotAssignedTasks
        ? []
        : ["AssignedTo"];
    
    return <SearchItemsImportPanel
        {...props}
        title='Import Tasks'
        subTitle='Select Tasks that you want to add to the Timesheet to Report Time'
        supportedEntityTypes={[ServerEntityType.Project, ServerEntityType.Task]}
        showParentColumn={(type) => type === ServerEntityType.Task}
        showStatusColumn={(type) => type === ServerEntityType.Task}
        selectedEntityType={ServerEntityType.Task}
        showMyWorkItems
        initialFilter={{
            Date: timeFrame && {
                from: timeFrame.start,
                to: timeFrame.end
            },
            AssignedTo: [{ id: resource.id, fullName: resource.name, imageId: resource.imageId }],
            Projects: project ? [project] : undefined
        }}
        exceptIds={exceptIds}
        hiddenFilterFields={hiddenFilterFields}
    />
}

function mapStateToProps(state: ApplicationState): StateProps {
    return {
        timeTrackingSettings: state.tenant.timeTracking.globalSettings,
    };
}

export default connect(mapStateToProps)(TimeTrackingImportTasksPanel);