import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Selection, CommandBarButton, DefaultButton, IContextualMenuItem, IObjectWithKey, IColumn } from 'office-ui-fabric-react';
import { ApplicationState } from '../../store';
import {
    DetailsControlSettings, Dictionary, EntityType, IBaseEntity,
    IWithActiveSubViewId, UserPreferencesSettingsUpdate, SortDirection, TimelineControlSettings, ComparerBuilder, TimelineMapControlSettings, getCheckboxOptionsBasedOnCommands,
    IExtensibleEntity
} from "../../entities/common";
import ListMenu, { IWithViews, IWithColumns, IOutline } from './extensibleEntity/ListMenu';
import { HierarchyDetailsList, IDraggableEvents, IListProps, ListGroupingProps, OnItemRender, PersistDetailsList } from './extensibleEntity/EntityDetailsList';
import * as Metadata from '../../entities/Metadata';
import { ISubentity, isLinkedSubentity, urlParamsBuilder, ISubentitySectionActions, SubentityInfo } from "../../entities/Subentities";
import RemoveDialog from "./RemoveDialog";
import * as FieldsStore from '../../store/fields';
import * as FiltersStore from '../../store/filters';
import { isInReadonlyMode } from '../../store/User';
import { contains, CommonOperations } from '../../store/permissions';
import * as NotificationsStore from '../../store/NotificationsStore';
import { HierarchyTimelineList, ITimelineProps, PersistTimelineList } from './extensibleEntity/EntityTimelineList';
import { HierarchyTimelineMap, ITimelineMapProps, PersistTimelineMap } from './extensibleEntity/EntityTimelineMap';
import { ControlSpiner } from './Spinner';
import { toDictionaryById, notUndefined, toDictionaryByName, arraysEqual } from '../utils/common';
import { ClearFilterComponent, SectionControlPlaceholder } from './sectionsControl/SectionPlaceholder';
import { IHeader } from './ItemCreation';
import { IValidator } from '../../validation';
import { Dispatch, bindActionCreators } from 'redux';
import { EntityGroup } from './extensibleEntity/EntityGroupHeader';
import ScrollPositionFixer from '../utils/ScrollPositionFixer';
import { SourceType } from '../../store/ExternalEpmConnectStore';
import { RowMenu } from './extensibleEntity/RowMenu';
import { SectionHierarchyManager } from '../utils/SectionHierarchyManager';
import { SortService } from '../../services/SortService';
import { FilterHelper, FilterHelperProps } from '../../store/subentities/filters';
import PngExporter, { buildExportToPngMenuItem, PngExportConfig } from './PngExporter';
import { PngExportControlDetails, actionCreators as PngExportActionCreators } from '../../store/PngExporterStore';
import SelectionExt from './SelectionExt';
import { CreatePanel, UpdatePanel, ISubentityPanelProps } from './SubentityPanel';
import { IWithActiveFilter } from '../../store/services/settingsService';
import { ListUserSettings, Views } from '../../store/services/viewSaver';
import { Styling } from './timeline/TimelineList';
import { IWithSubViews, withSubView } from './withSubView';
import { IMaybeWithFilter, WithFilterProps, isFilterChanged, withFilter } from './withFilter';
import FullscreenReMountComponent from './FullscreenReMountComponent';
import { buildActionsMenuItemWithItems, buildExtensionsMenuItem, csvImportExportIconProps } from './headerMenuItemBuilders';
import { MenuTitleBuilder } from '../MenuTitleBuilder';
import { ExtensionInfo, findExtension } from '../../store/ExtensionStore';
import ExtensionPanel from './ExtensionPanel';
import RowMenuWithExtensions from './extensibleEntity/RowMenuWithExtensions';
import { ViewService } from '../../services/ViewService';

enum DialogAction {
    None,
    Update,
    Add,
    Import
}

export type MenuButtonState<TSubentity extends ISubentity> = {
    name?: string;
    title?: string;
    hidden?: boolean | ((selectedItems: TSubentity[]) => boolean);
    disabled?: boolean;
    action?: () => void;
}

export type SubentitiesListHierarchyContext<TSubentity extends ISubentity> = {
    filter: Metadata.IFilter<Metadata.BaseFilterValue>,
    prefilter?: Metadata.PreFilterOption<TSubentity>
}

export type SelectedEntitiesActions = {
    exportToCsv?: IContextualMenuItem;
}

type ISubentitiesListProps<TSubentity extends ISubentity> = {
    sectionId: string;
    controlId: string;
    parentEntityId: string;
    parentEntityType?: EntityType;
    timelineProps?: ITimelineProps;
    timelineMapProps?: ITimelineMapProps;
    entityPanelHeader?: Partial<IHeader> | ((entity: TSubentity | undefined) => Partial<IHeader> | undefined);
    useViews?: boolean;
    useFilters?: boolean;

    preFilterItems?: Metadata.PreFilterOption<TSubentity>[];
    sourceType?: SourceType;
    subentityInfo: SubentityInfo;
    settings: ISubentitiesListSettings;
    entities: TSubentity[];
    entitiesIsLoading?: boolean;
    entitiesIsUpdating?: boolean;
    actions: ISubentitySectionActions<TSubentity>;
    readOnly: boolean;
    allowExtensions?: boolean;
    canConfigure?: boolean;
    isArchived?: boolean;
    isViewSelected?: boolean;
    className?: string;
    getDeletionMessage?: (selectedItems: TSubentity[]) => string | undefined;
    renderDeleteDialogContent?: (selectedItems: TSubentity[]) => JSX.Element | undefined;
    fakeFields?: Metadata.Field[];
    fieldValueExtractor?: (item: TSubentity, field: Metadata.Field) => any;
    mandatoryEditFields?: string[];
    nonFilterableFields?: string[];
    readOnlyFields?: ((entity: TSubentity) => string[]) | string[];
    editableNativeFieldsForLinkedEntities?: ((entity: TSubentity) => string[]) | string[];
    theOnlyEditableFieldsForLinkedEntities?: string[];
    emptyScreen?: {
        iconName?: string;
        title?: string;
        description?: string;
        onRenderCommands?: () => JSX.Element | null;
    }
    editProps?: {
        uiControlElementsCustomRender?: Dictionary<any>;
        customFieldValidatorBuilder?: Dictionary<(state: any, field: Metadata.Field) => IValidator>;
    }
    inlineEditProps?: {
        inlineEditing?: boolean;
        uiControlElementsCustomRender?: Dictionary<any>;
        customFieldValidatorBuilder?: Dictionary<(state: any, field: Metadata.Field) => IValidator>;
    }
    renderImport?: (props: { onDismiss: () => void }) => JSX.Element | JSX.Element[] | null;
    listGrouping?: ListGroupingProps;
    groups?: EntityGroup[];
    showOnlyExternalCommands?: boolean;
    externalCommands?: {
        actionsCommandItems?: (filteredItems: TSubentity[]) => IContextualMenuItem[] | undefined;
        commands?: (filteredItems: TSubentity[]) => IContextualMenuItem[] | undefined;
        selectionModeCommands?: (selectedItems: TSubentity[], actions: SelectedEntitiesActions) => IContextualMenuItem[] | undefined;
    }
    extraColumns?: IColumn[];
    showMore?: {
        title?: string;
        count?: number;
        sliceFunc?: (entities: any[], showCount: number, getValue: (value: any) => TSubentity) => any[];
    };
    allowImportedManage?: boolean;
    hierarchy?: SectionHierarchyManager<TSubentity, SubentitiesListHierarchyContext<TSubentity>>;
    buildEntityFilterHelper?: (props: FilterHelperProps) => Metadata.IEntityFilterHelper<TSubentity>;

    entityPanelProps?: Partial<ISubentityPanelProps<TSubentity>>;
    onDelete?: (ids: string[]) => void;
    selection?: SelectionExt;
    onEditMenuClick?: (action: DialogAction, entity?: TSubentity) => void;

    controlSettings: SubListControlSettings;
    onSaveSettings?: (update: UserPreferencesSettingsUpdate, sendToServer?: boolean) => void;

    commandsConfig?: {
        add?: MenuButtonState<TSubentity>;
        edit?: MenuButtonState<TSubentity>;
        delete?: MenuButtonState<TSubentity>;
        import?: MenuButtonState<TSubentity>;
        export?: MenuButtonState<TSubentity>;
        aiGeneration?: MenuButtonState<TSubentity>;
        outline?: {
            title?: () => string | undefined;
            disabled?: () => boolean;
        };
    };
    isFullScreen: boolean;
    onItemRender?: OnItemRender<TSubentity>;
    onMenuRender?: () => JSX.Element;
    menu?: {
        isEnabled: boolean;
        mandatoryFields: string[];
        getItemCommands?: (selectedItems: TSubentity[], currentItem?: TSubentity, filterContext?: SubentitiesListHierarchyContext<TSubentity>) => IContextualMenuItem[];
    };
    resetExpandedAfterFilterChange?: boolean;
};

type State<TSubentity extends ISubentity> = {
    listMinHeight?: number;
    action: DialogAction;
    removeItemIds?: string[];
    selectedCount: number;
    isImportedEntitySelected: boolean;
    selectedEntityId?: string;
    filteredEntities: TSubentity[];
    isOpenExtensionPanel?: ExtensionInfo;
};

export type SubListControlSettings = {
    viewType: Views;
    details?: DetailsControlSettings & IWithActiveSubViewId;
    timelineMap?: TimelineMapControlSettings;
    timeline?: TimelineControlSettings & IWithActiveSubViewId;
} & IWithActiveFilter;

export interface ISubentitiesListSettings {
    displayFields: string[];
    orderBy: string[];
    timeline?: {
        displayFields: string[];
    };
    timelineMap?: {
        displayFields: string[];
    };
}
export interface ISubentitySectionActionProps<TSubentity extends ISubentity> {
    buildNew?: () => TSubentity;
    save?: (item: TSubentity) => void;
    remove: (ids: string[]) => void;
    updateUIControl: (sectionId: string, uiControlId: string, settings: Dictionary<any>) => void;
    refreshEntity?: () => void;
    renderImport?: (props: { onDismiss: () => void }) => JSX.Element | JSX.Element[] | null;
    exportToFile?: (subentityType: EntityType, exportSubEntitiesToFile: string, fields: string[], ids: string[], viewType: 'Details' | 'Timeline') => void;
    importFromFile?: (subentityType: EntityType, subentityCollectionName: string | undefined, file: File) => void;
    dragEntities?: (entities: string[], groupid?: string, insertbeforeId?: string) => void;
}

type StoreProps = {
    fields: Metadata.Field[];
    isFieldsLoading: boolean;
    isLoading: boolean;
    pngExportDetails?: PngExportControlDetails;
    filters: Metadata.IFilter<Metadata.BaseFilterValue>[] | undefined;
    canManageConfiguration: boolean;
    canConfigureSelection: boolean;
    extensions: ExtensionInfo[];
};
type ActionProps = {
    fieldsActions: ReturnType<typeof FieldsStore.actionCreators.forEntity>;
    filtersActions: ReturnType<typeof FiltersStore.actionCreators.forEntity>;
    notificationsActions: typeof NotificationsStore.actionCreators;
    pngExporterActions: typeof PngExportActionCreators;
};
type OwnProps<TSubentity extends ISubentity> = ISubentitiesListProps<TSubentity>;
type Props<TSubentity extends ISubentity> = OwnProps<TSubentity>
    & StoreProps
    & ActionProps
    & RouteComponentProps<{}>
    & IMaybeWithFilter<TSubentity>
    & IWithSubViews;

export interface IWithSelection {
    resetSelection: () => void;
}

class SubentitiesList<TSubentity extends ISubentity> extends React.Component<Props<TSubentity>, State<TSubentity>> {
    private _listContainer: HTMLDivElement | null = null;
    private _selection: Selection;
    private _fileUpload: HTMLInputElement | null | undefined;

    constructor(props: Props<TSubentity>) {
        super(props);
        const selectedEntityId: string | undefined = this._getUrlEntityId(props);

        this.state = {
            action: selectedEntityId ? (selectedEntityId.toLowerCase() === Metadata.NEW_ID ? DialogAction.Add : DialogAction.Update) : DialogAction.None,
            selectedCount: 0,
            removeItemIds: undefined,
            isImportedEntitySelected: false,
            selectedEntityId,
            filteredEntities: []
        };

        const getKey = props.hierarchy?.getKey as (undefined | ((item: IObjectWithKey, index?: number) => string | number));
        props.selection?.AddEventHandler(this._onSelectionChanged);
        props.selection?.SetGetKey(getKey);
        this._selection = this.props.selection ?? new Selection({
            onSelectionChanged: this._onSelectionChanged,
            getKey
        });
    }

    componentWillUnmount() {
        this.props.selection?.RemoveEventHandler(this._onSelectionChanged);
    }

    componentWillMount() {
        if (this.props.isLoading) {
            return;
        }

        this._applyFilter(this.props);
    }

    componentDidUpdate(prevProps: Props<TSubentity>, prevState: State<TSubentity>) {
        if (this.state.listMinHeight !== undefined) {
            const delay = 300;
            //setTimeout is used as fabric DetailsList may render contents asyncronously
            setTimeout(() => {
                if (this.state.listMinHeight !== undefined) {
                    this.setState({ listMinHeight: undefined });
                }
            }, delay);
        }
    }

    componentWillReceiveProps(nextProps: Props<TSubentity>) {
        if (this.props.controlSettings.viewType !== nextProps.controlSettings.viewType) {
            this.setState({ listMinHeight: this._listContainer?.clientHeight });
        }

        const urlEntityId: string | undefined = this._getUrlEntityId(this.props);
        const nextUrlEntityId: string | undefined = this._getUrlEntityId(nextProps);
        if (urlEntityId !== nextUrlEntityId) {
            let selectedEntityId = nextUrlEntityId;
            if (!selectedEntityId) {
                const selected = this._selection.getSelection() as TSubentity[];
                const selectedEntity = selected.length === 1 ? selected[0] : undefined;
                selectedEntityId = selectedEntity?.id;
            }
            this.setState({ selectedEntityId });
            if (nextUrlEntityId) {
                this.setState({ action: nextUrlEntityId.toLowerCase() === Metadata.NEW_ID ? DialogAction.Add : DialogAction.Update });
            }
        }

        const filterChanged = isFilterChanged(this.props, nextProps);
        if (!arraysEqual(this.props.entities, nextProps.entities) || filterChanged) {
            this._applyFilter(nextProps, filterChanged);
        }
    }

    private _applyFilter = (props: Props<TSubentity>, filterChanged?: boolean) => {
        const filteredEntities = props.filter
            ? props.entities.filter(props.filter.isItemVisible)
            : props.entities;

        if (props.hierarchy) {
            const context = {
                filter: props.filter?.activeFilter!,
                prefilter: props.filter?.preFilter?.active
            }
            props.hierarchy.setItems(filteredEntities, props.entities, !(this.props.resetExpandedAfterFilterChange && filterChanged), context);
        }

        this.setState({ filteredEntities });
    }

    private _isHidden = (menu: MenuButtonState<TSubentity> | undefined, selectedItems: TSubentity[] | undefined = undefined): boolean => {
        if (!menu?.hidden) {
            return false;
        }
        if (typeof menu.hidden === 'function') {
            return menu.hidden(selectedItems ?? this._selection.getSelection() as TSubentity[]);
        }
        return menu.hidden;
    }

    private _onSelectionChanged = () => {
        const selected = this._selection.getSelection() as TSubentity[];
        const selectedEntity = selected.length === 1 ? selected[0] : undefined;

        this.setState({
            selectedEntityId: selectedEntity?.id,
            selectedCount: this._selection.getSelectedCount(),
            isImportedEntitySelected: selected.some(_ => this._isImported(_))
        });
    }

    private _getDisplayFields = (): string[] => {
        const { view, fields, fakeFields } = this.props;

        const allFields = [...fakeFields || [], ...fields];
        if (view) {
            if (!view.activeSubView) { return []; }

            const fieldsMap = toDictionaryById(allFields);
            return view.activeSubView?.columns.map(_ => fieldsMap[_.id]?.name).filter(notUndefined);
        }

        const fieldsByNameMap = toDictionaryByName(allFields);
        return this._getViewConfig().displayFields.map(_ => fieldsByNameMap[_]?.name).filter(notUndefined);
    }

    private _getUrlEntityId(props: Props<TSubentity>): string | undefined {
        const query = new URLSearchParams(props.location.search);
        return query.get(urlParamsBuilder.item(props.subentityInfo.subentityType)) || undefined;
    }

    private _clearFilter = () => {
        this.props.filter?.actions.onClearFilter(this.props?.filters)
        this.props.filter?.preFilter?.onChange(undefined);
    }

    render() {
        const { subentityInfo, hierarchy, fieldsActions, fields, fakeFields, mandatoryEditFields, readOnlyFields,
            editableNativeFieldsForLinkedEntities, theOnlyEditableFieldsForLinkedEntities, entities, timelineProps, isLoading,
            entitiesIsUpdating, readOnly, className, emptyScreen, fieldValueExtractor, editProps, inlineEditProps,
            groups, listGrouping, menu, showMore, pngExportDetails, settings, controlSettings, onSaveSettings,
            extraColumns, commandsConfig: buttonsConfig, isFullScreen, filter, canManageConfiguration, isArchived } = this.props;
        const { filteredEntities } = this.state;
        const allFields = [...fakeFields || [], ...fields];
        const fakeMap = toDictionaryById(fakeFields || []);

        if (isLoading) {
            return <ControlSpiner isLoading={isLoading} />;
        }

        const step = 10;
        const displayFields = this._getDisplayFields();
        const selectionModeCommands = this._getSelectionModeCommands(displayFields);
        const listProps: IListProps<TSubentity> & ListUserSettings = {
            isFilterApplied: filteredEntities.length !== entities.length,
            entityType: subentityInfo.subentityType,
            selection: this._selection,
            fields: allFields,
            isFieldFake: _ => !!fakeMap[_.id],
            displayFields,
            listGrouping: pngExportDetails && listGrouping ? { ...listGrouping, renderGroupFooter: undefined } : listGrouping,
            groups,
            draggableEvents: pngExportDetails || readOnly || !hierarchy?.allowDrag()
                ? undefined
                : this._getViewConfig().draggableEvents,
            showMore: pngExportDetails?.isInProgress || isFullScreen
                ? undefined
                : { title: `Show ${step} more ${this._getPluralLabel()}`, step, ...showMore, extraCount: hierarchy?.getExpandedChildrenCount() },
            onItemRender: this.props.onItemRender,
            onItemMenuRender: menu?.isEnabled ? this._renderItemMenu : undefined,
            extraColumns: extraColumns,
            isVirtualizationDisabled: pngExportDetails?.isInProgress,
            fieldValueExtractor,
            entities: filteredEntities,
            defaultSort: settings.orderBy?.length
                ? { fieldName: settings.orderBy[0], direction: SortDirection.ASC }
                : undefined,
            controlSettings: controlSettings,
            onSaveSettings: onSaveSettings,
            isArchived: isArchived,
            ...getCheckboxOptionsBasedOnCommands(selectionModeCommands),
            inlineEditProps: inlineEditProps?.inlineEditing && !readOnly
                ? {
                    onInlineEditComplete: (field: Metadata.Field, item: IExtensibleEntity, value: any, extraUpdates?: Dictionary<any>) => {
                        this._onUpdate(item.id, ViewService.buildUpdates(field, value, extraUpdates))
                    },
                    readonlyFields: readOnlyFields,
                    editableNativeFieldsForLinkedEntities,
                    theOnlyEditableFieldsForLinkedEntities,
                    customFieldValidatorBuilder: inlineEditProps?.customFieldValidatorBuilder ?? editProps?.customFieldValidatorBuilder,
                    uiControlElementsCustomRender: inlineEditProps?.uiControlElementsCustomRender?? editProps?.uiControlElementsCustomRender,
                } : undefined
        };

        const menuProps = this._buildMenuProps(displayFields);

        const stylingMenuProps = this._buildStylingMenuProps();
        const outlineMenuProps = this._buildOutlineMenuProps();

        const selectedItem = this.state.action === DialogAction.Update ? this._getSelectedItem() : undefined;
        const panelProps = {
            subentityTypeLabel: subentityInfo.subentityTypeLabel,
            subentityType: subentityInfo.subentityType,
            fields: fields,
            displayFields,
            mandatoryEditFields,
            readOnlyFields,
            editableNativeFieldsForLinkedEntities,
            theOnlyEditableFieldsForLinkedEntities,
            header: typeof (this.props.entityPanelHeader) === "function" ? this.props.entityPanelHeader(selectedItem) : this.props.entityPanelHeader,
            onDismiss: this._onSubentityPanelClosed,
            uiControlElementsCustomRender: editProps?.uiControlElementsCustomRender,
            customFieldValidatorBuilder: editProps?.customFieldValidatorBuilder,
            readOnly,
            ...this.props.entityPanelProps
        }

        return <ControlSpiner isLoading={!!entitiesIsUpdating} className="show-over">
            {!entities.length && !groups?.length && !!emptyScreen
                ? <SectionControlPlaceholder
                    key="no-data"
                    iconName={emptyScreen.iconName}
                    title={emptyScreen.title}
                    description={emptyScreen.description}>
                    {
                        !readOnly && <>
                            {
                                !this._isHidden(buttonsConfig?.add) && <DefaultButton
                                    text={buttonsConfig?.add?.name ?? `New ${subentityInfo.subentityTypeLabel}`}
                                    onClick={() => {
                                        if (buttonsConfig?.add?.action) {
                                            buttonsConfig.add.action();
                                        } else {
                                            this.setState({ action: DialogAction.Add });
                                            this.props.onEditMenuClick?.(DialogAction.Add);
                                        }
                                    }} />
                            }
                            {
                                !this._isHidden(buttonsConfig?.import) && <DefaultButton
                                    text={buttonsConfig?.import?.name ?? `Import from File`}
                                    onClick={() => this._fileUpload?.click()} />
                            }
                            {
                                this._isImportEnabled() &&
                                <DefaultButton
                                    text={`Import ${this._getPluralLabel()}`}
                                    onClick={() => this.setState({ action: DialogAction.Import })} />
                            }
                            {
                                !this._isHidden(buttonsConfig?.aiGeneration) && <DefaultButton
                                    text={buttonsConfig?.aiGeneration?.name ?? 'Generate with AI'}
                                    title={buttonsConfig?.aiGeneration?.title}
                                    disabled={buttonsConfig?.aiGeneration?.disabled}
                                    onClick={() => buttonsConfig?.aiGeneration?.action?.()} />
                            }
                        </>
                    }
                </SectionControlPlaceholder>
                : <ScrollPositionFixer>
                    {
                        //temporary setting div minHeight is needed because of the inner DetailsList anync render:
                        //it renders empty grid first and then adds the items. this causes page to shrink and scroll to jump
                    }
                    <div key="list" className={`subentities-list ${className || ""}`}
                        ref={_ => this._listContainer = _} style={{ minHeight: this.state.listMinHeight }}>
                        {this.props.onMenuRender ?
                            this.props.onMenuRender() :
                            <ListMenu
                                {...menuProps}
                                {...filter?.menu}
                                {...stylingMenuProps}
                                selectionMode={{
                                    enabled: this._isAnyItemSelected(),
                                    items: selectionModeCommands,
                                    onCancel: () => this._selection.setAllSelected(false)
                                }}
                                styling={stylingMenuProps}
                                outline={outlineMenuProps}
                                entityType={subentityInfo.subentityType}
                                commands={this._getCommands()}
                                fields={listProps.fields}
                                allowManageFields={!isArchived && canManageConfiguration}
                                fieldActions={fieldsActions}
                                mandatoryViewFields={menu?.mandatoryFields} />}
                        <PngExporter details={pngExportDetails} getConfig={this._getPngExportConfig}>
                            <FullscreenReMountComponent isFullScreen={this.props.isFullScreen}>
                                <ClearFilterComponent
                                    items={this.props.entities}
                                    filteredItems={listProps.entities}
                                    onClearFilter={() => this._clearFilter()}>
                                    {hierarchy
                                        ? <HierarchyViewSelector
                                            {...listProps}
                                            hierarchy={hierarchy}
                                            timelineMapProps={this.props.timelineMapProps}
                                            timelineProps={timelineProps}
                                            isFullScreen={isFullScreen}
                                        />
                                        : <ViewSelector
                                            {...listProps}
                                            timelineMapProps={this.props.timelineMapProps}
                                            timelineProps={timelineProps}
                                            isFullScreen={isFullScreen}
                                        />}
                                </ClearFilterComponent>
                            </FullscreenReMountComponent>
                        </PngExporter>
                    </div>
                </ScrollPositionFixer>
            }
            {
                this.state.action === DialogAction.Add &&
                <CreatePanel
                    buildNewEntity={this.props.actions.buildNew}
                    onComplete={this._onCreate}
                    {...panelProps}
                />
            }
            {
                this.state.action === DialogAction.Update && selectedItem &&
                <UpdatePanel
                    key={selectedItem.id}
                    entity={selectedItem}
                    onDelete={((this._isImported(selectedItem) && !this.props.allowImportedManage) ||
                        this._isHidden(buttonsConfig?.delete) ||
                        readOnly) ? undefined : this._onDeleteItem}
                    {...panelProps}
                    onComplete={this._onUpdate}
                />
            }
            {this.state.action === DialogAction.Import && this.props.renderImport?.({ onDismiss: () => this.setState({ action: DialogAction.None }) })}
            {this.state.removeItemIds?.length && <RemoveDialog
                onClose={() => this.setState({ removeItemIds: undefined })}
                onComplete={() => {
                    const { removeItemIds } = this.state;
                    if (removeItemIds?.length) {
                        if (this.props.onDelete) {
                            this.props.onDelete(removeItemIds);
                        } else {
                            this.props.actions.remove!(removeItemIds);
                        }
                    }
                    this._selection.setAllSelected(false);
                    this.setState({ removeItemIds: undefined });
                }}
                dialogContentProps={{
                    title: `Delete ${this.props.subentityInfo.subentityTypeLabel}`,
                    subText: this._getDeletionMessage()
                }}
                confirmButtonProps={{ text: "Delete" }} >
                {this.props.renderDeleteDialogContent?.(entities.filter(_ => this.state.removeItemIds?.includes(_.id)))}
            </RemoveDialog>}
            {this.state.isOpenExtensionPanel &&
                <ExtensionPanel
                    entityType={this.props.subentityInfo.subentityType}
                    info={this.state.isOpenExtensionPanel}
                    context={{ entityId: this.props.parentEntityId, entityType: this.props.parentEntityType, sourceType: this.props.sourceType }}
                    urlParams={this._buildExtensionUrlParams()}
                    onRefresh={this.props.actions.refreshEntity}
                    onDismiss={() => this.setState({ isOpenExtensionPanel: undefined })} />}
            <input ref={(ref) => this._fileUpload = ref} style={{ display: "none" }} type="file" accept=".csv"
                onChange={(e) => {
                    if (e.target && e.target.files && e.target.files[0]) {
                        this._onImportFromCsv(e.target.files[0]);
                    }
                    e.target.value = '';
                }} />
        </ControlSpiner>;
    }

    private _buildExtensionUrlParams(): Dictionary<string> {
        const params: Dictionary<any> = { entityId: this.props.parentEntityId };
        //legacy behaviour
        if(this.props.subentityInfo.subentityType == EntityType.Task)
        {
            params.projectId = this.props.parentEntityId;
        }
        return params;
    }

    private _onCreate = (subentity: TSubentity) => {
        this.props.actions.create!(subentity);
    }

    private _onUpdate = (id: string, changes: Dictionary<unknown>) => {
        this.props.actions.update!(id, changes);
    }

    private _onDeleteItem = (entityId: string) => {
        this._onDelete([entityId]);
    }

    private _onSubentityPanelClosed = () => {
        this.setState({ action: DialogAction.None });

        const query = new URLSearchParams(this.props.location.search);

        let urlChanged = false;

        if (query.has(urlParamsBuilder.item(this.props.subentityInfo.subentityType))) {
            query.delete(urlParamsBuilder.item(this.props.subentityInfo.subentityType));
            urlChanged = true;
        }

        if (query.has(urlParamsBuilder.itemType(this.props.subentityInfo.subentityType))) {
            query.delete(urlParamsBuilder.itemType(this.props.subentityInfo.subentityType));
            urlChanged = true;
        }

        if (urlChanged) {
            this.props.history.replace({ ...this.props.location, search: query.toString() });
        }
    }

    private _onDisplayFieldsChange = (displayFields: string[]) => {
        const { sectionId, controlId } = this.props;
        this.props.actions.updateUIControl!(sectionId, controlId, { displayFields });
    }

    private _onTimelineDisplayFieldsChange = (displayFields: string[]) => {
        const { sectionId, controlId } = this.props;
        this.props.actions.updateUIControl!(sectionId, controlId, { timeline: { displayFields } });
    }

    private _onTimelineMapDisplayFieldsChange = (displayFields: string[]) => {
        const { sectionId, controlId } = this.props;
        this.props.actions.updateUIControl!(sectionId, controlId, { timelineMap: { displayFields } });
    }

    private _getDeletionMessage = () => {
        const selectedItems = this.props.entities.filter(_ => this.state.removeItemIds?.includes(_.id));
        const msg = this.props.getDeletionMessage?.(selectedItems);
        if (msg) {
            return msg;
        }

        if (selectedItems.length === 1) {
            return `Are you sure you want to delete ${this.props.subentityInfo.subentityTypeLabel} "${selectedItems[0].attributes['Name']}"?`;
        }

        return `Are you sure you want to delete ${selectedItems.length} ${this._getPluralLabel()}?`;
    }

    private _getSelectedItem = (): TSubentity | undefined =>
        this.state.selectedEntityId
            ? this.props.entities.find(_ => _.id === this.state.selectedEntityId)
            : undefined;

    private _isAnyItemSelected = () => this._selection.getSelection().length > 0;

    private _getSelectedItems = () => {
        const { entities } = this.props;
        const selectedIds = (this._selection.getSelection() as TSubentity[]).map(_ => _.id);
        return entities.filter(_ => selectedIds.includes(_.id));
    }

    private _getSelectionModeCommands = (displayFields: string[]): IContextualMenuItem[] => {
        const { readOnly, subentityInfo, allowImportedManage, commandsConfig, isArchived, showOnlyExternalCommands } = this.props;
        const { isImportedEntitySelected } = this.state;
        const selectedItems = this._getSelectedItems();
        const selectedCount = selectedItems.length;

        const actions: SelectedEntitiesActions = {
            exportToCsv: !this._isHidden(commandsConfig?.export) ? {
                key: 'exportRows',
                name: commandsConfig?.export?.name ?? `Export to CSV`,
                title: MenuTitleBuilder.exportSelectedToCSVTitle(this._getPluralLabel()),
                iconProps: csvImportExportIconProps,
                disabled: selectedCount < 1 || isArchived,
                onClick: () => this._onExportToCsv(displayFields)
            } : undefined,
        };

        const exportCommands = [actions.exportToCsv].filter(notUndefined);
        if (readOnly) {
            return exportCommands;
        }

        const commands = this.props.externalCommands?.selectionModeCommands?.(selectedItems, actions) ?? [];
        if (showOnlyExternalCommands) {
            return commands;
        }

        return [
            ...commands,
            ...exportCommands,
            !this._isHidden(commandsConfig?.delete) ? {
                key: 'deleteRow',
                name: commandsConfig?.delete?.name ?? `Delete`,
                title: selectedCount > 0 && isImportedEntitySelected && !allowImportedManage
                    ? `Imported ${subentityInfo.subentityTypeLabel} can't be deleted`
                    : MenuTitleBuilder.deleteSelectedTitle(this._getPluralLabel()),
                iconProps: { iconName: 'Delete' },
                disabled: !allowImportedManage && isImportedEntitySelected || selectedCount < 1,
                onRender: (item: any) => {
                    // nail to show title for disabled item
                    return <div data-is-focusable title={item.title} className="list-menu-command">
                        <CommandBarButton
                            {...item}
                            text={item.name}
                            styles={{ root: { height: '100%' }, label: { whiteSpace: 'nowrap' }, ...item.buttonStyles }}
                        />
                    </div>;
                },
                onClick: () => this.setState({ removeItemIds: this._getSelectedIds() }),
            } as IContextualMenuItem : undefined
        ].filter(notUndefined);
    }

    private _getCommands = (): IContextualMenuItem[] => {
        const { readOnly, subentityInfo, commandsConfig, showOnlyExternalCommands, externalCommands } = this.props;
        const { filteredEntities } = this.state;

        const actionsCommand = buildActionsMenuItemWithItems(this._getActionsCommandItems());

        if (readOnly) {
            return [actionsCommand];
        }

        if (showOnlyExternalCommands) {
            return [
                ...externalCommands?.commands?.(filteredEntities) ?? [],
                actionsCommand
            ];
        }

        return ([
            !this._isHidden(commandsConfig?.add) ? {
                key: 'addRow',
                name: commandsConfig?.add?.name ?? `New ${subentityInfo.subentityTypeLabel}`,
                title: commandsConfig?.add?.title,
                iconProps: { iconName: 'Add' },
                onClick: () => {
                    if (commandsConfig?.add?.action) {
                        commandsConfig?.add?.action();
                    } else {
                        this.setState({ action: DialogAction.Add });
                        this.props.onEditMenuClick?.(DialogAction.Add);
                    }
                }
            } : undefined,
            !this._isHidden(commandsConfig?.aiGeneration) ? {
                key: 'generateWithAI',
                name: commandsConfig?.aiGeneration?.name ?? `Generate with AI`,
                title: commandsConfig?.aiGeneration?.title,
                iconProps: { iconName: 'D365CustomerInsights' },
                disabled: commandsConfig?.aiGeneration?.disabled,
                onClick: () => commandsConfig?.aiGeneration?.action?.()
            } : undefined,
            this._isImportEnabled()
                ? {
                    key: "importRows",
                    name: `Import ${subentityInfo.subentityTypeLabel}`,
                    iconProps: { iconName: 'PPMXImport' },
                    onClick: () => this.setState({ action: DialogAction.Import })
                } : undefined,
            actionsCommand
        ] as IContextualMenuItem[]).filter(notUndefined);
    }

    private _getActionsCommandItems = (): IContextualMenuItem[] => {
        const { readOnly, commandsConfig, externalCommands } = this.props;
        const { filteredEntities } = this.state;
        const exportCommands = [
            buildExportToPngMenuItem(this._getPngExportConfig, this.props.pngExporterActions, !filteredEntities?.length)
        ];

        const extensionsCommands = !this.props.readOnly || this.props.allowExtensions
            ? buildExtensionsMenuItem(this.props.extensions, (_) => this.setState({ isOpenExtensionPanel: _ }))
            : [];

        if (readOnly) {
            return [
                ...extensionsCommands,
                ...exportCommands,
            ].filter(notUndefined);
        }

        const importCommands: IContextualMenuItem[] = [
            !this._isHidden(commandsConfig?.import)
                ? {
                    key: 'importRows',
                    name: commandsConfig?.import?.name ?? 'Import from CSV',
                    title: MenuTitleBuilder.importFromCSVTitle(this._getPluralLabel()),
                    iconProps: csvImportExportIconProps,
                    onClick: () => this._fileUpload?.click()
                } : undefined
        ].filter(notUndefined);

        return [
            ...extensionsCommands,
            ...externalCommands?.actionsCommandItems?.(filteredEntities) ?? [],
            ...importCommands,
            ...exportCommands
        ].filter(notUndefined);
    }

    private _isImportEnabled = (): boolean => {
        return !!this.props.renderImport;
    }

    private _renderItemMenu = (item: TSubentity) => {
        const { readOnly, subentityInfo, menu, allowImportedManage, commandsConfig: buttonsConfig, parentEntityId, allowExtensions, sourceType } = this.props;
        const { isImportedEntitySelected, selectedCount } = this.state;

        const selectedItems = this._selection.getSelection() as TSubentity[];
        const deleteDisabled = !allowImportedManage && (isImportedEntitySelected || selectedCount < 1);
        const editHidden = readOnly || this._isHidden(buttonsConfig?.edit, [item]);
        const deleteHidden = readOnly || this._isHidden(buttonsConfig?.delete);
        const commands: IContextualMenuItem[] = [
            {
                key: 'updateRow',
                name: editHidden ? 'View' : buttonsConfig?.edit?.name ?? 'Edit',
                iconProps: { iconName: editHidden ? 'View' : 'Edit' },
                onClick: () => {
                    this.setState({ action: DialogAction.Update });
                    this.props.onEditMenuClick?.(DialogAction.Update, item);
                }
            },
            ...menu?.getItemCommands?.(selectedItems, item, { filter: this.props.filter?.activeFilter!, prefilter: this.props.filter?.preFilter?.active }) ?? [],
            !deleteHidden ? {
                key: 'deleteRow',
                name: 'Delete',
                style: { color: deleteDisabled ? undefined : 'red' },
                iconProps: { iconName: "Delete", style: { color: deleteDisabled ? undefined : 'red' } },
                disabled: deleteDisabled,
                title: selectedCount > 0 && isImportedEntitySelected && !allowImportedManage ?
                    `Imported ${subentityInfo.subentityTypeLabel} can't be deleted` :
                    undefined,
                onClick: () => this._onDelete(this._getSelectedIds())
            } : undefined
        ].filter(notUndefined);

        return commands.length
            ? <RowMenuWithExtensions
                commands={commands}
                context={{ parentEntityId, sourceType }}
                entityType={subentityInfo.subentityType}
                onRefresh={this.props.actions.refreshEntity}
                selection={this._selection}
                item={{ ...item, allowExtensions: !readOnly || allowExtensions }} />
            : null;
    }

    private _buildMenuProps = (displayFields: string[]): IWithViews | IWithColumns | undefined => {
        if (this._isAnyItemSelected()) {
            return undefined;
        }

        const { view } = this.props;

        if (view) {
            return {
                type: "Views",
                views: view.subViews,
                activeView: view.activeSubView,
                ...view.actions
            };
        }

        if (this.props.timelineMapProps) {
            return undefined;
        }

        return {
            type: "Columns",
            canViewConfigureColumns: this.props.canConfigureSelection,
            displayFields: displayFields,
            onDisplayFieldsChange: this.props.canConfigureSelection
                ? this._getViewConfig().onDisplayFieldsChange
                : undefined
        };
    }

    private _buildStylingMenuProps(): Styling | undefined {
        return this._getViewConfig()?.styling;
    }

    private _buildOutlineMenuProps(): IOutline | undefined {
        if (this.props.hierarchy?.isSupportOutline()) {
            const pluralLabel = this._getPluralLabel();
            return {
                outlineTitle: () => this.props.commandsConfig?.outline?.title?.() ??
                    `Expand or collapse ${pluralLabel} in the ${pluralLabel} list`,
                expandTitle: `Expand ${pluralLabel} list to make ${pluralLabel} on all hierarchy levels visible`,
                collapseTitle: `Collapse ${pluralLabel} list to make only ${pluralLabel} on the 1st level of hierarchy visible`,
                expandAll: () => this.props.hierarchy?.expandAll(),
                collapseAll: () => this.props.hierarchy?.collapseAll(),
                disabled: this.props.commandsConfig?.outline?.disabled,
            };
        }

        return undefined;
    }

    private _getViewConfig = (): {
        styling?: Styling;
        displayFields: string[];
        onDisplayFieldsChange?: (displayFields: string[]) => void;
        draggableEvents: IDraggableEvents<TSubentity>;
    } => {
        const { controlSettings, timelineMapProps, timelineProps, settings, actions, hierarchy } = this.props;

        switch (controlSettings.viewType) {
            case Views.Timeline: return {
                styling: timelineProps?.styling,
                onDisplayFieldsChange: this._onTimelineDisplayFieldsChange,
                displayFields: settings.timeline?.displayFields || [],
                draggableEvents: {
                    onDrag: actions.dragEntities,
                    isDragDisabled: _ => hierarchy?.isDragDisabled(_ as TSubentity) ?? true
                }
            }
            case Views.List: return {
                styling: undefined,
                onDisplayFieldsChange: this._onDisplayFieldsChange,
                displayFields: settings.displayFields,
                draggableEvents: {
                    onDragStart: _ => hierarchy?.onDragStart(_ as TSubentity),
                    onDrag: actions.dragEntities
                }
            }
            case Views.TimelineMap: return {
                styling: timelineMapProps?.styling,
                onDisplayFieldsChange: this._onTimelineMapDisplayFieldsChange,
                displayFields: settings.timelineMap?.displayFields || [],
                draggableEvents: { onDrag: actions.dragEntities }
            }
        }
    }

    private _isImported(entity?: TSubentity): boolean {
        return !!entity && isLinkedSubentity(this.props.subentityInfo.subentityType, entity) && entity.sourceType !== SourceType.Ppmx;
    }

    private _getSelectedIds(): string[] {
        return this._selection.getSelection().map(_ => (_ as TSubentity).id);
    }

    private _onDelete = (ids: string[]) => {
        this.setState({ removeItemIds: ids });
    }

    private _onExportToCsv = (displayFields: string[]) => {
        const selectedItems: string[] = this._getSelectedIds();
        if (selectedItems.length) {
            this.props.actions.exportToFile?.(
                this.props.subentityInfo.subentityType,
                this._getPluralLabel(),
                displayFields,
                selectedItems,
                this.props.controlSettings.viewType!);
        }
        this._selection.setAllSelected(false);
    }

    private _onImportFromCsv = (file: File) => {
        this.props.actions.importFromFile?.(this.props.subentityInfo.subentityType, this.props.subentityInfo.subentityCollectionName, file);
    }

    private _getPluralLabel = (): string => this.props.subentityInfo.pluralSubentityTypeLabel ?? `${this.props.subentityInfo.subentityTypeLabel}s`;

    private _getPngExportConfig = (): PngExportConfig => {
        const { groups, listGrouping, controlSettings } = this.props;
        const { filteredEntities } = this.state;

        const groupRowsCount = listGrouping?.renderGroupHeader && groups ? groups.length : 0;
        const rowsCount = filteredEntities.length + groupRowsCount;
        const isDetailsView = controlSettings.viewType === Views.List;
        return {
            name: this._getPluralLabel(),
            controlId: getExportControlId(this.props),
            activeView: isDetailsView ? Metadata.ViewTypes.list : Metadata.ViewTypes.timeline,
            elementSelector: isDetailsView ? ListExportElementSelector : undefined,
            rowsCount
        };
    }
}

export const ListExportElementSelector = ".ms-DetailsList div[role='grid']";

const getExportControlId = <TSubentity extends ISubentity>(props: OwnProps<TSubentity>) => `${props.sectionId}_${props.controlId}`;

export default function <TSubentity extends ISubentity>() {
    function mapStateToProps(state: ApplicationState, ownProps: OwnProps<TSubentity> & RouteComponentProps<{}>): StoreProps {
        const subentityType = ownProps.subentityInfo.subentityType;
        const fields = state.fields[subentityType];
        const filters = ownProps.useFilters
            ? FiltersStore.getFilter(state.filters, subentityType, FiltersStore.FilterKeys.Main, ownProps.sourceType)
            : undefined;

        const isReadOnlyMode = isInReadonlyMode(state.user, state.tenant, { isArchived: ownProps.isArchived });
        return {
            fields: fields.allIds.map(_ => fields.byId[_]),
            isFieldsLoading: fields.isLoading,
            isLoading: !!ownProps.entitiesIsLoading,
            pngExportDetails: state.pngExporter.controls[getExportControlId(ownProps)],
            filters: filters?.all,
            extensions: ownProps.parentEntityType ? findExtension(state.extensions, ownProps.parentEntityType, subentityType, ownProps.sourceType) : [],
            canManageConfiguration: contains(state.user.permissions.common, CommonOperations.ConfigurationManage),
            canConfigureSelection: ownProps.isViewSelected
                ? !ownProps.isArchived && contains(state.user.permissions.common, CommonOperations.ConfigurationManage)
                : isReadOnlyMode || !!ownProps.canConfigure
        };
    }

    function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps<TSubentity> & RouteComponentProps<{}>): ActionProps {
        const subentityFieldsStore = FieldsStore.actionCreators.forEntity(ownProps.subentityInfo.subentityType, ownProps.parentEntityId, ownProps.actions.refreshEntity);
        return {
            fieldsActions: bindActionCreators(subentityFieldsStore, dispatch),
            notificationsActions: bindActionCreators(NotificationsStore.actionCreators, dispatch),
            pngExporterActions: bindActionCreators(PngExportActionCreators, dispatch),
            filtersActions: bindActionCreators(FiltersStore.actionCreators.forEntity(ownProps.subentityInfo.subentityType), dispatch),
        };
    }

    const WithFilter = withFilter(SubentitiesList, useFilterProps);
    const WithSubViews = withSubView<Props<TSubentity> & RouteComponentProps<{}> & IMaybeWithFilter<TSubentity>>((props: Props<TSubentity>) => props.useFilters
        ? <WithFilter {...props} />
        : <SubentitiesList {...props} />);
    const WithStore = connect(mapStateToProps, mapDispatchToProps)(WithSubViews);
    return withRouter<OwnProps<TSubentity>>(WithStore);
}

export function getItemsFromSelection<TEntity extends IBaseEntity>(selection: Selection, ids?: string[]): TEntity[] {
    const items = selection.getItems().map(_ => _ as TEntity);
    return ids === undefined
        ? items
        : items.filter(_ => ids.includes(_.id));
}

type ViewSelectorProps<TSubentity extends ISubentity> = IListProps<TSubentity> & ListUserSettings & {
    timelineProps?: ITimelineProps;
    timelineMapProps?: ITimelineMapProps;
    isFullScreen: boolean;
}

const ViewSelector = <TSubentity extends ISubentity>(props: ViewSelectorProps<TSubentity>) => {
    const { controlSettings, timelineMapProps, timelineProps } = props;
    return <>
        {controlSettings.viewType === Views.List && <PersistDetailsList
            {...props}
            //scroll event not raised in fullscreen mode, should be reevaluated after fabric update
            isVirtualizationDisabled={props.isVirtualizationDisabled || props.isFullScreen}
        />}
        {controlSettings.viewType === Views.TimelineMap && <PersistTimelineMap
            {...props}
            {...timelineMapProps!}
        />}
        {controlSettings.viewType === Views.Timeline && <PersistTimelineList
            {...props}
            {...timelineProps!}
        />}
    </>
}

const HierarchyViewSelector = <TSubentity extends ISubentity>(props: ViewSelectorProps<TSubentity> & {
    hierarchy: SectionHierarchyManager<TSubentity, SubentitiesListHierarchyContext<TSubentity>>;
}) => {
    const { controlSettings, fields, isFieldFake, fieldValueExtractor, hierarchy, timelineProps, timelineMapProps } = props;

    const comparerBuilder: ComparerBuilder<TSubentity> = React.useCallback((orderBy) => {
        const fieldsMap = Metadata.toMap(fields, isFieldFake);
        return SortService.getComparer(fieldsMap, orderBy, isFieldFake, fieldValueExtractor);
    }, [fields, isFieldFake, fieldValueExtractor]);

    const hierarchyProps = {
        comparerBuilder: comparerBuilder,
        hierarchy
    }

    return <>
        {controlSettings.viewType === Views.List && <HierarchyDetailsList
            {...props}
            {...hierarchyProps}
            //scroll event not raised in fullscreen mode, should be reevaluated after fabric update
            isVirtualizationDisabled={props.isVirtualizationDisabled || props.isFullScreen}
        />}
        {controlSettings.viewType === Views.TimelineMap && <HierarchyTimelineMap
            {...props}
            {...timelineMapProps!}
            {...hierarchyProps}
        />}
        {controlSettings.viewType === Views.Timeline && <HierarchyTimelineList
            {...props}
            {...timelineProps!}
            {...hierarchyProps}
        />}
    </>
}

const useFilterProps = <TSubentity extends ISubentity>(props: Props<TSubentity>): WithFilterProps<TSubentity> => {
    const filterHelper = React.useMemo(
        () => {
            const builderProps = { entities: props.entities, fields: props.fields };
            return props.buildEntityFilterHelper?.(builderProps) ?? new FilterHelper(builderProps);
        }, [props.buildEntityFilterHelper, props.entities, props.fields]);

    return {
        filterHelper: filterHelper,
        canManageConfiguration: props.canManageConfiguration,
        entities: props.entities,
        entityType: props.subentityInfo.subentityType,
        fields: props.fields,
        filters: props.filters || [],
        filtersActions: props.filtersActions,
        location: props.location,
        history: props.history,
        controlSettings: props.controlSettings,
        onSaveSettings: props.onSaveSettings,
        nonFilterableFields: props.nonFilterableFields,
        preFilterItems: props.preFilterItems,
        sourceType: props.sourceType
    }
}