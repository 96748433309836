import * as React from 'react';
import { ActionButton, ComboBox, Dropdown } from 'office-ui-fabric-react';
import { buildPredecessorsMap, IPredecessorInfo, ITask, PredecessorType, PredecessorTypeMetadata } from '../../../entities/Subentities';
import NumberInput from '../../common/inputs/NumberInput';
import LabellableComponent from '../../common/LabellableComponent';
import { isAllowedAsPredecessorByHierarchy, TaskScheduleCalculator } from '../Fields';
import { CalendarDataSet } from '../../../store/CalendarStore';
import DatePickerInput from '../../common/inputs/DatePickerInput';
import { toDate } from '../../utils/common';
import { Validator } from '../../../validation';
import { FormatType } from '../../../entities/Metadata';

interface Props {
    task: ITask;
    tasks: ITask[];
    calendar: CalendarDataSet;
    predecessor?: IPredecessorInfo;
    onApply: (predecessor: IPredecessorInfo) => void;
    onCancel: () => void;
}

const pickerMinMaxLag = { min: -100, max: 100 };
const lagValidator = Validator.new().required().int32().min(pickerMinMaxLag.min).max(pickerMinMaxLag.max).build();
const TaskPredecessorEditBox = (props: Props) => {
    const predecessorsMap = React.useMemo(() => buildPredecessorsMap(props.tasks), [props.tasks]);
    const predecessorOptions = React.useMemo(() => props.tasks.filter(_ => _.id !== props.task?.id
        && isAllowedAsPredecessorByHierarchy(props.task, _)
        && !predecessorsMap[_.id]?.[props.task.id]
        && !props.task.attributes.Predecessor?.some(pr => pr.id === _.id && props.predecessor?.id !== pr.id))
        .map(_ => ({ key: _.id, text: _.attributes.Name })), [props.tasks, props.task, predecessorsMap]);
    const typeOptions = React.useMemo(() => [PredecessorType.FinishToStart, PredecessorType.StartToStart, PredecessorType.FinishToFinish, PredecessorType.StartToFinish]
        .map(_ => ({ key: _, text: `${PredecessorTypeMetadata[_].label} (${PredecessorTypeMetadata[_].abbreviation})` })), []);
    const tasksMap = React.useMemo(() => props.tasks?.reduce((map, _) => ({ ...map, [_.id]: _ }), {}) ?? {}, [props.tasks]);

    const [predecessorType, setPredecessorType] = React.useState(props.predecessor?.type ?? PredecessorType.FinishToStart);
    const [predecessorId, setPredecessorId] = React.useState(props.predecessor?.id);
    const [lag, setLag] = React.useState<number>(props.predecessor?.lag ?? 0);
    const [estimatedDate, setEstimatedDate] = React.useState<Date>();

    const isPredecessorToStart = React.useMemo(() => predecessorType === PredecessorType.FinishToStart || predecessorType === PredecessorType.StartToStart,
        [predecessorType]);
    const isPredecessorFromStart = React.useMemo(() => predecessorType === PredecessorType.StartToStart || predecessorType === PredecessorType.StartToFinish,
        [predecessorType]);

    const dictatedDate = React.useMemo(() => {
        const dates = predecessorId !== undefined ? TaskScheduleCalculator.GetDatesDictatedByPredecessor(tasksMap[predecessorId],
            { id: predecessorId, name: tasksMap[predecessorId].attributes.Name, type: predecessorType, lag: 0 }, props.calendar) : {};
        return dates.startDate ?? dates.dueDate;
    }, [props.calendar, tasksMap, predecessorId, predecessorType]);

    const onPredecessorChange = React.useCallback((_, option) => {
        setPredecessorId(option?.key as string);
        setLag(0);
    }, []);
    const onTypeChange = React.useCallback((_, option) => {
        setPredecessorType(option?.key as PredecessorType);
        setLag(0);
    }, []);
    const onLagChange = React.useCallback((value) => setLag(value), []);
    const onDateChange = React.useCallback((value) => {
        const newDate = toDate(value);
        if (dictatedDate && newDate) {
            setLag(TaskScheduleCalculator.GetLag(newDate, dictatedDate, props.calendar));
        }
    }, [props.task, props.calendar, tasksMap, predecessorId, dictatedDate])

    React.useEffect(() => {
        if (predecessorId && typeof lag === 'number') {
            const dates = TaskScheduleCalculator.GetDatesDictatedByPredecessor(tasksMap[predecessorId],
                { id: predecessorId, name: tasksMap[predecessorId].attributes.Name, type: predecessorType, lag }, props.calendar);
            setEstimatedDate(dates.startDate ?? dates.dueDate);
        } else {
            setEstimatedDate(undefined);
        }
    }, [props.task, props.calendar, tasksMap, predecessorId, predecessorType, lag])

    const isApplyAvailable = React.useMemo(() => predecessorId && lagValidator.isValid(lag), [predecessorId, lag]);
    const onApply = React.useCallback(() => {
        if (predecessorId && typeof lag === 'number') {
            props.onApply({ id: predecessorId, name: tasksMap[predecessorId].attributes.Name, type: predecessorType, lag });
        }
    }, [props.onApply, tasksMap, predecessorType, predecessorId, lag]);

    const predecessorDoesNotDictateDate = React.useMemo(() => !predecessorId || !dictatedDate, [predecessorId, dictatedDate]);
    const pickerMinMaxDates = React.useMemo(() => ({
        minDate: dictatedDate?.clone().addWorkingDays(pickerMinMaxLag.min, props.calendar),
        maxDate: dictatedDate?.clone().addWorkingDays(pickerMinMaxLag.max, props.calendar)
    }), [props.calendar, dictatedDate]);

    const estimatedDateTitle = React.useMemo(() => `Task ${isPredecessorToStart ? "Start" : "Due"} Date will be defined based on \
Predecessor ${isPredecessorFromStart ? "Start" : "Due"} Date, selected dependency type and lead/lag time. \
Estimated ${isPredecessorToStart ? "Start" : "Due"} Date is for information only. Task’s ${isPredecessorToStart ? "Start" : "Due"} Date can be changed from Details tab.`,
        [isPredecessorFromStart, isPredecessorToStart]);

    return <div className="edit-predecessor-box">
        <div className="title">{!!props.predecessor ? "Editing" : "Adding"} predecessor</div>
        <div className="grid-item">
            <ComboBox
                selectedKey={predecessorId}
                options={predecessorOptions}
                onChange={onPredecessorChange}
                autoComplete='on'
                allowFreeform
                useComboBoxAsMenuWidth
            />
        </div>
        <div className="grid-item">
            <Dropdown
                className="predecessor-type"
                selectedKey={predecessorType}
                options={typeOptions}
                onChange={onTypeChange}
            />
        </div>
        <div className="grid-item estimated-date-box">
            <LabellableComponent label='Lag'
                description="To add lead time, type a negative number of working days. To add lag time, type a positive number of working days."
                className="field-container">
                <NumberInput value={lag} format={FormatType.Days} disabled={predecessorDoesNotDictateDate} validator={lagValidator} onChanged={onLagChange} />
            </LabellableComponent>
            <LabellableComponent label="Estimated Date"
                description={estimatedDateTitle}
                className="field-container">
                <DatePickerInput minDate={pickerMinMaxDates.minDate} maxDate={pickerMinMaxDates.maxDate} value={estimatedDate?.toDateOnlyString()}
                    disabled={predecessorDoesNotDictateDate} onChanged={onDateChange} />
            </LabellableComponent>
        </div>
        <div>
            <ActionButton className="apply-btn" disabled={!isApplyAvailable} primary onClick={onApply}>Apply</ActionButton>
            <ActionButton onClick={props.onCancel}>Cancel</ActionButton>
        </div>
    </div>;
};
export default TaskPredecessorEditBox;
