import * as React from 'react';
import { actionCreators, Resource } from "../../../store/ResourcesListStore";
import { connect } from 'react-redux';
import { Panel, PanelType, DefaultButton, PrimaryButton, Persona, PersonaSize, MessageBar, MessageBarType } from 'office-ui-fabric-react';
import { getPersonInfoImageUrl } from '../../utils/common';
import { SourceTypeMap } from '../../../store/ExternalEpmConnectStore';
import SelectableCardItem from '../../common/SelectableCardItem';
import './MergePanel.css';
import { PanelHeader } from '../../common/PanelHeader';

type OwnProps = {
    selected: Resource[];
    onDismiss: () => void;
    onComplete?: () => void;
}
type Props = OwnProps & typeof actionCreators;

interface State {
    targetId: string;
}

const MergePanel = (props: Props) => {

    const [targetId, setTargetId] = React.useState(props.selected.find(_ => _.attributes.LinkedUser)?.id ?? props.selected[0].id);
    const disabled = props.selected.some(_ => _.attributes.LinkedUser);

    const selected = React.useMemo(() =>
        [...props.selected].sort((a, b) => (a.attributes.LinkedUser ? 0 : 1) - (b.attributes.LinkedUser ? 0 : 1)), [props.selected]);

    const _renderResource = (resource: Resource): JSX.Element =>
        <SelectableCardItem
            isSelected={targetId === resource.id}
            key={resource.id}
            disabled={disabled}
            onItemChecked={() => setTargetId(props.selected.find(_ => _.attributes.LinkedUser)?.id ?? resource.id)}>
            <Persona size={PersonaSize.size40}
                imageUrl={getPersonInfoImageUrl(resource)}
                text={resource.name}
                secondaryText={`Email: ${resource.attributes.Email || '-'}`} />
            {resource.sourceInfos.map((_, i) => <div className="res-src" key={i}>{`Linked to ${SourceTypeMap[_.type]} user "${_.sourceData.displayName}"`}</div>)}
        </SelectableCardItem>;

    const _onRenderFooterContent = (): JSX.Element | null => 
        <div className="commands">
            <PrimaryButton
                text="Merge"
                onClick={() => {
                    props.merge(targetId, props.selected.map(_ => _.id));
                    props.onComplete?.();
                    props.onDismiss();
                }} />
            <DefaultButton
                text="Cancel"
                onClick={props.onDismiss} />
        </div>;

    return <Panel
        isOpen={true}
        className='merge-resources-panel'
        isLightDismiss={true}
        type={PanelType.custom}
        customWidth="400px"
        onRenderHeader={() => <PanelHeader header="Merge Resources" secondaryText="Merge Resource records imported from multiple data sources that identify the same Resource." ></PanelHeader>}
        focusTrapZoneProps={{ disableFirstFocus: true }}
        onRenderFooterContent={_onRenderFooterContent}
        onDismiss={props.onDismiss}>
        <MessageBar messageBarType={MessageBarType.severeWarning}>
            Once the merge is complete, the resultant resource will have the following settings:<br />
            - The Work Week settings, Calendar Exceptions, and Details will be taken from the resource you specify as "main."<br />
            - The Resource Plans, Assignments, and Timesheets will be merged.
        </MessageBar>
        <MessageBar messageBarType={MessageBarType.warning}>
            {disabled
                ? 'Selected resource has a linked user, other resources will be merged into this resource.'
                : 'Please choose one of the resources below all other selected resources will be merged to.'}
        </MessageBar>
        <div className='selecteable-cards-items'>
            {selected.map(_ => _renderResource(_))}
        </div>
    </Panel>;
}

export default connect(null, actionCreators)(MergePanel);